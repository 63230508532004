import React from 'react';

import useOpen from '@/hooks/useOpen';

import { CampaignDialog } from './banner/CampaignDialog';

/**
 * プロパティ
 */
type Props = {
  // ゲスト画面コンポーネント
  guestViewBasicComponent: JSX.Element;
};

/**
 * 共有終了ビュー
 */
export const ShareEndView = React.memo(({ guestViewBasicComponent }: Props) => {
  const { isOpen, onClose } = useOpen(true);

  return (
    <div>
      {/* キャンペーンダイアログを表示 */}
      <CampaignDialog isOpen={isOpen} onClose={onClose} />

      {guestViewBasicComponent}
    </div>
  );
});
