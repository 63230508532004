import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  AGREEMENT_LINK_URL_EN,
  AGREEMENT_LINK_URL_JA,
  ApiStatus,
  COOKIE_KEY_NAME,
  COOKIE_USER_TYPE,
  LOCALE_LANG,
} from '@/constants';
import {
  useAgreementUpdateApi,
  AgreementUpdateState,
  AGREEMENT_UPDATE_API_RESULT_CODE,
  LOGOUT_API_RESULT_CODE,
} from '@/features/api';
import { usePtidExpired } from '@/features/expired';
import { useLogout } from '@/features/logout';
import { useBrowserUserSetting } from '@/hooks/useBrowserUserSetting';
import { findCookieValue } from '@/utils/cookie';

import {
  AGREEMENT_STATUS,
  AgreementErrorMessageMap,
  AgreementStatusType,
} from '../constants';

/**
 * 本カスタムフックからの返却値
 */
export type useAgreementValue = {
  // [利用を開始する]ボタンのクリック処理
  onClickAgreement: () => void;
  // [同意しない]ボタンのクリック処理
  onClickDisagreement: () => void;
  // 利用許諾API State
  agreementUpdateState: AgreementUpdateState;
  // ログアウトAPIのAPIステータス
  logoutStatus: ApiStatus;
  // 利用許諾画面に表示するエラーメッセージ
  agreementErrorMassage: string;
  // 同意するのチェックボックスの値を変更
  onChangeIsAgreement: (value: boolean) => void;
  // 同意するのチェックボックスの状態
  isAgreement: boolean;
  // ログアウトAPI(リダイレクト用)呼び出し状況
  logoutRedirectStatus: ApiStatus;
  // アクセス期限切れダイアログを表示するか否か
  shouldOpenAccessExpDialog: boolean;
  // 利用規約リンクURL
  agreementLink: string;
};

/**
 * 利用規約画面 hooks
 *
 * @returns
 */
export const useAgreement = (): useAgreementValue => {
  const {
    agreementUpdateState,
    fetchAgreementUpdate,
    resetAgreementUpdateState,
  } = useAgreementUpdateApi();
  const {
    doLogoutForSerial,
    doLogoutForPTID,
    resetLogoutState,
    logoutStatus,
    logoutResultCode,
    logoutRedirectStatus,
  } = useLogout({});
  const [isAgreement, setIsAgreement] = useState<boolean>(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { ptidExpired } = usePtidExpired();
  const { language } = useBrowserUserSetting();

  // 利用許諾状態
  const [agreementStatusType, setAgreementStatusType] =
    useState<AgreementStatusType>(AGREEMENT_STATUS.NONE);

  // 利用許諾エラーメッセージ
  const agreementErrorMassage = useMemo(
    () => AgreementErrorMessageMap[agreementStatusType],
    [agreementStatusType],
  );

  // アクセス期限切れダイアログを表示するか否か(true=表示)
  const [shouldOpenAccessExpDialog, setShouldOpenAccessExpDialog] =
    useState<boolean>(false);

  /**
   * 同意するチェックボックスのON/OFFを変更する
   */
  const onChangeIsAgreement = useCallback((value: boolean) => {
    setIsAgreement(value);
  }, []);

  /**
   * APIのステータスをリセットする
   */
  const resetApiState = useCallback(() => {
    resetAgreementUpdateState();
    resetLogoutState();
  }, [resetAgreementUpdateState, resetLogoutState]);

  /**
   * [利用を開始する]ボタンのクリック処理
   */
  const onClickAgreement = useCallback(() => {
    // エラーメッセージを非表示にするためリセット実施
    resetApiState();
    // 利用許諾API呼び出し
    fetchAgreementUpdate();
  }, [fetchAgreementUpdate, resetApiState]);

  /**
   * [同意しない]ボタンのクリック処理
   */
  const onClickDisagreement = useCallback(() => {
    // エラーメッセージを非表示にするためリセット実施
    resetApiState();
    // ユーザ種別ごとのログアウト処理実行
    const userType = findCookieValue(COOKIE_KEY_NAME.PTBR_TYPE);
    if (userType === COOKIE_USER_TYPE.SERIAL) {
      doLogoutForSerial();
    } else {
      doLogoutForPTID();
    }
  }, [doLogoutForPTID, doLogoutForSerial, resetApiState]);

  /**
   * 利用許諾APIのステータスが更新された時
   */
  useEffect(() => {
    if (!agreementUpdateState.data) {
      setAgreementStatusType(AGREEMENT_STATUS.NONE);

      return;
    }

    switch (agreementUpdateState.data.resultCode) {
      case AGREEMENT_UPDATE_API_RESULT_CODE.OK:
        setAgreementStatusType(AGREEMENT_STATUS.SUCCESS);
        // 呼び出し元のパスに遷移
        navigate(state.pathname);

        return;
      case AGREEMENT_UPDATE_API_RESULT_CODE.WARN_AUTH:
        // アクセス期限切れダイアログを表示
        setShouldOpenAccessExpDialog(true);

        return;
      case AGREEMENT_UPDATE_API_RESULT_CODE.INFO_EXPIRED_IDTOKEN:
        // PTID期限切れの場合はエラーメッセージ表示しない
        ptidExpired();

        return;
      default:
        setAgreementStatusType(AGREEMENT_STATUS.AGREEMENT_ERROR);
    }
  }, [agreementUpdateState.data, navigate, ptidExpired, state.pathname]);

  /**
   * ログアウトAPIのステータスが更新された時
   */
  useEffect(() => {
    if (!logoutStatus || !logoutResultCode) {
      setAgreementStatusType(AGREEMENT_STATUS.NONE);

      return;
    }

    switch (logoutResultCode) {
      case LOGOUT_API_RESULT_CODE.OK:
        setAgreementStatusType(AGREEMENT_STATUS.SUCCESS);

        return;
      case LOGOUT_API_RESULT_CODE.WARN_AUTH:
        // アクセス期限切れダイアログを表示
        setShouldOpenAccessExpDialog(true);

        return;
      default:
        setAgreementStatusType(AGREEMENT_STATUS.LOGOUT_ERROR);
    }
  }, [logoutResultCode, logoutStatus]);

  /**
   * 利用規約リンク
   * UI言語ごとに変える
   */
  const agreementLink = useMemo(() => {
    switch (language) {
      case LOCALE_LANG.JA:
        return AGREEMENT_LINK_URL_JA;
      default:
        return AGREEMENT_LINK_URL_EN;
    }
  }, [language]);

  return {
    onClickAgreement,
    onClickDisagreement,
    agreementUpdateState,
    logoutStatus,
    agreementErrorMassage,
    onChangeIsAgreement,
    isAgreement,
    logoutRedirectStatus,
    shouldOpenAccessExpDialog,
    agreementLink,
  };
};
