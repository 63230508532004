/**
 * 表示タイプ
 */
export const LIST_DISPLAY_TYPE = {
  // 縦表示
  onePhrase: 'onePhrase',
  // 横表示
  list: 'list',
} as const;

export type ListDisplayType =
  (typeof LIST_DISPLAY_TYPE)[keyof typeof LIST_DISPLAY_TYPE];

/**
 * フォントサイズ変更情報
 */
export const FONT_SIZE_INFO = {
  minLength: 1,
  maxLength: 10,
  step: 0.25,
  defaultValue: 1.8,
  point: 'rem',
} as const;

/**
 * 翻訳画面：暫定テキストの表示内容
 */
export type InterimContent = {
  // 暫定STTテキスト
  stt: string;
  // 暫定TTTテキスト
  ttt: string;
  // 翻訳元言語
  srclang: string;
  // 翻訳先言語
  destlang: string;
  // 話者が反転しているか否か
  isReversed: boolean;
};

/**
 * 翻訳画面：暫定テキストの初期値
 */
export const DEFAULT_INTERIM_CONTENT: InterimContent = {
  stt: '',
  ttt: '',
  srclang: '',
  destlang: '',
  isReversed: false,
} as const;

/**
 * 翻訳画面：表示内容
 */
export type RecognizedContent = {
  // 音声認識結果テキスト
  stt: string;
  // 翻訳結果テキスト
  ttt: string;
  // 音声認識言語
  srclang: string;
  // 翻訳言語
  destlang: string;
  // 翻訳日時
  date: string;
  // 話者が反転しているか否か
  isReversed: boolean;
};

/**
 * 翻訳画面：音声認識リストの1行分の型
 */
export type RecognizedItem = {
  // ユニークに識別するID(index番号)
  id: number;
  // 表示内容
  value: RecognizedContent;
};

/**
 * 翻訳画面：音声認識リストViewの型
 */
export type RecognizedList = {
  // 翻訳前/翻訳後テキストリストの表示内容
  lists: RecognizedItem[];
  // 表示タイプ
  displayType: ListDisplayType;
  // 暫定テキスト情報
  interimContent: InterimContent;
};

/**
 * 翻訳モード
 */
export const TRANS_MODE = {
  // 周囲の音声
  MIC: 'mic',
  // スピーカーの音声
  SPEAKER: 'speaker',
};
export type TransMode = (typeof TRANS_MODE)[keyof typeof TRANS_MODE];

/**
 * 翻訳画面への遷移時に渡す値
 */
export type TranslationState = {
  mode: TransMode;
};

/**
 * コーデック情報
 */
export type CodecInfo = {
  // ファイル形式
  format: string;
  // サンプリングレート
  sampleRate: number;
  // ビット深度
  bitDepth: number;
};

/**
 * 画面タイプ
 */
export const TRANSLATION_LIST_VIEW_TYPE = {
  // 翻訳画面
  TRANSLATION: 'translation',
  // カンファレンス画面
  CONFERENCE: 'conference',
};
export type TranslationListViewType =
  (typeof TRANSLATION_LIST_VIEW_TYPE)[keyof typeof TRANSLATION_LIST_VIEW_TYPE];
