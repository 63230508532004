import { CLOSE_EVENT_TYPE, CloseEventType } from '@/features/api';
import { STT_ERROR_TYPE } from '@/states/slices/translationInfoSlice';

/**
 * CloseEventTypeをSttErrorTypeに変換
 *
 * @param closeEvent
 * @param closeResponse
 * @returns
 */
export const convertCloseEventToSttError = (
  closeEvent: CloseEventType | undefined,
) => {
  if (closeEvent === undefined) {
    return STT_ERROR_TYPE.NONE;
  }

  if (closeEvent === CLOSE_EVENT_TYPE.SUCCESS) {
    return STT_ERROR_TYPE.NONE;
  }

  if (closeEvent === CLOSE_EVENT_TYPE.LIMIT_EXCEEDED_ERROR) {
    return STT_ERROR_TYPE.FREE_EXP;
  }

  if (closeEvent === CLOSE_EVENT_TYPE.CONNECTION_SHIFTED_ERROR) {
    return STT_ERROR_TYPE.CONNECTION_SHIFTED;
  }

  return STT_ERROR_TYPE.OTHER;
};
