import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogMessageContents, ModalDialog } from '@/components/Elements';

import { useRetryError } from '../../hooks/error/useRetryError';

type RetryErrorDialog = {
  isOpen: boolean;
};

/**
 * リトライ失敗エラーダイアログ コンポーネント
 *
 * @param param0 本ダイアログ表示/非表示
 * @returns
 */
export const RetryErrorDialog = React.memo(({ isOpen }: RetryErrorDialog) => {
  const { clickRetryErrorOKButton } = useRetryError();
  const { t } = useTranslation();

  return (
    <ModalDialog
      isOpen={isOpen}
      title={t(
        'translationDialog.サーバー混雑等の理由で現在通訳機能が利用できません',
      ).toString()}
      buttons={[
        {
          label: t('translationDialog.OK'),
          onClick: clickRetryErrorOKButton,
          gtmClassTag: 'dts-error-server',
        },
      ]}
    >
      <DialogMessageContents>
        {t('translationDialog.しばらく待ってから再度ご利用ください。')}
      </DialogMessageContents>
    </ModalDialog>
  );
});
