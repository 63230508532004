import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogMessageContents, ModalDialog } from '@/components/Elements';

/**
 * プロパティ
 */
type Props = {
  // ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // OKボタンクリック処理
  onClickOK: () => void;
};

/**
 * 共有復帰ダイアログ コンポーネント
 */
export const ShareRejoinDialog = React.memo(({ isOpen, onClickOK }: Props) => {
  const { t } = useTranslation();

  return (
    <ModalDialog
      isOpen={isOpen}
      title={t('shareRejoinDialog.中断された共有を再開しました').toString()}
      buttons={[
        {
          label: t('translationDialog.OK'),
          onClick: onClickOK,
          gtmClassTag: 'dialog-08',
        },
      ]}
    >
      <DialogMessageContents>
        <p />
      </DialogMessageContents>
    </ModalDialog>
  );
});
