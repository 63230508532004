import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogMessageContents, ModalDialog } from '@/components/Elements';

import { useNoSoundError } from '../../hooks/error/useNoSoundError';

type NoSoundErrorDialog = {
  // ダイアログ表示/非表示
  isOpen: boolean;
};

/**
 * 音声信号無音エラーダイアログ コンポーネント
 *
 * @param param0 本ダイアログ表示/非表示
 * @returns
 */
export const NoSoundErrorDialog = React.memo(
  ({ isOpen }: NoSoundErrorDialog) => {
    const { clickOkButtonNoSound } = useNoSoundError();
    const { t } = useTranslation();

    return (
      <ModalDialog
        isOpen={isOpen}
        title={t('noSoundError.音声が認識されていません').toString()}
        buttons={[
          {
            label: 'OK',
            onClick: clickOkButtonNoSound,
            gtmClassTag: 'dts-10seconds-ok',
          },
        ]}
      >
        <DialogMessageContents>
          {t(
            'noSoundError.音声が取得できません。デバイスの設定が正しいか確認してください。',
          )}
        </DialogMessageContents>
      </ModalDialog>
    );
  },
);
