import { z } from 'zod';

/**
 * シリアル：バリデーション
 */
export const SERIAL_VALIDATE = {
  LENGTH: {
    MIN: 22, // 最小文字数
    MAX: 25, // 最大文字数
  } as const,
} as const;

/**
 * バリデーション定義
 */
export const SERIAL_FORM_SCHEMA = z.object({
  serial: z
    .string()
    .min(
      SERIAL_VALIDATE.LENGTH.MIN,
      'コードは22文字以上、25文字以内で入力してください',
    )
    .max(
      SERIAL_VALIDATE.LENGTH.MAX,
      'コードは22文字以上、25文字以内で入力してください',
    )
    .regex(/^[A-Z]{5}(-[0-9]{4}){4}$|^[A-Z]{5}-[-0-9]{16}$/, {
      message: 'コードの形式が不正です',
    }),
});
export type SerialForm = z.infer<typeof SERIAL_FORM_SCHEMA>;

/**
 * シリアル認証状態
 */
export const SERIAL_STATUS = {
  // 何もしていない
  NONE: 'NONE',
  // エラー
  ERROR: 'ERROR',
  // 成功
  SUCCESS: 'SUCCESS',
};
export type SerialStatusType =
  (typeof SERIAL_STATUS)[keyof typeof SERIAL_STATUS];

/**
 * シリアルエラーメッセージ
 */
export const SerialErrorMessageMap: { [key in SerialStatusType]: string } = {
  [SERIAL_STATUS.NONE]: '',
  [SERIAL_STATUS.ERROR]: '認証に失敗しました。コードを再入力してください。',
  [SERIAL_STATUS.SUCCESS]: '',
};
