import { useEffect, useRef } from 'react';

import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { SHARE_DISPLAY_STATUS } from '@/states/slices/translationInfoSlice';

/**
 * 本カスタムからの返却値
 */
export type UseSystemAudiValue = {
  // 画面共有開始
  requestStartShare: (
    // スピーカー音声データ受信時にコールバック
    callback: (data: ArrayBufferLike) => void,
    // 共有停止された際にコールバック
    endedCallback?: () => void,
  ) => Promise<void>;
  // 画面共有終了
  stopShare: () => void;
  // 収録開始
  startRecording: () => void;
  // 収録停止
  stopRecording: () => void;
};

/**
 * システムオーディオ hooks
 *
 * @returns
 */
export const useSystemAudio = (): UseSystemAudiValue => {
  const audioContext = useRef<AudioContext | null>(null);
  const audioWorklet = useRef<AudioWorkletNode | null>(null);
  const inputStream = useRef<MediaStreamAudioSourceNode | undefined>(undefined);
  const mediaStream = useRef<MediaStream | null>(null);

  const { setShareDisplayStatus } = useTranslationInfo();

  /**
   * 音声データのオブジェクトがundefinedかチェックする
   *
   * @returns true=undefined
   */
  const isAudioObjectsClose = (): boolean => {
    if (
      audioContext.current ||
      audioWorklet.current ||
      inputStream.current ||
      mediaStream.current
    ) {
      return false;
    }

    return true;
  };

  /**
   * 画面共有リクエスト
   * ※画面共有直後は録音されない
   *
   * @param callback スピーカー音声データ受信時にコールバック
   * @param endedShareCallback 共有停止された際にコールバック
   */
  const requestStartShare = async (
    callback: (data: ArrayBufferLike) => void,
    endedCallback?: () => void,
  ) => {
    // 生成されたオブジェクトが残っていないかをチェック
    // 後々字幕アプリのコードに合わせて対策処理を入れる予定なので、現段階ではアサーションメッセージのみ出力する。
    // eslint-disable-next-line no-console
    console.assert(isAudioObjectsClose(), 'audioContextがundefinedじゃない');

    audioContext.current = new AudioContext();
    audioContext.current.audioWorklet
      .addModule('/audioProcessor.js')
      .then(() => {
        // 画面共有開始
        navigator.mediaDevices
          .getDisplayMedia({
            audio: { echoCancellation: true },
            video: true,
          })
          .then(
            (stream) =>
              new Promise<void>((resolve, reject) => {
                // 共有成功
                setShareDisplayStatus(SHARE_DISPLAY_STATUS.SUCCESS);

                mediaStream.current = stream;

                if (
                  stream.getVideoTracks().length > 0 &&
                  stream.getVideoTracks()[0].label.startsWith('screen')
                ) {
                  setShareDisplayStatus(SHARE_DISPLAY_STATUS.SUCCESS_SCREEN);
                }

                if (!audioContext || !audioContext.current) {
                  reject();

                  return;
                }

                try {
                  // AudioContextで音声ストリームを扱えるように設定
                  inputStream.current =
                    audioContext.current.createMediaStreamSource(stream);

                  audioWorklet.current = new AudioWorkletNode(
                    audioContext.current,
                    'audio-worklet-stream',
                  );

                  // 接続
                  audioWorklet.current.connect(
                    audioContext.current.destination,
                  );
                  inputStream.current.connect(audioWorklet.current);
                } catch {
                  // 音声トラックが含まれていない場合、共有を停止する
                  setShareDisplayStatus(SHARE_DISPLAY_STATUS.NO_AUDIO);

                  return;
                }

                // audio-worklet-streamからデータ受信
                audioWorklet.current.port.start();
                audioWorklet.current.port.addEventListener(
                  'message',
                  (event) => {
                    callback(event.data);
                  },
                );

                // 共有停止イベントの検知
                const [displayVideoTrack] = stream.getTracks();
                displayVideoTrack.addEventListener('ended', () => {
                  setShareDisplayStatus(SHARE_DISPLAY_STATUS.NONE);
                  if (endedCallback) endedCallback();
                });

                resolve();
              }),
          )
          .catch((e) => {
            // 共有キャンセル
            if (e && e.name === 'NotAllowedError') {
              return;
            }

            // 共有失敗
            setShareDisplayStatus(SHARE_DISPLAY_STATUS.ERROR);
          });
      })
      .catch((_) => {
        // 共有失敗
        setShareDisplayStatus(SHARE_DISPLAY_STATUS.ERROR);
      });
  };

  /**
   * 画面共有利用終了(破棄)
   */
  const stopShare = (): void => {
    if (audioWorklet && audioWorklet.current) {
      audioWorklet.current.port.close();
      audioWorklet.current.disconnect();
    }
    if (inputStream && inputStream.current) {
      inputStream.current.disconnect();
    }
    if (audioContext && audioContext.current) {
      audioContext.current.close();
    }
    if (mediaStream && mediaStream.current) {
      mediaStream.current
        .getTracks()
        .forEach((track: MediaStreamTrack) => track.stop());
    }

    audioContext.current = null;
    inputStream.current = undefined;
    audioWorklet.current = null;
    mediaStream.current = null;
  };

  /**
   * 収録開始
   *
   * @returns
   */
  const startRecording = (): void => {
    if (!audioContext || !audioContext.current) {
      return;
    }
    if (audioContext.current.state === 'running') {
      // すでに収録中の場合は何もしない
      return;
    }
    // 収録開始
    audioContext.current.resume();
  };

  /**
   * 収録停止
   *
   * @returns
   */
  const stopRecording = (): void => {
    if (!audioContext || !audioContext.current) {
      return;
    }
    // すでに停止中の場合は何もしない
    if (audioContext.current.state === 'suspended') {
      return;
    }
    // 収録停止
    audioContext.current.suspend();
  };

  /**
   * マウント/アンマウント時に実行する処理
   */
  useEffect(
    () => () => {
      if (!isAudioObjectsClose()) {
        // 画面共有利用終了(破棄)
        stopShare();
      }
    },
    // コンポーネントのアンマウント時に1度だけ実行
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    requestStartShare,
    stopShare,
    startRecording,
    stopRecording,
  };
};
