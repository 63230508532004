import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import accountPlan from '@/assets/account_plan.svg';
import { PlanDialog } from '@/features/plan';
import useOpen from '@/hooks/useOpen';

import { useTranslationPath } from '../../hooks/useTranslationPath';

/**
 * 設定メニュー プロパティ
 */
type PlanContainer = {
  onCloseMenu: () => void;
};
/**
 * ヘッダー > メインメニュー > プランメニュー
 */
export const PlanContainer = React.memo(({ onCloseMenu }: PlanContainer) => {
  const { isOpen, onClose, onOpen } = useOpen();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isTranslationPath } = useTranslationPath();

  return (
    <>
      <button
        type="button"
        onClick={() => {
          onOpen();
          onCloseMenu();
        }}
        className={isTranslationPath(pathname) ? 'dts-transplan' : 'dts-plan'}
      >
        <img src={accountPlan} alt="accountPlan" />
        {t('mainMenu.プラン情報')}
      </button>

      {/* useOpenのisOpenでレンダリング制御しているため、常にtrue */}
      {isOpen && <PlanDialog isOpen onClose={onClose} />}
    </>
  );
});
