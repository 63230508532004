import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import logout from '@/assets/logout.svg';
import { SpinnerOverlayView } from '@/components/Elements';
import { API_STATUS } from '@/constants';
import { LogoutDialog } from '@/features/logout';
import useOpen from '@/hooks/useOpen';

import { useLogoutContainer } from '../../hooks/logout/useLogoutContainer';
import { useTranslationPath } from '../../hooks/useTranslationPath';

/**
 * プロパティ
 */
type LogoutInfoContainer = {
  onCloseMenu: () => void;
};

/**
 * ヘッダー > メインメニュー > ログアウトメニュー
 */
export const LogoutContainer = React.memo(
  ({ onCloseMenu }: LogoutInfoContainer) => {
    const { isOpen, onClose, onOpen } = useOpen();
    const { t } = useTranslation();
    const { shouldOpenLogoutDialog, onClickLogoutMenu, logoutRedirectStatus } =
      useLogoutContainer();
    const { pathname } = useLocation();
    const { isTranslationPath } = useTranslationPath();

    return (
      <>
        <button
          type="button"
          onClick={() => {
            onOpen();
            onClickLogoutMenu(onCloseMenu);
          }}
          className={
            isTranslationPath(pathname) ? 'dts-translogout' : 'dts-logout'
          }
        >
          <img src={logout} alt="logout" />
          {t('mainMenu.ログアウト')}
        </button>

        <SpinnerOverlayView
          isOpen={logoutRedirectStatus === API_STATUS.LOADING}
        />
        <LogoutDialog
          isOpen={shouldOpenLogoutDialog && isOpen}
          onClose={onClose}
        />
      </>
    );
  },
);
