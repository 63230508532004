import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { PAGE_ROUTE_PATH } from '@/constants';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { STT_ERROR_TYPE } from '@/states/slices/translationInfoSlice';

/**
 * 5分無音エラー、90分連続使用エラー カスタムフック
 */
export const useSilenceContinuousError = () => {
  const navigate = useNavigate();
  const { setSttErrorType } = useTranslationInfo();

  /**
   * 5分無音エラーダイアログの
   * キャンセルボタンがクリックされた場合の処理
   */
  const clickCancelButtonSilence = useCallback(() => {
    // エラーダイアログを閉じる
    setSttErrorType(STT_ERROR_TYPE.NONE);
  }, [setSttErrorType]);

  /**
   * 5分無音エラーダイアログ、90分連続使用エラーダイアログの
   * 再開ボタンがクリックされた場合の処理
   *
   * @param clickSttButton [STT]ボタンのクリック処理
   */
  const clickRestartButton = useCallback(
    (clickSttButton: () => void) => {
      // エラーダイアログを閉じる
      setSttErrorType(STT_ERROR_TYPE.NONE);
      // [STT]ボタンをクリックしてWebsocket再接続
      clickSttButton();
    },
    [setSttErrorType],
  );

  /**
   * 90分連続使用エラーダイアログの
   * キャンセルボタンがクリックされた場合の処理
   */
  const clickCancelButtonContinuous = useCallback(() => {
    // ホーム画面に遷移
    navigate({
      pathname: PAGE_ROUTE_PATH.HOME,
    });
  }, [navigate]);

  return {
    clickCancelButtonSilence,
    clickRestartButton,
    clickCancelButtonContinuous,
  };
};
