import { GA_EVENT_NAME, GuestCampaignDisplayType } from '@/constants/ga';

/**
 * UI言語切り替え GAイベントを送信
 */
export const sendChangeUiLangEvent = (value: { ui_lang: string }) => {
  window.gtag('event', GA_EVENT_NAME.CHANGE_UI_LANG, value);
};

/**
 * 翻訳元言語切り替え GAイベントを送信
 */
export const sendChangeSrcLangEvent = (value: { src_lang: string }) => {
  window.gtag('event', GA_EVENT_NAME.CHANGE_SRC_LANG, value);
};

/**
 * 翻訳先言語切り替え GAイベントを送信
 */
export const sendChangeDestLangEvent = (value: { dest_lang: string }) => {
  window.gtag('event', GA_EVENT_NAME.CHANGE_DEST_LANG, value);
};

/**
 * ゲスト画面キャンペーンポップアップ GAイベントを送信
 */
export const sendGuestCampaignPopupEvent = (value: {
  display_type: GuestCampaignDisplayType;
}) => {
  window.gtag('event', GA_EVENT_NAME.GUEST_CAMPAIGN, value);
};
