import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { PAGE_ROUTE_PATH } from '@/constants';
import { useBrowserLanguage } from '@/hooks/useBrowserLanguage';
import { useGoogleTtsVoiceList } from '@/hooks/useGoogleTtsVoiceList';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import {
  MIC_STATUS,
  SHARE_DISPLAY_STATUS,
} from '@/states/slices/translationInfoSlice';

/**
 * メニュー表示制御 hooks
 *
 * @returns
 */
export const useMenuValidator = () => {
  const { micStatus, shareDisplayStatus } = useTranslationInfo();
  const { pathname } = useLocation();
  const { destlang } = useBrowserLanguage();
  const { isSupportedLangGoogleTTS } = useGoogleTtsVoiceList();

  /**
   * メニューボタン自体を非表示にするか
   *
   * @returns true=非表示にする
   */
  const isMenuHidden = useMemo((): boolean => {
    // 特定の画面の場合は表示にする
    if (pathname === PAGE_ROUTE_PATH.HOME) {
      return false;
    }
    if (pathname === PAGE_ROUTE_PATH.TRANSLATION.MIC) {
      return false;
    }
    if (pathname === PAGE_ROUTE_PATH.TRANSLATION.SPEAKER) {
      return false;
    }

    return true;
  }, [pathname]);

  /**
   * 翻訳関連のメニューをDisabledにするか否か
   *
   * @returns true=Disabledにする
   */
  const isTranslationMenuDisabled = useMemo(() => {
    // マイク認識失敗
    if (micStatus === MIC_STATUS.ERROR) {
      return true;
    }
    // 音声キャプチャ共有失敗
    if (shareDisplayStatus === SHARE_DISPLAY_STATUS.ERROR) {
      return true;
    }
    // 画面共有開始時に「音声を共有」のチェックがONになっていない
    if (shareDisplayStatus === SHARE_DISPLAY_STATUS.NO_AUDIO) {
      return true;
    }

    return false;
  }, [micStatus, shareDisplayStatus]);

  /**
   * TTSボタンをDisabledにするか否か(Google TTS API非対応言語か)
   *
   * true=Disabledにする
   */
  const isTtsMenuDisabled = useMemo(
    () => !isSupportedLangGoogleTTS(destlang),
    [isSupportedLangGoogleTTS, destlang],
  );

  return {
    isMenuHidden,
    isTranslationMenuDisabled,
    isTtsMenuDisabled,
  };
};
