import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { LangData } from '@/constants/language';
import {
  languageListSlice,
  LanguageListState,
} from '@/states/slices/languageListSlice';
import { AppDispatch, RootState, useAppDispatch } from '@/states/store';

/**
 * 翻訳元言語一覧、翻訳先言語一覧をReduxに保存 hooks
 *
 * @returns
 */
export const useLanguageList = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const { setSrcLangList, setDestLangList, clearLanguageList } =
    languageListSlice.actions;

  const { srcLangList, destLangList } = useSelector<
    RootState,
    LanguageListState
  >((state) => state.languageList);

  /**
   * 翻訳元言語リストを更新
   */
  const changeSrclangList = useCallback(
    (value: LangData[]) => {
      dispatch(setSrcLangList(value));
    },
    [dispatch, setSrcLangList],
  );

  /**
   * 翻訳先言語リストを更新
   */
  const changeDestlangList = useCallback(
    (value: LangData[]) => {
      dispatch(setDestLangList(value));
    },
    [dispatch, setDestLangList],
  );

  /**
   * 言語リストに関する全てのStateをリセット
   */
  const resetState = useCallback(() => {
    dispatch(clearLanguageList());
  }, [dispatch, clearLanguageList]);

  return {
    srcLangList,
    destLangList,
    setSrcLangList: changeSrclangList,
    setDestLangList: changeDestlangList,
    resetState,
  };
};
