/**
 * Cookieのキー名定義
 */
export const COOKIE_KEY_NAME = {
  // サーバで発行されたトークン
  PTBR_TOKEN: 'PTBR_TOKEN',
  // トークン種別
  PTBR_TYPE: 'PTBR_TYPE',
  // PTID認証時に指定するstateパラメータ(リダイレクト先のURLを除いた値を保持)
  PTBR_STATE: 'PTBR_STATE',
} as const;

/**
 * Cookieに保存されるユーザ種別
 */
export const COOKIE_USER_TYPE = {
  // シリアル入力で利用開始したユーザ
  SERIAL: 'serial',
  // ポケトークIDで利用開始したユーザ（サブスク契約中）
  SUBSC: 'subsc',
  // ポケトークIDで利用開始したユーザ（サブスク未契約）
  FREE: 'free',
} as const;
