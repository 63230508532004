import { useCallback, useEffect, useRef } from 'react';

import { API_STATUS } from '@/constants';
import { LANG_UPDATE_API_RESULT_CODE } from '@/features/api';
import { useLangSynchronize } from '@/features/langupdate';
import { useBrowserUserSetting } from '@/hooks/useBrowserUserSetting';
import { sendChangeUiLangEvent } from '@/utils/ga';

/**
 * 処理完了時に実行したい関数の型
 */
export type FailedFunction = () => void;

/**
 * 本カスタムフックのプロパティ
 */
type Props = {
  // 失敗時に実行したい処理
  onFailedFun?: FailedFunction;
  // アクセス期限切れ時に実行したい処理
  onWarnAuthFun: FailedFunction;
};

/**
 * UI言語操作 カスタムフック
 *
 * @returns
 */
export const useUILanguage = ({ onFailedFun, onWarnAuthFun }: Props) => {
  const { setLanguage } = useBrowserUserSetting();
  const { synchronizeLang, langUpdateState: state } = useLangSynchronize();

  const onFailedFuncRef = useRef<FailedFunction>();
  useEffect(() => {
    onFailedFuncRef.current = onFailedFun;
  }, [onFailedFun]);
  const onWarnAuthFuncRef = useRef<FailedFunction>();
  useEffect(() => {
    onWarnAuthFuncRef.current = onWarnAuthFun;
  }, [onWarnAuthFun]);

  /**
   * 変更されたUI言語をローカルストレージに保存
   */
  const onChangeUILang = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      // 選択されたUI言語をローカルストレージに保存
      const { value } = e.currentTarget;
      setLanguage(value);
      // 利用言語同期
      synchronizeLang(value);

      // GAイベントを送信
      sendChangeUiLangEvent({ ui_lang: value });
    },
    [setLanguage, synchronizeLang],
  );

  // 利用言語更新APIのステータスを監視
  useEffect(() => {
    if (state.status === API_STATUS.FAILED) {
      // 失敗時の処理実行
      onFailedFuncRef.current?.();
      if (state.data?.resultCode === LANG_UPDATE_API_RESULT_CODE.WARN_AUTH) {
        // アクセス期限切れ時の処理実行
        onWarnAuthFuncRef.current?.();
      }
    }
  }, [state]);

  return {
    // UI言語が変更された時の処理
    onChangeUILang,
    // 利用言語更新状態
    langUpdateStatus: state.status,
  };
};
