import { BROWSER } from '@/constants';
import { browserAxios } from '@/lib/axios';

import { API_AUTH_RESULT_CODE } from './apiAuth';

/**
 * 結果コード定数
 */
export const DELETE_VIEW_API_RESULT_CODE = {
  ...({
    // 成功
    OK: 'OK',
    // 不明エラー
    ERR_UNKNOWN: 'ERR_UNKNOWN',
  } as const),
  ...API_AUTH_RESULT_CODE,
} as const;
export type DeleteViewResultCode =
  (typeof DELETE_VIEW_API_RESULT_CODE)[keyof typeof DELETE_VIEW_API_RESULT_CODE];

/**
 * 結果コードのTypeGuard
 *
 * @param resultCode
 * @returns
 */
export const isDeleteViewApiResultCode = (
  resultCode: string,
): resultCode is DeleteViewResultCode => {
  if (resultCode in DELETE_VIEW_API_RESULT_CODE) {
    return true;
  }

  return false;
};

/**
 * レスポンス
 */
export type DeleteViewApiResponse = {
  // 結果コード
  resultCode: DeleteViewResultCode;
};

/**
 * API 呼び出し
 *
 * @returns
 */
const callApi = (): Promise<DeleteViewApiResponse> => {
  // リクエスト送信
  const response = browserAxios
    .get<DeleteViewApiResponse>(BROWSER.API_URL.DELETE_VIEW, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((apiResponse) => apiResponse.data);

  return response;
};

/**
 * 共有画面破棄API リクエスト
 *
 * @returns
 */
export const deleteViewApi = (): Promise<DeleteViewApiResponse> => callApi();
