import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  COOKIE_KEY_NAME,
  COOKIE_USER_TYPE,
  PAGE_ROUTE_PATH,
  URL_PARAMS_KEY,
} from '@/constants';
import { findCookieValue } from '@/utils/cookie';

/**
 * ライセンス不正ダイアログのプロパティ
 */
type LicenseInvalidDialogContents = {
  // ダイアログタイトル
  title: string;
  // ダイアログメッセージ
  message: string;
  // GTMクラスタグ
  gtmClassTag: string;
};

/**
 * ライセンス不正 カスタムフック
 */
export const useLicenseInvalid = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const dialogTitle = useMemo(
    () => t('licenseInvalidDialog.このライセンスはご利用できません').toString(),
    [t],
  );

  /**
   * ダイアログタイトル、ダイアログメッセージ、GTMクラスタグをセットで返す
   */
  const licenseInvalidDialogContents =
    useMemo((): LicenseInvalidDialogContents => {
      if (
        findCookieValue(COOKIE_KEY_NAME.PTBR_TYPE) === COOKIE_USER_TYPE.SERIAL
      ) {
        return {
          title: dialogTitle,
          message: t(
            'licenseInvalidDialog.ログアウトして再度お試しください。',
          ).toString(),
          gtmClassTag: 'dts-error-licence-serial',
        };
      }

      return {
        title: dialogTitle,
        message: t(
          'licenseInvalidDialog.ライセンスを再購入すると引き続きご利用いただけます。',
        ).toString(),
        gtmClassTag: 'dts-error-licence-ptid',
      };
    }, [dialogTitle, t]);

  /**
   * ライセンス不正ダイアログ用OKボタンのクリック処理
   */
  const clickLicenseInvalidOKButton = useCallback(() => {
    const userType = findCookieValue(COOKIE_KEY_NAME.PTBR_TYPE);
    // シリアルで利用中
    if (userType === COOKIE_USER_TYPE.SERIAL) {
      if (pathname === PAGE_ROUTE_PATH.HOME) {
        // ホーム画面を表示中の場合はリロード
        navigate(0);

        return;
      }
      // ホーム画面に遷移
      navigate({
        pathname: PAGE_ROUTE_PATH.HOME,
      });

      return;
    }

    // ようこそ画面に遷移。このときホーム画面のパスを指定
    navigate({
      pathname: PAGE_ROUTE_PATH.WELCOME,
      search: encodeURI(
        `${URL_PARAMS_KEY.WELCOME.path}=${PAGE_ROUTE_PATH.HOME}`,
      ),
    });
  }, [navigate, pathname]);

  return {
    clickLicenseInvalidOKButton,
    licenseInvalidDialogContents,
  };
};
