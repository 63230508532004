import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { TOAST_ICON_TYPE } from '@/components/Elements';
import { useGoogleTtsVoiceList } from '@/hooks/useGoogleTtsVoiceList';
import { useToastInfo } from '@/hooks/useToastInfo';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { TTS_STATUS } from '@/states/slices/translationInfoSlice';

import { useTranslationPath } from '../../header/hooks/useTranslationPath';

/**
 * 本カスタムフックからの返却値
 */
export type Value = {
  // [TTS ON/OFF]ボタンがクリックされた際の処理
  clickTtsButton: () => void;
  // 翻訳先言語がGoogle TTS API非対応かつ、音声合成(読み上げ)状態が「読み上げ中」の場合はTTSを停止する
  pauseTtsOnDestLang: (currentDestlang: string) => void;
  // TTS再生中か否か
  isTTSPlaying: boolean;
};

/**
 * TTS ON/OFFボタン hooks
 */
export const useTtsButton = (): Value => {
  const { ttsStatus, setTtsStatus } = useTranslationInfo();
  const { isTranslationMic } = useTranslationPath();
  const { addToastMessage } = useToastInfo();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { isSupportedLangGoogleTTS } = useGoogleTtsVoiceList();

  /**
   * TTS再生中か否か
   *
   * @returns TTS再生中の場合はtrue
   */
  const isTTSPlaying = useMemo(
    () => ttsStatus === TTS_STATUS.READING,
    [ttsStatus],
  );

  /**
   * [TTS ON/OFF]ボタンがクリックされた際の処理
   */
  const clickTtsButton = useCallback(() => {
    // 読み上げ開始
    if (!isTTSPlaying) {
      // マイク翻訳時のみ、トーストメッセージを表示
      if (isTranslationMic(pathname)) {
        addToastMessage(
          t('speaker.ヘッドセットかイヤホンをご利用ください。'),
          TOAST_ICON_TYPE.INFO,
        );
      }
      // 状態を「読み上げ中」に更新
      setTtsStatus(TTS_STATUS.READING);

      return;
    }

    // 読み上げ停止
    if (isTTSPlaying) {
      // 状態を「停止中」に更新
      setTtsStatus(TTS_STATUS.PAUSED);
    }
  }, [
    addToastMessage,
    isTTSPlaying,
    isTranslationMic,
    pathname,
    setTtsStatus,
    t,
  ]);

  /**
   * 翻訳先言語がGoogle TTS API非対応かつ、
   * 音声合成(読み上げ)状態が「読み上げ中」の場合、
   * TTSを停止する
   *
   * @param currentDestlang 翻訳先言語
   */
  const pauseTtsOnDestLang = useCallback(
    (currentDestlang: string): void => {
      if (isSupportedLangGoogleTTS(currentDestlang)) {
        // TTS対応言語の場合何もせずreturn
        return;
      }

      // Reduxに保存された「音声合成(読み上げ)状態」を確認
      if (!isTTSPlaying) {
        // 読み上げ中以外は何もしない
        return;
      }

      // 状態を「停止中」に更新
      setTtsStatus(TTS_STATUS.PAUSED);
    },
    [isSupportedLangGoogleTTS, isTTSPlaying, setTtsStatus],
  );

  return {
    clickTtsButton,
    pauseTtsOnDestLang,
    isTTSPlaying,
  };
};
