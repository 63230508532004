import { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { API_STATUS, PAGE_ROUTE_PATH } from '@/constants';
import {
  LOGOUT_API_RESULT_CODE,
  useLogoutRedirectApi,
  useLogoutApi,
} from '@/features/api';
import { usePtidExpired } from '@/features/expired';
import { useClearLocalStorage } from '@/hooks/useClearLocalStorage';

/**
 * ログアウト成功時に実行したい関数の型
 */
export type SuccessFunction = () => void;

/**
 * プロパティ
 */
export type Props = {
  // ログアウト成功時に実行したい処理
  onSuccessFun?: SuccessFunction;
};

/**
 * ログアウト処理 カスタムフック
 *
 * @returns
 */
export const useLogout = ({ onSuccessFun }: Props) => {
  const { logoutState, fetchLogout, resetLogoutState } = useLogoutApi();
  const { logoutRedirectState, doLogoutRedirect } = useLogoutRedirectApi();
  const { doCleanLocalStorage } = useClearLocalStorage();
  const { ptidExpired } = usePtidExpired();
  const navigate = useNavigate();

  const onSuccessFuncRef = useRef<SuccessFunction>();
  useEffect(() => {
    onSuccessFuncRef.current = onSuccessFun;
  }, [onSuccessFun]);

  /**
   * シリアルで利用中にログアウトを実行する
   */
  const doLogoutForSerial = useCallback(() => {
    // ログアウトAPIにリクエスト送信
    fetchLogout();
  }, [fetchLogout]);

  /**
   * PTIDで利用中にログアウトを実行する
   */
  const doLogoutForPTID = useCallback(() => {
    // プロパティに指定された関数実行
    onSuccessFuncRef.current?.();
    // ローカルストレージをリセット
    doCleanLocalStorage();
    // ログアウトAPI(リダイレクト用)にGETアクセス
    doLogoutRedirect();
  }, [doCleanLocalStorage, doLogoutRedirect]);

  /**
   * シリアル利用中のログアウト成功時の処理
   */
  const logoutForSerial = useCallback(() => {
    // プロパティに指定された関数実行
    onSuccessFuncRef.current?.();
    // ローカルストレージをリセット
    doCleanLocalStorage();
    // 利用方法選択画面に遷移
    navigate(PAGE_ROUTE_PATH.SELECT_USAGE);
  }, [doCleanLocalStorage, navigate]);

  /**
   * エラーダイアログ or エラーメッセージ表示
   */
  const showErrorDialogOrMessage = useCallback(() => {
    if (
      logoutState.data?.resultCode ===
      LOGOUT_API_RESULT_CODE.INFO_EXPIRED_IDTOKEN
    ) {
      // PTID期限切れ処理実行
      ptidExpired();
    }
  }, [logoutState.data?.resultCode, ptidExpired]);

  /**
   * ログアウトAPIが実行された時
   */
  useEffect(() => {
    // 失敗
    if (logoutState.status === API_STATUS.FAILED) {
      showErrorDialogOrMessage();

      return;
    }

    // 成功
    if (logoutState.status === API_STATUS.SUCCESS) {
      // ログアウト処理実行
      logoutForSerial();
    }
  }, [logoutForSerial, logoutState.status, showErrorDialogOrMessage]);

  return {
    doLogoutForSerial,
    doLogoutForPTID,
    resetLogoutState,
    logoutStatus: logoutState.status,
    logoutResultCode: logoutState.data?.resultCode,
    logoutRedirectStatus: logoutRedirectState.status,
  };
};
