import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CommonErrorDialogType } from '@/constants/error';

/**
 * State
 */
export type CommonErrorDialogState = {
  commonErrorDialogType: CommonErrorDialogType | undefined;
};

/**
 * 初期State
 */
const initialState: CommonErrorDialogState = {
  commonErrorDialogType: undefined,
};

/**
 * 共通エラーダイアログ情報 Slice
 */
export const commonErrorDialogSlice = createSlice({
  name: 'commonErrorDialog',
  initialState,
  reducers: {
    // エラーダイアログのタイプを更新
    setCommonErrorDialogType: (
      state,
      action: PayloadAction<CommonErrorDialogType>,
    ) => {
      state.commonErrorDialogType = action.payload;
    },

    // リセット
    resetToInitialState: () => initialState,
  },
});
