import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';

import type { RootState } from '../store';

/**
 * 1日分の通訳履歴
 */
export type BrowserHistory = {
  // ユニークに識別するID(index番号)
  id: string;
  // 通訳履歴データ一覧(暗号化前)
  list: HistoryData[];
};

/**
 * 1日分の通訳履歴
 * ※通訳履歴データは暗号化済
 */
type BrowserHistoryEncrypted = {
  // ユニークに識別するID(index番号)
  id: string;
  // 通訳履歴データ一覧(暗号化後の文字列)
  list: string;
};

/**
 * 通訳履歴データ
 */
export type HistoryData = {
  通訳日時: string;
  通訳元言語: string;
  通訳元テキスト: string;
  通訳先言語: string;
  通訳先テキスト: string;
};

/**
 * State
 */
export type BrowserHistoryState = {
  // ログ
  isHistory: boolean;
  // 通訳履歴一覧
  historyList: EntityState<BrowserHistoryEncrypted>;
};

export const browserHistoryAdapter =
  createEntityAdapter<BrowserHistoryEncrypted>({
    selectId: (item) => item.id,
  });

export const browserHistoryAdapterSelectors =
  browserHistoryAdapter.getSelectors(
    (state: RootState) => state.browserHistory.historyList,
  );

/**
 * 初期State
 */
const initialState: BrowserHistoryState = {
  isHistory: false,
  historyList: browserHistoryAdapter.getInitialState(),
};

/**
 * 通訳履歴管理 Slice
 */
export const browserHistorySlice = createSlice({
  name: 'browserHistory',
  initialState,
  reducers: {
    // 通訳履歴を保存するか否かを更新
    setIsHistory: (state, action: PayloadAction<boolean>) => {
      state.isHistory = action.payload;
    },
    // 1日分の通訳履歴データを追加
    addOneHistoryList: (
      state,
      action: PayloadAction<BrowserHistoryEncrypted>,
    ) => {
      browserHistoryAdapter.addOne(state.historyList, action.payload);

      return state;
    },
    // 1日分の通訳履歴データを更新、無ければ追加
    upsertOneHistoryList: (
      state,
      action: PayloadAction<BrowserHistoryEncrypted>,
    ) => {
      browserHistoryAdapter.upsertOne(state.historyList, action.payload);
    },
    // 1日分の通訳履歴データを削除
    removeOneHistoryList: (state, action: PayloadAction<string>) => {
      browserHistoryAdapter.removeOne(state.historyList, action.payload);
    },
    // 通訳履歴を全て削除
    removeAllHistoryList: (state) => {
      browserHistoryAdapter.removeAll(state.historyList);
    },
    // リセット
    clearToken: () => initialState,
  },
});
