import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { VoiceInfo } from '../../features/texttospeech/constants';

export type googleTtsVoiceListState = {
  ttsVoiceInfo: VoiceInfo;
};

/**
 * 初期State
 */
const initialState: googleTtsVoiceListState = {
  ttsVoiceInfo: {},
};

/**
 * Google TTS APIの発話情報の管理 Slice
 */
export const googleTtsVoiceListSlice = createSlice({
  name: 'googleTtsVoiceList',
  initialState,
  reducers: {
    // TTS発話情報を更新
    setGoogleTtsVoiceList: (state, action: PayloadAction<VoiceInfo>) => {
      state.ttsVoiceInfo = action.payload;
    },

    // リセット
    resetToInitialState: () => initialState,
  },
});
