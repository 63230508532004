import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogMessageContents, ModalDialog } from '@/components/Elements';

import { useContractRenewal } from '../hooks/useContractRenewal';

/**
 * プロパティ
 */
type ContractRenewalDialog = {
  isOpen: boolean;
};

/**
 * 契約状態更新後の再ログインダイアログ コンポーネント
 */
export const ContractRenewalDialog = React.memo(
  ({ isOpen }: ContractRenewalDialog) => {
    const { clickContractRenewalOKButton } = useContractRenewal();
    const { t } = useTranslation();

    return (
      <ModalDialog
        isOpen={isOpen}
        title={t('plan.再ログインが必要です').toString()}
        buttons={[{ label: 'OK', onClick: clickContractRenewalOKButton }]}
      >
        <DialogMessageContents>
          {t(
            'plan.プランが変更された可能性があります。プラン情報更新のため再ログインしてください。',
          )}
        </DialogMessageContents>
      </ModalDialog>
    );
  },
);
