import { useCallback, useEffect, useRef } from 'react';

import { useAudioInfo } from '@/hooks/useAudioInfo';
import { removeWavFile } from '@/utils/converters/audioFileConverter';

/**
 * HTMLMediaElement操作カスタムフック
 *
 * @param param0
 * @returns
 */
export const useAudio = () => {
  const { setIsAudioPlay, resetState } = useAudioInfo();

  // HTMLAudioElement
  const audioRef = useRef<HTMLAudioElement>(new Audio());

  /**
   * 再生
   */
  const play = useCallback(
    (url: string) => {
      audioRef.current.src = url;
      audioRef.current.load();
      void audioRef.current.play();
      setIsAudioPlay(true);
    },
    [setIsAudioPlay],
  );

  /**
   * 停止
   */
  const pause = useCallback(() => {
    audioRef.current.pause();
    setIsAudioPlay(false);
  }, [setIsAudioPlay]);

  /**
   * 再生済の音声ファイルURL解放
   */
  const removeEndedUrl = useCallback(() => {
    if (audioRef.current.src) {
      removeWavFile(audioRef.current.src);
    }
  }, []);

  /**
   * 音声破棄
   */
  const releaseAudioSource = useCallback(() => {
    removeEndedUrl();
    audioRef.current.src = '';
    audioRef.current.load();
  }, [removeEndedUrl]);

  /**
   * 音声の出力先変更
   */
  const changePlayDevice = useCallback((outputDevice: string) => {
    if (typeof (audioRef.current as any)?.setSinkId === 'undefined') {
      // モバイル端末などsetSinkIdが使えない環境では何もしない。
      return;
    }

    (audioRef.current as any)?.setSinkId(outputDevice);
  }, []);

  /**
   * 再生終了を検知
   */
  useEffect(() => {
    let audioRefValue: HTMLAudioElement | null = null; // Cleanup ref issues in React警告が表示されるので対応
    const onEnded = () => {
      setIsAudioPlay(false);
      removeEndedUrl();
    };

    if (audioRef.current) {
      audioRef.current.addEventListener('ended', onEnded);
      audioRefValue = audioRef.current;
    }

    return () => {
      if (audioRefValue) {
        audioRefValue.removeEventListener('ended', onEnded);
      }
    };
  }, [removeEndedUrl, setIsAudioPlay]);

  /**
   * マウント/アンマウント時に実行する処理
   */
  useEffect(
    () =>
      // unmount時、
      () => {
        // 音声停止
        pause();
        // 音声破棄
        releaseAudioSource();
        // Reduxに保存したAudio情報を初期化
        resetState();
      },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    play, // 再生
    pause, // 停止
    releaseAudioSource, // 音声破棄
    changePlayDevice, // 音声の出力先変更
  };
};
