import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * State
 */
export type BrowserUserInfoState = {
  // シリアルのモード
  mode: string;
};

/**
 * 初期State
 */
const initialState: BrowserUserInfoState = {
  mode: '',
};

/**
 * ログインユーザ情報 Slice
 */
export const browserUserInfoSlice = createSlice({
  name: 'browserUserInfo',
  initialState,
  reducers: {
    // シリアルのモードを更新
    setMode: (state, action: PayloadAction<string>) => {
      state.mode = action.payload;
    },
    // リセット
    clearUserInfo: () => initialState,
  },
});
