/**
 * マイク利用時の設定
 */
export const USER_MEDIA_CONSTRAINTS = {
  // チャンネル数の初期値
  DEFAULT_CHANNELS: 1,
  // サンプリングレートの初期値
  DEFAULT_SAMPLE_RATE: 16000,
  // ビット深度の初期値
  DEFAULT_BIT_DEPTH: 16,
} as const;
