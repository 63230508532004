import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { HISTORY_LIST_HEADER } from '@/constants/history';
import {
  YYYYMMDD_HHMMSS_FORMAT,
  convertDateUTCToString,
  currentDateUTC,
} from '@/utils/date';

/**
 * CSVダウンロード共通処理 カスタムフック
 *
 * @returns
 */
export const useCSVDownload = () => {
  const { t } = useTranslation();

  /**
   * ヘッダー部分のCSVデータ生成
   */
  const headerCSVData = useMemo(() => {
    let header = '';
    HISTORY_LIST_HEADER.forEach((title: string, index: number) => {
      header += t(`translation.${title}`);
      if (index < HISTORY_LIST_HEADER.length - 1) {
        header += ',';
      }
    });
    header += '\n';

    return header;
  }, [t]);

  /**
   * CSVファイル(BOM付UTF-8)のダウンロード処理
   *
   * @param bodyCSVData CSVのBodyデータ
   */
  const downloadCSVDataWithBom = useCallback(
    (bodyCSVData: string): void => {
      // csvファイル作成
      const fileData = headerCSVData + bodyCSVData;
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]); // BOM付UTF-8を指定(エクセルがBOMが無いと文字コードを判別できない)
      const blob = new Blob([bom, fileData], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      const fileName = convertDateUTCToString(
        currentDateUTC(),
        YYYYMMDD_HHMMSS_FORMAT,
      );
      link.download = `${fileName}.csv`;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    [headerCSVData],
  );

  /**
   * 指定された配列の中身を取り出してカンマ区切りに変換
   *
   * ※CSVとして出力されるように以下の変換を行う
   * ・値をダブルクォートで囲む
   * ・値中の「"」を出力するためにダブルクォートを二重化（"→""）
   *
   * @param dataArray
   * @returns
   */
  const convertDataToCommaSeparateStr = useCallback(
    (dataArray: string[][]): string => {
      const textData = dataArray
        .map((row) =>
          row.map((cell) => `"${cell.replace(/"/g, '""')}"`).join(','),
        )
        .join('\n');

      return textData;
    },
    [],
  );

  return {
    // 指定された配列の中身を取り出してカンマ区切りに変換
    convertDataToCommaSeparateStr,
    // CSVファイル(BOM付UTF-8)のダウンロード処理
    downloadCSVDataWithBom,
  };
};
