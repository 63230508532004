import React from 'react';
import { useTranslation } from 'react-i18next';

import description from '@/assets/description.svg';

/**
 * プロパティ
 */
type LicenseInfoContainer = {
  // 設定ダイアログを閉じる
  onCloseDialog: () => void;
  // ライセンス情報ダイアログを開く
  onOpenLicenseDialog: () => void;
};

/**
 * ヘッダー > メインメニュー > 一般メニュー > ライセンス情報メニュー
 */
export const LicenseInfoContainer = React.memo(
  ({ onCloseDialog, onOpenLicenseDialog }: LicenseInfoContainer) => {
    const { t } = useTranslation();

    return (
      <button
        type="button"
        onClick={() => {
          onCloseDialog();
          onOpenLicenseDialog();
        }}
      >
        <img src={description} alt="description" />
        {t('settingMenu.ライセンス情報')}
      </button>
    );
  },
);
