import { PullDownMenuItem } from '@/components/Elements';
import { LangData } from '@/constants/language';

/**
 * 指定された言語一覧をプルダウンメニュー形式に変換
 */
export const convertPullDownMenuItem = (
  targetLangDataArray: LangData[],
): PullDownMenuItem[] => {
  const menuItemArray: PullDownMenuItem[] = [];
  if (!targetLangDataArray) {
    return menuItemArray;
  }
  targetLangDataArray.forEach((element: LangData) => {
    Object.entries(element).forEach(([key, value]) => {
      menuItemArray.push({ label: value.label, value: key });
    });
  });

  return menuItemArray;
};

/**
 * ブラウザ言語の型
 */
export type BrowserLangData = {
  // ブラウザ言語
  browserLang: string;
  // ブラウザ言語の前方のみの値('en-US'などの表記になっている場合があるので'-'前の文字列)
  preBrowserLang: string;
};

/**
 * ブラウザ言語を取得
 *
 * @returns
 */
export const findBrowserLang = (): BrowserLangData => {
  // ブラウザ言語を取得
  const browserLang = navigator.language;
  if (!browserLang) {
    return {
      browserLang,
      preBrowserLang: '',
    };
  }

  // 'en-US'などの表記になっている場合があるので'-'前の文字列を取得
  const preBrowserLang = browserLang.slice(0, browserLang.indexOf('-'));

  return {
    browserLang,
    preBrowserLang,
  };
};
