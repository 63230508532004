import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogMessageContents, ModalDialog } from '@/components/Elements';

/**
 * ゲスト画面ようこそダイアログ プロパティ
 */
type Props = {
  // ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // ダイアログを閉じる
  onClose: () => void;
};

/**
 * ゲスト画面ようこそダイアログ
 */
export const WelcomeGuestDialog = React.memo(({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <ModalDialog
      isOpen={isOpen}
      title={t('welcomeGuestDialog.ポケトークライブ通訳へようこそ').toString()}
      buttons={[{ label: 'OK', onClick: onClose }]}
    >
      <DialogMessageContents> </DialogMessageContents>
    </ModalDialog>
  );
});
