// // Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */

import styles from './Button.module.scss';

/**
 * プロパティ
 */
export type Button = {
  // 型(必須)
  type: 'button' | 'submit';
  // ボタン名(必須)
  title: string;
  // 無効化(default=false)
  disabled?: boolean;
  // 画像
  image?: string;
  // クリック時の処理
  onClick?: () => void;
  // GTM用クラスタグ
  gtmClassTag?: string;
};

/**
 * ボタン コンポーネント
 *
 * @param param0
 * @returns
 */
export const Button = ({
  type = 'button',
  title,
  disabled = false,
  image,
  onClick,
  gtmClassTag = '',
}: Button) => (
  <button
    type={type}
    disabled={disabled}
    onClick={onClick}
    className={`${styles.button} ${gtmClassTag}`}
    title={title}
  >
    {image && (
      <div>
        <img src={image} alt="icon" />
      </div>
    )}
    <div>{title}</div>
  </button>
);
