import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  API_STATUS,
  ApiStatus,
  COOKIE_KEY_NAME,
  COOKIE_USER_TYPE,
  HISTORY_SAVE_LIMIT_DATE,
  PAGE_ROUTE_PATH,
  TRANS_MODE,
} from '@/constants';
import { isHomeStateType } from '@/constants/home';
import { TOKEN_AUTH_API_RESULT_CODE, useTokenAuthApi } from '@/features/api';
import { useNeedAgreement, usePtidExpired } from '@/features/expired';
import { useUrlParameterLang } from '@/features/langupdate';
import { useLogoutExpiredSerial } from '@/features/logout';
import { useBrowserHistory } from '@/hooks/useBrowserHistory';
import { findCookieValue } from '@/utils/cookie';

/**
 * 本カスタムフックからの返却値
 */
export type UseHomeValue = {
  // トークンチェックAPI呼び出し状況
  tokenAuthStatus: ApiStatus;
  // [マイク翻訳]ボタンがクリックされた場合の処理
  clickMicButton: () => void;
  // [スピーカー翻訳]ボタンがクリックされた場合の処理
  clickSpeakerButton: () => void;
  // ライセンス期限チェック&ログアウト処理状況
  expiredSerialLogoutState: ApiStatus;
};

/**
 * ホーム画面（モード選択画面） hooks
 */
export const useHome = (): UseHomeValue => {
  const { tokenAuthState, fetchTokenAuth } = useTokenAuthApi();
  const navigate = useNavigate();
  const { ptidExpired } = usePtidExpired();
  const { saveURLParamToLocalStorage } = useUrlParameterLang();
  const { needAgreement } = useNeedAgreement();
  const { deleteHistoryOverLimit } = useBrowserHistory();
  const { logoutIfExpiredSerial, expiredSerialLogoutState } =
    useLogoutExpiredSerial();
  const { state } = useLocation();

  /**
   * [マイク翻訳]ボタンがクリックされた場合の処理
   */
  const clickMicButton = useCallback(() => {
    navigate(PAGE_ROUTE_PATH.TRANSLATION.MIC, { state: TRANS_MODE.MIC });
  }, [navigate]);

  /**
   * [スピーカー翻訳]ボタンがクリックされた場合の処理
   */
  const clickSpeakerButton = useCallback(() => {
    navigate(PAGE_ROUTE_PATH.TRANSLATION.SPEAKER, {
      state: TRANS_MODE.SPEAKER,
    });
  }, [navigate]);

  /**
   * シリアルで利用中の場合のみ、シリアルの期限をチェックして期限切れだった場合に以下の処理実行
   * ・自動ログアウト
   * ・コード付きでシリアル画面に遷移
   */
  const navigateSerialViewAfterLogoutIfSerialExpired = useCallback(
    (serialCode: string) => {
      if (
        findCookieValue(COOKIE_KEY_NAME.PTBR_TYPE) !== COOKIE_USER_TYPE.SERIAL
      ) {
        return;
      }

      logoutIfExpiredSerial(serialCode);
    },
    [logoutIfExpiredSerial],
  );

  /**
   * 状態：画面表示時
   */
  useEffect(() => {
    // URLパラメータに指定されたUI言語をローカルストレージに保存
    saveURLParamToLocalStorage();

    // トークンの期限チェック
    fetchTokenAuth();

    // ローカルストレージから保存期間が過ぎた履歴を削除
    deleteHistoryOverLimit(HISTORY_SAVE_LIMIT_DATE);

    // コンポーネントのマウント時に一度だけ実行
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * トークンチェックAPIからの返却値を監視
   */
  useEffect(() => {
    if (
      tokenAuthState.status === API_STATUS.FAILED &&
      tokenAuthState.data?.resultCode ===
        TOKEN_AUTH_API_RESULT_CODE.INFO_EXPIRED_IDTOKEN
    ) {
      // PTID期限切れ処理実行
      ptidExpired();

      return;
    }

    // INFO_NEED_AGREEMENTの場合、利用規約画面に遷移
    if (
      tokenAuthState.data?.resultCode ===
      TOKEN_AUTH_API_RESULT_CODE.INFO_NEED_AGREEMENT
    ) {
      needAgreement();
    }
  }, [needAgreement, ptidExpired, tokenAuthState]);

  /**
   * トークンが有効な場合の処理
   */
  useEffect(() => {
    if (tokenAuthState.status !== API_STATUS.SUCCESS) {
      return;
    }
    if (tokenAuthState.data?.resultCode !== TOKEN_AUTH_API_RESULT_CODE.OK) {
      return;
    }
    // stateにシリアル番号が指定された場合のみ、シリアルの期限チェックを行う
    if (isHomeStateType(state) && state.serialCode) {
      navigateSerialViewAfterLogoutIfSerialExpired(state.serialCode);
    }
  }, [
    navigateSerialViewAfterLogoutIfSerialExpired,
    state,
    tokenAuthState.data?.resultCode,
    tokenAuthState.status,
  ]);

  return {
    tokenAuthStatus: tokenAuthState.status,
    clickMicButton,
    clickSpeakerButton,
    expiredSerialLogoutState: expiredSerialLogoutState.status,
  };
};
