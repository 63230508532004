import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ModalDialog, ToggleButton } from '@/components/Elements';
import { useHistoryDownload } from '@/features/history';
import { useBrowserHistory } from '@/hooks/useBrowserHistory';

import styles from './HistoryDownloadDialog.module.scss';

/**
 * 通訳履歴ダウンロードダイアログ プロパティ
 */
type HistoryDialog = {
  // ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // ダイアログを閉じる
  onClose: () => void;
};

/**
 * 通訳履歴ダイアログ
 */
export const HistoryDialog = React.memo(
  ({ isOpen, onClose }: HistoryDialog) => {
    const { onClickDownload, onClickToggleButton } = useHistoryDownload();
    const { isHistory } = useBrowserHistory();
    const { t } = useTranslation();

    return (
      <ModalDialog
        isOpen={isOpen}
        isHiddenCloseButton={false}
        onClickClose={onClose}
        title={t('history.通訳履歴').toString()}
      >
        <div className={styles['history-container']}>
          <div className={styles['leave-history']}>
            {t('history.通訳履歴を残す')}
            <ToggleButton
              onChange={() => {
                onClickToggleButton();
              }}
              checked={isHistory}
            />
          </div>
          <Button
            type="button"
            title={t('history.通訳履歴をダウンロード')}
            onClick={() => {
              onClickDownload();
            }}
            disabled={!isHistory}
          />
        </div>
      </ModalDialog>
    );
  },
);
