import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { PAGE_ROUTE_PATH } from '@/constants';
import { useCommonErrorDialog } from '@/hooks/useCommonErrorDialog';

/**
 * PTID期限切れ カスタムフック
 */
export const usePtidExpired = () => {
  const navigate = useNavigate();
  const { resetState } = useCommonErrorDialog();

  /**
   * PTID期限切れ
   */
  const ptidExpired = useCallback(() => {
    // ようこそ画面に遷移。このとき表示中だった画面パスは指定不要(再ログイン後、一律ホーム画面を表示)
    navigate({
      pathname: PAGE_ROUTE_PATH.WELCOME,
    });
    // エラーダイアログの情報をリセット
    resetState();
  }, [navigate, resetState]);

  return {
    ptidExpired,
  };
};
