import { useCallback } from 'react';

import { PAGE_ROUTE_PATH, isSerialStateType } from '@/constants';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';

/**
 * 翻訳画面パス hooks
 *
 * @returns
 */
export const useTranslationPath = () => {
  const { isSharingDisplay } = useTranslationInfo();

  /**
   * 現在表示中の画面が翻訳画面(マイク翻訳モード/スピーカー翻訳モード)か否か
   *
   * @param currentPath 現在表示中の画面パス
   * @returns true=翻訳画面(マイク翻訳モード/スピーカー翻訳モード)
   */
  const isTranslationPath = useCallback(
    (currentPath: string) => {
      if (currentPath === PAGE_ROUTE_PATH.TRANSLATION.MIC) {
        return true;
      }
      if (
        currentPath === PAGE_ROUTE_PATH.TRANSLATION.SPEAKER &&
        isSharingDisplay
      ) {
        return true;
      }

      return false;
    },
    [isSharingDisplay],
  );

  /**
   * ヘッダーに戻るボタンを表示するかどうか
   *
   * 現在表示中の画面が翻訳画面または通訳開始画面か否か
   * また利用方法選択画面からシリアル画面を表示した場合も表示
   *
   * @param currentPath 現在表示中の画面パス
   * @param state state
   * @returns true=表示する
   */
  const isDisplayBack = useCallback((currentPath: string, state: string) => {
    if (currentPath === PAGE_ROUTE_PATH.TRANSLATION.MIC) {
      return true;
    }
    if (currentPath === PAGE_ROUTE_PATH.TRANSLATION.SPEAKER) {
      return true;
    }
    if (currentPath === PAGE_ROUTE_PATH.SERIAL && isSerialStateType(state)) {
      return true;
    }

    return false;
  }, []);

  /**
   * 現在表示中の画面が翻訳画面(マイク翻訳モード)か否か
   *
   * @param currentPath 現在表示中の画面パス
   * @returns true=翻訳画面(マイク翻訳モード)
   */
  const isTranslationMic = useCallback((currentPath: string) => {
    if (currentPath === PAGE_ROUTE_PATH.TRANSLATION.MIC) {
      return true;
    }

    return false;
  }, []);

  return {
    isTranslationPath,
    isDisplayBack,
    isTranslationMic,
  };
};
