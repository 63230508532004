import { addMinutes } from 'date-fns';

export * from './utcDate';

/**
 * 現在時刻を「UNIX時間ミリ秒なし（秒）」形式で取得
 */
export const currentUnixTime = () => Math.floor(new Date().getTime() / 1000);

/**
 * 現在時刻を「UNIX時間ミリ秒なし（ミリ秒）」形式で取得
 */
export const currentUnixMillisecondsTime = () => new Date().getTime();

/**
 * 指定されたミリ秒を「分」に変換(端数は切り上げ)
 *
 * @param millisTime
 * @returns
 */
export const convertMillisToMinutes = (millisTime: number) =>
  Math.ceil(millisTime / 1000 / 60) % 60;

/**
 * 現在日時に指定された分を加算した日時を取得
 *
 * @param minutes
 * @returns
 */
export const addMinutesToCurrentDate = (minutes: number) => {
  const currentDate = new Date();

  return addMinutes(currentDate, minutes);
};
