/**
 * envファイルに設定された「REACT_APP_SERIAL_CAMPAIGN」を見て、
 * 特定のシリアルで認証された人向けのキャンペーンが有効かどうかを返す
 *
 * @returns true=有効|false=無効
 */
export const isSerialCampaignActive = (): boolean => {
  if (process.env.REACT_APP_SERIAL_CAMPAIGN === 'true') {
    return true;
  }

  return false;
};

/**
 * envファイルに設定された「REACT_APP_SHARE_GUEST_CAMPAIGN」を見て、
 * ゲスト向けのキャンペーンが有効かどうかを返す
 *
 * @returns true=有効|false=無効
 */
export const isGuestCampaignActive = (): boolean => {
  if (process.env.REACT_APP_SHARE_GUEST_CAMPAIGN === 'true') {
    return true;
  }

  return false;
};
