import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { PAGE_ROUTE_PATH } from '@/constants';
import { useClearLocalStorage } from '@/hooks/useClearLocalStorage';
import { useCommonErrorDialog } from '@/hooks/useCommonErrorDialog';

/**
 * アクセス期限切れ カスタムフック
 *
 * @returns
 */
export const useAccessExpired = () => {
  const { doCleanLocalStorage } = useClearLocalStorage();
  const navigate = useNavigate();
  const { resetState } = useCommonErrorDialog();

  /**
   * アクセス期限切れ
   */
  const accessExpired = useCallback(() => {
    // ローカルストレージの情報を全て削除
    doCleanLocalStorage();
    // 利用方法選択画面に遷移(同タブ内)
    navigate(PAGE_ROUTE_PATH.SELECT_USAGE);
  }, [doCleanLocalStorage, navigate]);

  /**
   * アクセス期限切れダイアログ用OKボタンのクリック処理
   */
  const clickAccessExpiredOKButton = useCallback(() => {
    // アクセス期限処理実行
    accessExpired();
    // エラーダイアログの情報をリセット
    resetState();
  }, [accessExpired, resetState]);

  return {
    clickAccessExpiredOKButton,
  };
};
