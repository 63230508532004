import { useMemo } from 'react';

import {
  LOCALE_LANG,
  MANUAL_LINK_URL_EN,
  MANUAL_LINK_URL_JA,
} from '@/constants';
import { useBrowserUserSetting } from '@/hooks/useBrowserUserSetting';

/**
 * ヘッダー > メインメニュー > マニュアルメニュー用 hooks
 *
 * @returns
 */
export const useManualContainer = () => {
  const { language } = useBrowserUserSetting();

  /**
   * マニュアルリンク
   * UI言語ごとに変える
   */
  const manualLink = useMemo(() => {
    switch (language) {
      case LOCALE_LANG.JA:
        return MANUAL_LINK_URL_JA;
      default:
        return MANUAL_LINK_URL_EN;
    }
  }, [language]);

  return {
    manualLink,
  };
};
