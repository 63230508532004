import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { TOAST_ICON_TYPE } from '@/components/Elements';
import { useToastInfo } from '@/hooks/useToastInfo';
import { copyToClipboard } from '@/utils/copy';

/**
 * 共有ダイアログ用 hooks
 *
 * @returns
 */
export const useShareCommonDialog = () => {
  const { addToastMessage } = useToastInfo();
  const { t } = useTranslation();
  /**
   * URLコピーボタン押下時の処理
   */
  const clickUrlCopyButton = useCallback(
    (url: string) => {
      copyToClipboard(url).then(() => {
        // 成功のトーストメッセージ表示
        addToastMessage(
          t('shareInfoDialog.コピーしました'),
          TOAST_ICON_TYPE.INFO,
        );
      });
    },
    [addToastMessage, t],
  );

  return {
    clickUrlCopyButton,
  };
};
