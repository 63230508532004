import React from 'react';
import { useTranslation } from 'react-i18next';

import { ShareCommonDialog } from '../../share/components/ShareCommonDialog';

/**
 * プロパティ
 */
type Props = {
  // 共有ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // 共有ダイアログを閉じる
  onClose: () => void;
};

/**
 * ゲスト画面用 共有ダイアログ コンポーネント
 */
export const GuestShareDialog = React.memo(({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <ShareCommonDialog
      isOpen={isOpen}
      onClose={onClose}
      isHiddenCloseButton={false}
      title={t('shareInfoDialog.共有')}
      description={t(
        'shareInfoDialog.このコードで通訳画面を表示できます',
      ).toString()}
      url={window.location.href} // 表示中の画面URLを表示
      isUrlCopyButton
    />
  );
});
