import React from 'react';
import { useTranslation } from 'react-i18next';

import { ALIGN_TYPE, Button, TextBlock } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';

import { useAuthError } from '../hooks/useAuthError';

import styles from './AuthError.module.scss';

/**
 * 認証エラー画面 子コンポーネント
 *
 * @returns
 */
const AuthErrorView = React.memo(() => {
  const { clickLoginAgainButton } = useAuthError();
  const { t } = useTranslation();

  return (
    <ContentLayout>
      <div className={styles['auth-error']}>
        <TextBlock
          text={t('authError.ログイン認証に失敗しました')}
          align={ALIGN_TYPE.center}
        />
        <div className={styles['auth-error-contents']}>
          <TextBlock
            text={t(
              'authError.処理を継続できません。再ログインして操作をやり直してください。',
            )}
          />
          <Button
            title={t('authError.再ログインする').toString()}
            type="button"
            onClick={clickLoginAgainButton}
          />
        </div>
      </div>
    </ContentLayout>
  );
});

/**
 * 認証エラー画面
 */
export const AuthError = React.memo(() => <AuthErrorView />);
