import React from 'react';

import useOpen from '@/hooks/useOpen';

import { ShareOverLimitDialog } from './error/ShareOverLimitDialog';

/**
 * プロパティ
 */
type Props = {
  // ゲスト画面コンポーネント
  guestViewBasicComponent: JSX.Element;
};

/**
 * 共有人数上限ビュー
 */
export const TotalGuestOverView = React.memo(
  ({ guestViewBasicComponent }: Props) => {
    const { isOpen, onClose } = useOpen(true);

    return (
      <div>
        {/* 共有超過エラーダイアログを表示 */}
        <ShareOverLimitDialog isOpen={isOpen} onClickOK={onClose} />

        {guestViewBasicComponent}
      </div>
    );
  },
);
