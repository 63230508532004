/**
 * 言語データ
 */
export type LangData = {
  // 言語ID:表示名のキー-バリュー ペア
  // 言語が双方向翻訳に対応しているかも含む
  [langCode: string]: { label: string; interactive: boolean };
};

/**
 * 自動言語判別モード
 */
export const AUTO_DETECT_MODE_TYPE = {
  // 双方向モード
  INTERACTIVE: 'interactive',
  // 一方向モード
  NON_INTERACTIVE: 'noninteractive',
};
export type AutoDetectModeType =
  (typeof AUTO_DETECT_MODE_TYPE)[keyof typeof AUTO_DETECT_MODE_TYPE];
