import { Reducer, useCallback, useReducer } from 'react';

import { ApiStatus, API_STATUS } from '@/constants';

import {
  logoutApi,
  LogoutApiResponse,
  isLogoutApiResultCode,
  LOGOUT_API_RESULT_CODE,
} from '../logoutApi';

/**
 * State
 */
export type LogoutState = {
  // レスポンスデータ
  data?: LogoutApiResponse;
  // APIの呼び出し状態
  status: ApiStatus;
};

/**
 * Actionタイプ
 */
const LOGOUT_ACTION_TYPE = {
  SET_LOGOUT: 'SET_LOGOUT',
  SET_LOGOUT_SUCCESS: 'SET_LOGOUT_SUCCESS',
  SET_LOGOUT_FAILED: 'SET_LOGOUT_FAILED',
  SET_LOGOUT_IDLE: 'SET_LOGOUT_IDLE',
} as const;

/**
 * Action
 */
type LogoutAction = {
  data?: LogoutApiResponse;
  type: keyof typeof LOGOUT_ACTION_TYPE;
};

/**
 * reducer関数
 *
 * @param state
 * @param action
 * @returns
 */
const reducer: Reducer<LogoutState, LogoutAction> = (
  state: LogoutState,
  action: LogoutAction,
) => {
  switch (action.type) {
    case LOGOUT_ACTION_TYPE.SET_LOGOUT:
      return {
        ...state,
        status: API_STATUS.LOADING,
      };

    case LOGOUT_ACTION_TYPE.SET_LOGOUT_SUCCESS:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.SUCCESS,
      };

    case LOGOUT_ACTION_TYPE.SET_LOGOUT_FAILED:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.FAILED,
      };

    case LOGOUT_ACTION_TYPE.SET_LOGOUT_IDLE:
      return {
        ...state,
        status: API_STATUS.IDLE,
      };
    default:
      return state;
  }
};

/**
 * ログアウトAPI 呼び出しhooks
 *
 * @returns
 */
export const useLogoutApi = () => {
  const [state, dispatch] = useReducer(reducer, {
    data: undefined,
    status: API_STATUS.IDLE,
  });

  /**
   * API呼び出し
   */
  const fetchLogout = useCallback(() => {
    if (state.status === API_STATUS.LOADING) {
      return;
    }

    dispatch({ type: LOGOUT_ACTION_TYPE.SET_LOGOUT });

    logoutApi()
      .then((response: LogoutApiResponse) => {
        // 成功
        if (response.resultCode === LOGOUT_API_RESULT_CODE.OK) {
          dispatch({
            type: LOGOUT_ACTION_TYPE.SET_LOGOUT_SUCCESS,
            data: response,
          });

          return;
        }

        // 意図しない結果コードの型だった場合は失敗とする
        if (!isLogoutApiResultCode(response.resultCode)) {
          dispatch({
            type: LOGOUT_ACTION_TYPE.SET_LOGOUT_FAILED,
            data: {
              ...response,
              resultCode: LOGOUT_API_RESULT_CODE.ERR_UNKNOWN,
            } as LogoutApiResponse,
          });

          return;
        }

        // 失敗
        dispatch({
          type: LOGOUT_ACTION_TYPE.SET_LOGOUT_FAILED,
          data: response,
        });
      })
      .catch((_) => {
        dispatch({
          type: LOGOUT_ACTION_TYPE.SET_LOGOUT_FAILED,
          data: {
            resultCode: LOGOUT_API_RESULT_CODE.ERR_UNKNOWN,
          } as LogoutApiResponse,
        });
      });
  }, [state.status]);

  /**
   * APIのステータスをリセットする
   */
  const resetLogoutState = useCallback(() => {
    dispatch({ type: LOGOUT_ACTION_TYPE.SET_LOGOUT_IDLE });
  }, []);

  return {
    logoutState: state,
    fetchLogout,
    resetLogoutState,
  };
};
