import React from 'react';

import { PullDownMenuItem } from './PullDownMenu';
import styles from './PullDownMenu.module.scss';

// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */
/**
 * メニューの型
 */
export type OptGroupPullDownMenuItem = {
  optgroup: { label: string };
  items: PullDownMenuItem[];
};

/**
 * プロパティ
 */
export type OptGroupPullDownMenu = {
  // name属性
  name: string;
  // メニューの選択リスト
  optionItems: OptGroupPullDownMenuItem[];
  // 選択されたメニュー値
  selectedValue: string;
  // 無効化(default=false)
  disabled?: boolean;
  // 変更時の処理(必須)
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  // 要素(参照、操作したい場合に指定)
  selectRef?: React.RefObject<HTMLSelectElement>;
  // GTM用クラスタグ
  gtmClassTag?: string;
};

/**
 * グループ付きプルダウンメニュー コンポーネント
 * @param param0
 * @returns
 */
export const OptGroupPullDownMenu = ({
  name,
  optionItems,
  selectedValue,
  disabled = false,
  onChange,
  selectRef,
  gtmClassTag = '',
}: OptGroupPullDownMenu) => {
  const options = optionItems.map((i: OptGroupPullDownMenuItem) => (
    <optgroup label={i.optgroup?.label} key={i.optgroup?.label}>
      {i.items.map((j: PullDownMenuItem) => (
        <option
          className={styles.option}
          key={j.value} // keyを指定しないと警告がでるのでプロパティに指定されたvalueを設定
          value={j.value}
        >
          {j.label}
        </option>
      ))}
    </optgroup>
  ));

  return (
    <select
      className={`${styles.select} ${gtmClassTag}`}
      data-testid="opt-group-select"
      name={name}
      value={selectedValue}
      disabled={disabled}
      onChange={(e) => onChange(e)}
      ref={selectRef}
      role="button"
      tabIndex={-1}
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
    >
      {options}
    </select>
  );
};
