import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { PAGE_ROUTE_PATH, URL_PARAMS_KEY } from '@/constants';
import { usePTPayPlm0100 } from '@/features/api';

/**
 * プロパティ
 */
type Props = {
  // 購入時に実行したい処理(未指定可)
  onPurchaseFunction?: () => void;
};

/**
 * 無料期間終了 カスタムフック
 *
 * @param onPurchaseFunction 購入時に実行したい処理(未指定可)
 * @returns
 */
export const useFreeExpired = ({ onPurchaseFunction }: Props) => {
  const navigate = useNavigate();
  const { openProductSelect } = usePTPayPlm0100();

  /**
   * 無料期間終了ダイアログ用閉じるボタンのクリック処理
   */
  const clickFreeExpiredCloseButton = useCallback(() => {
    // ようこそ画面に遷移。このときホーム画面のパスを指定
    navigate({
      pathname: PAGE_ROUTE_PATH.WELCOME,
      search: encodeURI(
        `${URL_PARAMS_KEY.WELCOME.path}=${PAGE_ROUTE_PATH.HOME}`,
      ),
    });
  }, [navigate]);

  /**
   * 無料期間終了ダイアログ用購入ボタンのクリック処理
   */
  const clickFreeExpiredPurchaseButton = useCallback(() => {
    // PTPayサーバの商品選択画面を表示(別タブ)
    openProductSelect();
    // プロパティに指定された関数実行
    if (onPurchaseFunction) {
      onPurchaseFunction();
    }
  }, [onPurchaseFunction, openProductSelect]);

  return {
    clickFreeExpiredCloseButton,
    clickFreeExpiredPurchaseButton,
  };
};
