// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DialogMessageContents, ModalDialog } from '@/components/Elements';

import { useFreeExpired } from '../hooks/useFreeExpired';

/**
 * プロパティ
 */
type FreeExpiredDialog = {
  // ダイアログ表示/非表示
  isOpen: boolean;
  // 購入時に実行したい処理(未指定可)
  onPurchaseFunction?: () => void;
};

/**
 * 無料期間終了ダイアログ コンポーネント
 *
 * @param isOpen 本ダイアログ表示/非表示
 * @param onPurchaseFunction 購入時に実行したい処理(未指定可)
 * @returns
 */
export const FreeExpiredDialog = React.memo(
  ({ isOpen, onPurchaseFunction }: FreeExpiredDialog) => {
    const { clickFreeExpiredCloseButton, clickFreeExpiredPurchaseButton } =
      useFreeExpired({
        onPurchaseFunction,
      });
    const { t } = useTranslation();

    return (
      <ModalDialog
        isOpen={isOpen}
        title={t('trialPlanDialog.お試し期間が終了しました').toString()}
        buttons={[
          {
            label: t('trialPlanDialog.閉じる'),
            onClick: clickFreeExpiredCloseButton,
            gtmClassTag: 'dts-trialend-close',
          },
          {
            label: t('trialPlanDialog.購入'),
            onClick: clickFreeExpiredPurchaseButton,
            gtmClassTag: 'dts-trialend-purchase',
          },
        ]}
      >
        <DialogMessageContents>
          {t('trialPlanDialog.(月30分までのご利用となります。)')}
          <br />
          <br />
          {t(
            'trialPlanDialog.ライセンスを購入するか、コードを入力すると、引き続き無制限でご利用いただけます。',
          )}
        </DialogMessageContents>
      </ModalDialog>
    );
  },
);
