import { BROWSER } from '@/constants';
import { browserAxios } from '@/lib/axios';

import { API_AUTH_RESULT_CODE } from './apiAuth';

/**
 * 結果コード定数
 */
export const LICENSE_INFO_API_RESULT_CODE = {
  ...({
    // 成功
    OK: 'OK',
    // ライセンス登録されていない
    INFO_INVALID_LICENSE: 'INFO_INVALID_LICENSE',
    // ライセンス期限切れ
    INFO_EXPIRED_LICENSE: 'INFO_EXPIRED_LICENSE',
    // 外部API呼び出し失敗
    WARN_FAILED_CALL: 'WARN_FAILED_CALL',
    // 不明エラー
    ERR_UNKNOWN: 'ERR_UNKNOWN',
  } as const),
  ...API_AUTH_RESULT_CODE,
} as const;
export type LicenseInfoResultCode =
  (typeof LICENSE_INFO_API_RESULT_CODE)[keyof typeof LICENSE_INFO_API_RESULT_CODE];

/**
 * 無料ライセンスキー
 * ※利用中のプランが「お試しプラン」の場合にライセンス文字列に設定される
 */
export const FREE_LICENSE_KEY = 'free' as const;

/**
 * お試しプランで利用中にライセンス確認APIからINFO_INVALID_LICENSEが返却された場合に表示する利用可能残時間
 */
export const LICENSE_INVALID_REMAINING_TIME = 1800000;

/**
 * 結果コードのTypeGuard
 *
 * @param resultCode
 * @returns
 */
export const isLicenseInfoApiResultCode = (
  resultCode: string,
): resultCode is LicenseInfoResultCode => {
  if (resultCode in LICENSE_INFO_API_RESULT_CODE) {
    return true;
  }

  return false;
};

/**
 * レスポンス
 */
export type LicenseInfoApiResponse = {
  // 結果コード
  resultCode: LicenseInfoResultCode;
  // ライセンスの有効期限(ISO8601拡張形式(UTC))
  licenseExp: string;
  // ライセンス文字列
  licenseStr: string;
  // 月残時間(ミリ秒)
  remainingTimeMillis: number;
  // シリアル
  serial: string;
};

/**
 * API 呼び出し
 *
 * @returns
 */
const callApi = (): Promise<LicenseInfoApiResponse> => {
  // リクエスト送信(POST)
  const response = browserAxios
    .get<LicenseInfoApiResponse>(BROWSER.API_URL.LICENSE_INFO, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((apiResponse) => apiResponse.data);

  return response;
};

/**
 * ライセンス確認API(同通サーバ) リクエスト
 *
 * @returns
 */
export const licenseInfoApi = (): Promise<LicenseInfoApiResponse> => callApi();

/**
 * ライセンス期限が無期限のときの返却値
 */
export const LICENSE_INFO_API_MAX_DATE = '3000-12-30T14:59:59Z';
