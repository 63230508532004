import { BROWSER } from '@/constants';
import { browserAxios } from '@/lib/axios';

import { API_AUTH_RESULT_CODE } from './apiAuth';

/**
 * 結果コード定数
 */
export const CREATE_VIEW_API_RESULT_CODE = {
  ...({
    // 成功
    OK: 'OK',
    // 共有画面作成済み
    INFO_ALREADY_CREATE: 'INFO_ALREADY_CREATE',
    // 不明エラー
    ERR_UNKNOWN: 'ERR_UNKNOWN',
  } as const),
  ...API_AUTH_RESULT_CODE,
} as const;
export type CreateViewResultCode =
  (typeof CREATE_VIEW_API_RESULT_CODE)[keyof typeof CREATE_VIEW_API_RESULT_CODE];

/**
 * 結果コードのTypeGuard
 *
 * @param resultCode
 * @returns
 */
export const isCreateViewApiResultCode = (
  resultCode: string,
): resultCode is CreateViewResultCode => {
  if (resultCode in CREATE_VIEW_API_RESULT_CODE) {
    return true;
  }

  return false;
};

/**
 * レスポンス
 */
export type CreateViewApiResponse = {
  // 結果コード
  resultCode: CreateViewResultCode;
  // 共有画面のURLキー
  urlKey: string;
  // 共有画面の利用人数上限
  limitGuests: number;
  // 共有画面の有効期限
  limitTime: string;
};

/**
 * API 呼び出し
 *
 * @returns
 */
const callApi = (): Promise<CreateViewApiResponse> => {
  // リクエスト送信
  const response = browserAxios
    .get<CreateViewApiResponse>(BROWSER.API_URL.CREATE_VIEW, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((apiResponse) => apiResponse.data);

  return response;
};

/**
 * 共有画面作成API リクエスト
 *
 * @returns
 */
export const createViewApi = (): Promise<CreateViewApiResponse> => callApi();
