import { useEffect, useRef } from 'react';

/**
 * ひとつ前の値を保持するためのカスタムフック
 *
 * @param param0
 * @returns
 */
export const usePrevious = <T,>(state: T): T => {
  // ひとつ前の値を保持
  const ref = useRef<T>(state);
  // マウントされたかどうかを保持
  const isMountedRef = useRef<boolean>(false);

  useEffect(() => {
    if (!isMountedRef.current) {
      // マウントされた
      isMountedRef.current = true;
    } else {
      ref.current = state;
    }
  }, [state]);

  return ref.current; // レンダリング走った時点での値を返す(変更前の値を返す)。レンダリングが終わったらuseEffect走って現在の値に上書きされる。
};
