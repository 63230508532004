import { useCallback, useEffect, useRef } from 'react';

/**
 * プロパティ
 */
type Props = {
  // 左方向に自動スクロールするか否か(true=自動スクロールする)
  isAutoLeftScroll: boolean;
};

/**
 * 暫定テキストビューの自動スクロール制御用カスタムフック
 *
 * @returns
 */
export const useInterimListScroll = ({ isAutoLeftScroll }: Props) => {
  const interimListRef = useRef<HTMLDivElement>(null);

  /**
   * 暫定テキストのリストビュー スクロール制御
   */
  const interimListScrollView = useCallback(() => {
    if (interimListRef.current === null) {
      return;
    }

    // 左方向に自動スクロール
    const left = interimListRef.current.scrollLeft;
    interimListRef.current.scrollLeft =
      left + interimListRef.current.clientWidth;
  }, []);

  useEffect(() => {
    if (!isAutoLeftScroll) {
      return;
    }

    interimListScrollView();
  });

  return {
    interimListRef,
  };
};
