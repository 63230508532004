import { useNavigate } from 'react-router-dom';

import { PAGE_ROUTE_PATH } from '@/constants';

/**
 * 戻る処理 カスタムフック
 *
 * @returns
 */
export const useBack = () => {
  const navigate = useNavigate();

  /**
   * 戻るボタン押下時処理
   *
   * @param currentPath 現在表示中の画面パス
   * @returns
   */
  const onClickBackButton = (currentPath: string) => {
    if (currentPath === PAGE_ROUTE_PATH.SERIAL) {
      // シリアル入力画面の場合は利用方法選択画面に戻る
      navigate(PAGE_ROUTE_PATH.SELECT_USAGE);

      return;
    }
    // ホーム画面に戻る
    navigate(PAGE_ROUTE_PATH.HOME);
  };

  return {
    onClickBackButton,
  };
};
