import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { TOAST_ICON_TYPE } from '@/components/Elements';
import { API_STATUS } from '@/constants';
import { COMMON_ERROR_DIALOG_CODE } from '@/constants/error';
import {
  DELETE_VIEW_API_RESULT_CODE,
  DeleteViewApiResponse,
  useDeleteViewApi,
} from '@/features/api';
import { useNeedAgreement } from '@/features/expired';
import { useCommonErrorDialog } from '@/hooks/useCommonErrorDialog';
import { useToastInfo } from '@/hooks/useToastInfo';

/**
 * プロパティ
 */
type Props = {
  // 共有終了成功/失敗時に実行したい処理(共有ダイアログから終了を実行された時に共有ダイアログを閉じるために指定されることを想定)
  onShareEndCompletedFun?: () => void;
  // 共有終了確認ダイアログのクローズ処理
  onCloseShareEndConfirmDialog: () => void;
};

/**
 * 共有終了確認ダイアログ hooks
 *
 * @returns
 */
export const useShareEndConfirmDialog = ({
  onShareEndCompletedFun,
  onCloseShareEndConfirmDialog,
}: Props) => {
  const { deleteView, deleteViewState } = useDeleteViewApi();
  const { addToastMessage } = useToastInfo();
  const { needAgreement } = useNeedAgreement();
  const { setCommonErrorDialogType } = useCommonErrorDialog();

  const onShareEndCompletedFunRef = useRef<() => void>();
  useEffect(() => {
    onShareEndCompletedFunRef.current = onShareEndCompletedFun;
  }, [onShareEndCompletedFun]);
  const { t } = useTranslation();

  /**
   * 終了ボタン押下時の処理
   */
  const clickEndButton = useCallback(() => {
    // 共有画面破棄API呼び出し
    deleteView();
  }, [deleteView]);

  /**
   * 共有画面破棄APIの成功時の処理
   */
  const deleteViewSuccess = useCallback(() => {
    // 共有終了時に実行したい処理を実行
    onShareEndCompletedFunRef.current?.();
    // 共有終了確認ダイアログをクローズ
    onCloseShareEndConfirmDialog();
  }, [onCloseShareEndConfirmDialog]);

  /**
   * 共有画面破棄APIの失敗時の処理
   */
  const deleteViewFailed = useCallback(
    (apiResponse: DeleteViewApiResponse | undefined) => {
      try {
        // アクセス期限切れエラー
        if (apiResponse?.resultCode === DELETE_VIEW_API_RESULT_CODE.WARN_AUTH) {
          setCommonErrorDialogType(COMMON_ERROR_DIALOG_CODE.ACCESS_ERROR);

          return;
        }

        // PTID期限切れエラー
        if (
          apiResponse?.resultCode ===
          DELETE_VIEW_API_RESULT_CODE.INFO_EXPIRED_IDTOKEN
        ) {
          setCommonErrorDialogType(COMMON_ERROR_DIALOG_CODE.PTID_ERROR);

          return;
        }

        // INFO_NEED_AGREEMENTの場合、利用規約画面に遷移
        if (
          apiResponse?.resultCode ===
          DELETE_VIEW_API_RESULT_CODE.INFO_NEED_AGREEMENT
        ) {
          needAgreement();

          return;
        }

        // その他エラー
        addToastMessage(
          t('shareEndConfirmDialog.共有終了に失敗しました'),
          TOAST_ICON_TYPE.WARNING,
        );
      } finally {
        // 共有終了時に実行したい処理を実行
        onShareEndCompletedFunRef.current?.();
        // 共有終了確認ダイアログをクローズ
        onCloseShareEndConfirmDialog();
      }
    },
    [
      addToastMessage,
      needAgreement,
      onCloseShareEndConfirmDialog,
      setCommonErrorDialogType,
      t,
    ],
  );

  /**
   * 共有画面破棄APIの呼び出し状況を監視
   */
  useEffect(() => {
    if (
      deleteViewState.status === API_STATUS.IDLE ||
      deleteViewState.status === API_STATUS.LOADING
    ) {
      return;
    }

    // 成功
    if (
      deleteViewState.status === API_STATUS.SUCCESS &&
      deleteViewState.data?.resultCode === DELETE_VIEW_API_RESULT_CODE.OK
    ) {
      deleteViewSuccess();

      return;
    }

    // 失敗
    deleteViewFailed(deleteViewState.data);
  }, [deleteViewFailed, deleteViewState, deleteViewSuccess]);

  return {
    clickEndButton,
    deleteViewState,
  };
};
