import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { audioInfoSlice } from './slices/audioInfoSlice';
import { browserHistorySlice } from './slices/browserHistorySlice';
import { browserLanguageSlice } from './slices/browserLanguageSlice';
import { browserTranslationDisplaySlice } from './slices/browserTranslationDisplaySlice';
import { browserUserInfoSlice } from './slices/browserUserInfoSlice';
import { browserUserSettingSlice } from './slices/browserUserSettingSlice';
import { commonErrorDialogSlice } from './slices/commonErrorDialogSlice';
import { googleTtsVoiceListSlice } from './slices/googleTtsVoiceListSlice';
import { guestDisplaySlice } from './slices/guestDisplaySlice';
import { languageListSlice } from './slices/languageListSlice';
import { shareViewInfoSlice } from './slices/shareViewInfoSlice';
import { toastInfoSlice } from './slices/toastInfoSlice';
import { translationDisplaySlice } from './slices/translationDisplaySlice';
import { translationInfoSlice } from './slices/translationInfoSlice';

const persistConfig = {
  // storeに保存されるkey名
  key: 'root',
  // local storageに保存
  storage,
  // storageに保存するリスト
  whitelist: [
    browserLanguageSlice.name,
    browserUserSettingSlice.name,
    browserHistorySlice.name,
    browserTranslationDisplaySlice.name,
    browserUserInfoSlice.name,
  ],
  // マイグレーション用
  version: 1,
  debug: true,
};

export const rootReducer = combineReducers({
  [browserLanguageSlice.name]: browserLanguageSlice.reducer,
  [translationInfoSlice.name]: translationInfoSlice.reducer,
  [audioInfoSlice.name]: audioInfoSlice.reducer,
  [translationDisplaySlice.name]: translationDisplaySlice.reducer,
  [toastInfoSlice.name]: toastInfoSlice.reducer,
  [browserUserSettingSlice.name]: browserUserSettingSlice.reducer,
  [browserHistorySlice.name]: browserHistorySlice.reducer,
  [browserTranslationDisplaySlice.name]: browserTranslationDisplaySlice.reducer,
  [googleTtsVoiceListSlice.name]: googleTtsVoiceListSlice.reducer,
  [shareViewInfoSlice.name]: shareViewInfoSlice.reducer,
  [commonErrorDialogSlice.name]: commonErrorDialogSlice.reducer,
  [guestDisplaySlice.name]: guestDisplaySlice.reducer,
  [languageListSlice.name]: languageListSlice.reducer,
  [browserUserInfoSlice.name]: browserUserInfoSlice.reducer,
});

// 永続化設定されたReducerとして定義
const persistedReducer = persistReducer(persistConfig, rootReducer);

// https://github.com/rt2zz/redux-persist/issues/988
// こちらを参考に「persist」キーの「serializable」のチェックを無視
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();

export default store;
