import { useCallback } from 'react';

import { URL_PARAMS_KEY } from '@/constants';
import { useBrowserUserSetting } from '@/hooks/useBrowserUserSetting';
import { useUrlParameter } from '@/hooks/useUrlParameter';

/**
 * URLパラメータに指定された利用言語の操作用 カスタムフック
 *
 * @returns
 */
export const useUrlParameterLang = () => {
  const { setLanguage } = useBrowserUserSetting();
  const { findParams } = useUrlParameter();

  /**
   * URLパラメータに指定されたUI言語をローカルストレージに保存
   */
  const saveURLParamToLocalStorage = useCallback(() => {
    // URLパラメータからUI言語を取得
    const uiLang = findParams(URL_PARAMS_KEY.lang);
    // 指定されていた場合はローカルストレージに保存
    if (uiLang) {
      setLanguage(uiLang);
    }
  }, [findParams, setLanguage]);

  return {
    saveURLParamToLocalStorage,
  };
};
