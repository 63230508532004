import { currentUnixMillisecondsTime, currentUnixTime } from '../date';

/**
 * JWT形式の文字列をパースしてペイロードを取得
 *
 * @param token
 * @returns
 */
const parseJwt = (token: string) => {
  const tokenArray = token.split('.');
  if (tokenArray.length < 2) {
    return '';
  }
  const base64Url = tokenArray[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

/**
 * ライセンストークンの期限をチェック
 * ※ライセンストークン=エンジンサーバで利用する通行手形
 *
 * @param targetLicenseToken チェック対象のライセンストークン
 * @returns true=OK/false=NG
 */
export const checkLicenseTokenExp = (targetLicenseToken: string): boolean => {
  if (!targetLicenseToken) {
    return false;
  }

  // 期限チェック
  const payload = parseJwt(targetLicenseToken);
  if (!payload) {
    return false;
  }
  const { exp } = payload;

  if (exp < currentUnixTime()) {
    return false;
  }

  return true;
};

/**
 * お試しプランの残利用時間を計算
 *
 * @param initRemainTimeMillis 現在のお試しプランの残時間
 * @param establishedTimeMillis Websocketへの接続が確立した時間
 * @returns
 */
export const calcRemainTimeMillis = (
  initRemainTimeMillis: number,
  establishedTimeMillis: number,
): number => {
  // 経過時間
  const elapsedTimeMillis =
    currentUnixMillisecondsTime() - establishedTimeMillis;
  // 残時間※計算結果
  const result = initRemainTimeMillis - elapsedTimeMillis;

  if (result <= 0) {
    return 0;
  }

  return result;
};
