import React from 'react';

import { PullDownMenu } from '@/components/Elements';
import { UILanguageList } from '@/constants';
import { useGuestDisplay } from '@/hooks/useGuestDisplay';

import { useGuestUILanguage } from '../../hooks/useGuestUILanguage';

/**
 * ゲスト画面 UI言語ドロップダウンリスト コンポーネント
 */
export const GuestUILanguageContainer = React.memo(() => {
  const { uiLang } = useGuestDisplay();
  const { onChangeUILang } = useGuestUILanguage();

  return (
    <PullDownMenu
      optionItems={UILanguageList}
      name="guestUiLanguageSelect"
      selectedValue={uiLang}
      onChange={onChangeUILang}
    />
  );
});
