// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */

import styles from './ToggleButton.module.scss';

type Props = {
  // 無効化(default=false)
  disabled?: boolean;
  // クリック時の処理
  onChange: () => void;
  // チェック状態
  checked: boolean;
};

/**
 * トグルボタン コンポーネント
 *
 * @param param0
 * @returns
 */
export const ToggleButton = ({
  disabled = false,
  onChange,
  checked,
}: Props) => (
  <label htmlFor="toggle-button" className={styles['toggle-button-wrapper']}>
    <input
      id="toggle-button"
      type="checkbox"
      disabled={disabled}
      onChange={onChange}
      checked={checked}
    />
    <span className={styles['toggle-button']} />
  </label>
);
