/**
 * キャンペーン対象のシリアルコード(暗号化された値)
 */
export const CAMPAIGN_SERIAL =
  'U2FsdGVkX18ubVjm06mHOyD4iUtNPFdGWbSeeYtP534kAuimPBXzPC5UeZD5q1fm' as string;

/**
 * キャンペーンページURL
 */
export const CAMPAIGN_PAGE_URL =
  'https://pocketalk.jp/forbusiness/livetranslation#feeplan' as string;
