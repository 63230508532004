import React from 'react';
import { useTranslation } from 'react-i18next';

import screenShare from '@/assets/screen_share.svg';
import useOpen from '@/hooks/useOpen';

import { ShareStartDialog } from '../../../share/components/ShareStartDialog';

/**
 * プロパティ
 */
type Props = {
  onCloseMenu: () => void;
};
/**
 * ヘッダー > メインメニュー > 共有を開始するメニュー
 */
export const ShareStartContainer = React.memo(({ onCloseMenu }: Props) => {
  const { isOpen, onClose, onOpen } = useOpen();
  const { t } = useTranslation();

  return (
    <>
      <button
        type="button"
        onClick={() => {
          onOpen();
          onCloseMenu();
        }}
        className="main-menu-01"
      >
        <img src={screenShare} alt="screenShare" />
        {t('mainMenu.共有を開始')}
      </button>

      {/* useOpenのisOpenでレンダリング制御しているため、常にtrue */}
      {isOpen && <ShareStartDialog isOpen onClose={onClose} />}
    </>
  );
});
