// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Elements';
import { SHARE_URL_DIRECTORY } from '@/constants/share';
import useOpen from '@/hooks/useOpen';

import { ShareCommonDialog } from './ShareCommonDialog';
import { ShareEndConfirmDialog } from './ShareEndConfirmDialog';

/**
 * プロパティ
 */
type Props = {
  // 共有ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // 共有ダイアログを閉じる
  onClose: () => void;
  // 共有URL
  shareURLKey: string;
};

/**
 * 共有ダイアログ コンポーネント
 */
export const ShareInfoDialog = React.memo(
  ({ isOpen, onClose, shareURLKey }: Props) => {
    const {
      isOpen: isOpenShareEndConfirmDialog,
      onOpen: onOpenShareEndConfirmDialog,
      onClose: onCloseShareEndConfirmDialog,
    } = useOpen();
    const { t } = useTranslation();

    return (
      <>
        {/* 共有ダイアログ */}
        <ShareCommonDialog
          isOpen={isOpen}
          onClose={onClose}
          isHiddenCloseButton={false}
          title={t('shareInfoDialog.共有')}
          description={t(
            'shareInfoDialog.このコードで通訳画面を表示できます',
          ).toString()}
          url={`${process.env.REACT_APP_API_DOMAIN}/${SHARE_URL_DIRECTORY}/${shareURLKey}`}
          isUrlCopyButton
          buttons={
            <Button
              title={t('shareInfoDialog.共有を終了')}
              type="button"
              onClick={onOpenShareEndConfirmDialog}
              gtmClassTag="dialog-04"
            />
          }
        />

        {/* 共有終了確認ダイアログ */}
        {isOpenShareEndConfirmDialog && (
          <ShareEndConfirmDialog
            isOpen
            onClose={onCloseShareEndConfirmDialog}
            onShareEndCompletedFun={onClose} // 共有終了に成功/失敗で共有ダイアログを閉じる
          />
        )}
      </>
    );
  },
);
