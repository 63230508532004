/**
 * 通訳履歴のヘッダー
 */
export const HISTORY_LIST_HEADER = [
  '通訳日時',
  '通訳元言語',
  '通訳元テキスト',
  '通訳先言語',
  '通訳先テキスト',
] as const;
