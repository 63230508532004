/**
 * デバイスの種類
 */
export const DEVICE_TYPE = {
  // iPhone
  IPHONE: 'iPhone',
  // iPad
  IPAD: 'iPad',
  // Android
  ANDROID: 'Android',
  // Androidタブレット
  ANDROID_TABLET: 'Android_tablet',
  // その他タブレット
  OTHER_TABLET: 'Other_tablet',
  // PC
  PC: 'PC',
};
export type DeviceType = (typeof DEVICE_TYPE)[keyof typeof DEVICE_TYPE];

/**
 * OSの種類
 */
export const OS_TYPE = {
  // macOS
  MAC_OS: 'macOS',
  // Windows
  WINDOWS: 'Windows',
  // ChromeOS
  CHROME_OS: 'ChromeOS',
  // Android
  ANDROID: 'Android',
  // iOS
  IOS: 'iOS',
  // iPadOS
  IPADOS: 'iPadOS',
  // その他OS
  OTHER: 'other',
};
export type OsType = (typeof OS_TYPE)[keyof typeof OS_TYPE];

/**
 * ユーザーエージェントから利用しているデバイスを判定する
 *
 * @returns デバイスの種類
 */
export const detectDevice = (): DeviceType => {
  const { userAgent } = window.navigator;

  // iPhone判定
  if (/iPhone/.test(userAgent)) {
    return DEVICE_TYPE.IPHONE;
  }

  // iPad判定
  if (/iPad|Macintosh/.test(userAgent) && 'ontouchend' in document) {
    return DEVICE_TYPE.IPAD;
  }

  // Android判定
  if (/Android/.test(userAgent) && /Mobile/.test(userAgent)) {
    return DEVICE_TYPE.ANDROID;
  }

  // Androidタブレット判定
  if (/Android/.test(userAgent) && !/Mobile/.test(userAgent)) {
    return DEVICE_TYPE.ANDROID_TABLET;
  }

  // その他タブレット判定
  if ('ontouchend' in document) {
    return DEVICE_TYPE.OTHER_TABLET;
  }

  return DEVICE_TYPE.PC;
};

/**
 * 利用しているデバイスがPCかモバイルかを判定する
 *
 * @returns
 */
export const isMobile = (): boolean => {
  const device = detectDevice();

  switch (device) {
    case DEVICE_TYPE.IPHONE:
    case DEVICE_TYPE.IPAD:
    case DEVICE_TYPE.ANDROID:
    case DEVICE_TYPE.ANDROID_TABLET:
    case DEVICE_TYPE.OTHER_TABLET:
      return true;
    default:
      return false;
  }
};

/**
 * ユーザーエージェントから利用しているOSを判定する
 * @returns OSの種類
 */
export const detectOS = (): OsType => {
  const { userAgent } = window.navigator;

  // Android判定
  if (/Android/.test(userAgent) && /Mobile/.test(userAgent)) {
    return OS_TYPE.ANDROID;
  }

  // iOS判定
  if (/iPhone/.test(userAgent)) {
    return OS_TYPE.IOS;
  }

  // iPadOS判定
  if (/iPad|Macintosh/.test(userAgent) && 'ontouchend' in document) {
    return OS_TYPE.IPADOS;
  }

  // Windows判定
  if (/Windows NT/.test(userAgent)) {
    return OS_TYPE.WINDOWS;
  }

  // macOS判定
  if (/Mac OS X/.test(userAgent)) {
    return OS_TYPE.MAC_OS;
  }

  // ChromeOS判定
  if (/CrOS/.test(userAgent)) {
    return OS_TYPE.CHROME_OS;
  }

  // その他OS
  return OS_TYPE.OTHER;
};

/**
 * Apple端末か否か
 * @returns Apple端末の場合 / true それ以外 / false
 */
export const isAppleMobileDevice = (): boolean =>
  detectOS() === OS_TYPE.IPADOS || detectOS() === OS_TYPE.IOS;
