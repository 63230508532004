import React from 'react';
import { useTranslation } from 'react-i18next';

import { GUEST_CAMPAIGN_DISPLAY_TYPE } from '@/constants/ga';
import useOpen from '@/hooks/useOpen';
import { sendGuestCampaignPopupEvent } from '@/utils/ga';

import { CampaignDialog } from './banner/CampaignDialog';
import styles from './CampaignButton.module.scss';

/**
 * キャンペーン ボタン コンポーネント
 *
 * @returns
 */
export const CampaignButton = React.memo(() => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useOpen();

  /**
   * キャンペーンボタンクリック時の処理
   */
  const onClickButton = () => {
    // キャンペーンダイアログ表示
    onOpen();
    // キャンペーンポップアップ表示イベント送信
    sendGuestCampaignPopupEvent({
      display_type: GUEST_CAMPAIGN_DISPLAY_TYPE.BUTTON_CLICK,
    });
  };

  return (
    <>
      <div className={styles.campaign}>
        <button
          className={`${styles['campaign-button']} ${'guest-07'}`}
          onClick={onClickButton}
        >
          <div className={styles['campaign-button-container']}>
            <span className={styles['campaign-icon']} />
            <p>{t('shareGuestCampaign.今だけ、特別割引')}</p>
          </div>
          <span className={styles['forward-icon']} />
        </button>
      </div>

      {/* useOpenのisOpenでレンダリング制御しているため、常にtrue */}
      {isOpen && <CampaignDialog isOpen onClose={onClose} />}
    </>
  );
});
