/**
 * ホーム画面への遷移時に渡す値
 */
export type HomeState = {
  serialCode: string; // URLに指定されたシリアルコード
};

/**
 * stateの型チェック
 *
 * @param target
 * @returns
 */
export const isHomeStateType = (
  target: HomeState | unknown,
): target is HomeState => {
  if (!target) {
    return false;
  }

  if (typeof (target as HomeState).serialCode !== 'string') {
    return false;
  }

  return true;
};
