import { useCallback, useEffect, useMemo } from 'react';

import {
  API_STATUS,
  CAMPAIGN_PAGE_URL,
  CAMPAIGN_SERIAL,
  COOKIE_KEY_NAME,
  COOKIE_USER_TYPE,
} from '@/constants';
import { useLicenseInfoApi } from '@/features/api';
import { decryptStr } from '@/utils/aes';
import { isSerialCampaignActive } from '@/utils/campaign';
import { findCookieValue } from '@/utils/cookie';

/**
 * シリアルで利用時のキャンペーンバナー用カスタムフック
 *
 * @returns
 */
export const useCampaignBanner = () => {
  const { licenseInfoState, fetchLicenseInfo } = useLicenseInfoApi();

  /**
   * キャンペーンバナーがクリックされた場合の処理
   */
  const onClickCampaignBanner = useCallback(() => {
    // 別タブでキャンペーンページを表示
    window.open(CAMPAIGN_PAGE_URL, '_blank');
  }, []);

  /**
   * キャンペーンバナーを表示するか否か
   */
  const shouldShowCampaignBanner = useMemo(() => {
    if (
      licenseInfoState.status !== API_STATUS.SUCCESS ||
      !licenseInfoState.data
    ) {
      return false;
    }

    if (
      findCookieValue(COOKIE_KEY_NAME.PTBR_TYPE) !== COOKIE_USER_TYPE.SERIAL
    ) {
      return false;
    }

    if (licenseInfoState.data.serial !== decryptStr(CAMPAIGN_SERIAL)) {
      return false;
    }

    return true;
  }, [licenseInfoState.data, licenseInfoState.status]);

  /**
   * ヘッダー表示時にライセンス確認API呼び出し(シリアルで利用開始時の場合のみ)
   */
  useEffect(() => {
    if (!isSerialCampaignActive()) {
      // キャンペーン期間外は何もしない
      return;
    }

    if (
      findCookieValue(COOKIE_KEY_NAME.PTBR_TYPE) === COOKIE_USER_TYPE.SERIAL
    ) {
      fetchLicenseInfo();
    }

    // マウント時の1度だけ実行したいので無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    shouldShowCampaignBanner,
    onClickCampaignBanner,
  };
};
