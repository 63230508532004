import React from 'react';
import { useTranslation } from 'react-i18next';

import manual from '@/assets/manual.svg';
import { LinkButtonMenu } from '@/components/Elements';

import { useManualContainer } from '../../hooks/useManualContainer';

/**
 * マニュアルメニュー プロパティ
 */
type ManualContainer = {
  // メインメニューを閉じる
  onCloseMenu: () => void;
};

/**
 * ヘッダー > メインメニュー > マニュアルメニュー
 */
export const ManualContainer = React.memo(
  ({ onCloseMenu }: ManualContainer) => {
    const { t } = useTranslation();
    const { manualLink } = useManualContainer();

    return (
      <LinkButtonMenu
        title={t('mainMenu.マニュアル')}
        href={manualLink}
        isNewTab
        onClick={onCloseMenu} // リンククリックでメインメニューを閉じる
        imgUrl={manual}
      />
    );
  },
);
