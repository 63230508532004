import { PullDownMenuItem } from '@/components/Elements';
import { DEFAULT_UI_LANG, UILanguageList } from '@/constants';

import { BrowserLangData, findBrowserLang } from '../language';

/**
 * UI言語リストに対象の言語が含まれているかどうか
 *
 * @param targetLang 対象の言語
 * @returns true:含まれている、false:含まれていない
 */
const isIncludedUILanguage = (targetLang: string): boolean =>
  UILanguageList.some((item: PullDownMenuItem) => item.value === targetLang);

/**
 * デフォルトのUI言語
 *
 * @returns
 */
export const defaultUiLanguage = (): string => {
  // ブラウザ言語を取得
  const browserLangData: BrowserLangData = findBrowserLang();

  if (!browserLangData.browserLang) {
    return DEFAULT_UI_LANG;
  }

  // UI言語リストに含まれている場合はその言語を返す
  if (isIncludedUILanguage(browserLangData.browserLang)) {
    return browserLangData.browserLang;
  }

  // 'en-US'などの表記になっている場合があるので'-'前の文字列で比較
  if (isIncludedUILanguage(browserLangData.preBrowserLang)) {
    return browserLangData.preBrowserLang;
  }

  return DEFAULT_UI_LANG;
};
