import { Reducer, useCallback, useReducer } from 'react';

import { ApiStatus, API_STATUS } from '@/constants';

import {
  deleteViewApi,
  DeleteViewApiResponse,
  isDeleteViewApiResultCode,
  DELETE_VIEW_API_RESULT_CODE,
} from '../deleteViewApi';

/**
 * State
 */
export type DeleteViewState = {
  // レスポンスデータ
  data?: DeleteViewApiResponse;
  // APIの呼び出し状態
  status: ApiStatus;
};

/**
 * Actionタイプ
 */
const DELETE_VIEW_ACTION_TYPE = {
  SET_DELETE_VIEW: 'SET_DELETE_VIEW',
  SET_DELETE_VIEW_SUCCESS: 'SET_DELETE_VIEW_SUCCESS',
  SET_DELETE_VIEW_FAILED: 'SET_DELETE_VIEW_FAILED',
  SET_DELETE_VIEW_IDLE: 'SET_DELETE_VIEW_IDLE',
} as const;

/**
 * Action
 */
type DeleteViewAction = {
  data?: DeleteViewApiResponse;
  type: keyof typeof DELETE_VIEW_ACTION_TYPE;
};

/**
 * reducer関数
 *
 * @param state
 * @param action
 * @returns
 */
const reducer: Reducer<DeleteViewState, DeleteViewAction> = (
  state: DeleteViewState,
  action: DeleteViewAction,
) => {
  switch (action.type) {
    case DELETE_VIEW_ACTION_TYPE.SET_DELETE_VIEW:
      return {
        ...state,
        status: API_STATUS.LOADING,
      };

    case DELETE_VIEW_ACTION_TYPE.SET_DELETE_VIEW_SUCCESS:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.SUCCESS,
      };

    case DELETE_VIEW_ACTION_TYPE.SET_DELETE_VIEW_FAILED:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.FAILED,
      };

    case DELETE_VIEW_ACTION_TYPE.SET_DELETE_VIEW_IDLE:
      return {
        ...state,
        status: API_STATUS.IDLE,
      };
    default:
      return state;
  }
};

/**
 * 共有画面破棄API 呼び出しhooks
 *
 * @returns
 */
export const useDeleteViewApi = () => {
  const [state, dispatch] = useReducer(reducer, {
    data: undefined,
    status: API_STATUS.IDLE,
  });

  /**
   * API呼び出し
   */
  const deleteView = useCallback(() => {
    if (state.status === API_STATUS.LOADING) {
      return;
    }

    dispatch({ type: DELETE_VIEW_ACTION_TYPE.SET_DELETE_VIEW });

    deleteViewApi()
      .then((response: DeleteViewApiResponse) => {
        // 成功
        if (response.resultCode === DELETE_VIEW_API_RESULT_CODE.OK) {
          dispatch({
            type: DELETE_VIEW_ACTION_TYPE.SET_DELETE_VIEW_SUCCESS,
            data: response,
          });

          return;
        }

        // 意図しない結果コードの型だった場合は失敗とする
        if (!isDeleteViewApiResultCode(response.resultCode)) {
          dispatch({
            type: DELETE_VIEW_ACTION_TYPE.SET_DELETE_VIEW_FAILED,
            data: {
              ...response,
              resultCode: DELETE_VIEW_API_RESULT_CODE.ERR_UNKNOWN,
            } as DeleteViewApiResponse,
          });

          return;
        }

        // 失敗
        dispatch({
          type: DELETE_VIEW_ACTION_TYPE.SET_DELETE_VIEW_FAILED,
          data: response,
        });
      })
      .catch((_) => {
        dispatch({
          type: DELETE_VIEW_ACTION_TYPE.SET_DELETE_VIEW_FAILED,
          data: {
            resultCode: DELETE_VIEW_API_RESULT_CODE.ERR_UNKNOWN,
          } as DeleteViewApiResponse,
        });
      });
  }, [state.status]);

  /**
   * APIのステータスをリセットする
   */
  const resetDeleteViewState = useCallback(() => {
    dispatch({ type: DELETE_VIEW_ACTION_TYPE.SET_DELETE_VIEW_IDLE });
  }, []);

  return {
    deleteViewState: state,
    deleteView,
    resetDeleteViewState,
  };
};
