import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LangData } from '@/constants/language';

/**
 * State
 */
export type LanguageListState = {
  // 翻訳元言語一覧
  srcLangList: LangData[];
  // 翻訳先言語一覧
  destLangList: LangData[];
};

/**
 * 初期化State
 */
const initialState: LanguageListState = {
  srcLangList: [],
  destLangList: [],
};

/**
 * 翻訳元言語/翻訳先言語リスト 管理 Slice
 */
export const languageListSlice = createSlice({
  name: 'languageList',
  initialState,
  reducers: {
    setSrcLangList: (state, action: PayloadAction<LangData[]>) => {
      state.srcLangList = action.payload;
    },
    setDestLangList: (state, action: PayloadAction<LangData[]>) => {
      state.destLangList = action.payload;
    },
    clearLanguageList: () => initialState,
  },
});
