import {
  GUEST_STATUS_TYPE,
  GuestStatusType,
} from '@/states/slices/guestDisplaySlice';

/**
 * 共有URLに付与するディレクトリ名
 *
 * ※このディレクトリ名をもとに、ゲスト画面の用途(共有機能/Bot)を判別する
 */
export const SHARE_URL_DIRECTORY = 'guest';

/**
 * 共有用ゲスト画面のバナーに表示するメッセージMAP
 */
export const ShareGuestBannerMessageMap: { [key in GuestStatusType]: string } =
  {
    [GUEST_STATUS_TYPE.NONE]: '',
    [GUEST_STATUS_TYPE.LOADING]: '',
    [GUEST_STATUS_TYPE.SUCCESS]: '',
    [GUEST_STATUS_TYPE.OVER_LIMIT]:
      '共有人数の上限に達しているため表示できません',
    [GUEST_STATUS_TYPE.SHARE_END]: 'ホストが共有を終了しました',
    [GUEST_STATUS_TYPE.INVALID_URL_KEY]: '',
    [GUEST_STATUS_TYPE.CANNOT_CONTINUE]: '接続が切れました',
    [GUEST_STATUS_TYPE.OTHER]: '',
  };

/**
 * 共有用ゲスト画面の翻訳先言語の初期値
 */
export const DEFAULT_GUEST_DEST_LANG = 'en';
