import { useSignUp } from '../hooks/useSignUp';

import styles from './SignUp.module.scss';
/**
 * ようこそ画面(サインアップ用) コンポーネント
 *
 * ※サインアップ画面を表示するためだけの画面。グレーの画面を表示するのみ。ユーザ操作は一切できない。
 */
export const SignUp = () => {
  useSignUp();

  return (
    <div className={styles['sign-up']}>
      <p />
    </div>
  );
};
