import { Reducer, useCallback, useReducer, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ApiStatus,
  API_STATUS,
  PAGE_ROUTE_PATH,
  URL_PARAMS_KEY,
} from '@/constants';
import {
  LICENSE_INFO_API_RESULT_CODE,
  LOGOUT_API_RESULT_CODE,
  licenseInfoApi,
  logoutApi,
} from '@/features/api';
import { useClearLocalStorage } from '@/hooks/useClearLocalStorage';

/**
 * 期限切れシリアル利用時のログアウト処理結果
 */
export const EXPIRED_SERIAL_LOGOUT_RESULT = {
  // ログアウト失敗(シリアルが期限内の場合も含む)
  FAILED: 'FAILED',
  // ログアウト成功
  SUCCESS: 'SUCCESS',
} as const;
export type ExpiredSerialLogoutResult =
  (typeof EXPIRED_SERIAL_LOGOUT_RESULT)[keyof typeof EXPIRED_SERIAL_LOGOUT_RESULT];

/**
 * ライセンス確認APIを呼んで期限切れならログアウトAPIを呼び出す
 *
 * @returns
 */
const callLogoutApiIfLicenseExpired =
  async (): Promise<ExpiredSerialLogoutResult> => {
    // ライセンス確認APIを呼んでシリアルの期限をチェック
    const licenseInfoResponse = await licenseInfoApi();

    // シリアルが期限内
    if (licenseInfoResponse.resultCode === LICENSE_INFO_API_RESULT_CODE.OK) {
      return EXPIRED_SERIAL_LOGOUT_RESULT.FAILED;
    }

    // 期限切れ以外のエラー
    if (
      licenseInfoResponse.resultCode !==
      LICENSE_INFO_API_RESULT_CODE.INFO_EXPIRED_LICENSE
    ) {
      return EXPIRED_SERIAL_LOGOUT_RESULT.FAILED;
    }

    // シリアルが期限切れの場合はログアウトAPIを呼び出す
    const logoutResponse = await logoutApi();

    // ログアウト失敗
    if (logoutResponse.resultCode !== LOGOUT_API_RESULT_CODE.OK) {
      return EXPIRED_SERIAL_LOGOUT_RESULT.FAILED;
    }

    // ログアウト成功
    return EXPIRED_SERIAL_LOGOUT_RESULT.SUCCESS;
  };

export type ExpiredSerialLogoutState = {
  // 結果
  data?: ExpiredSerialLogoutResult;
  // 処理状態
  status: ApiStatus;
};

/**
 * Actionタイプ
 */
const EXPIRED_SERIAL_LOGOUT_ACTION_TYPE = {
  SET_EXPIRED_SERIAL_LOGOUT: 'SET_EXPIRED_SERIAL_LOGOUT',
  SET_EXPIRED_SERIAL_LOGOUT_SUCCESS: 'SET_EXPIRED_SERIAL_LOGOUT_SUCCESS',
  SET_EXPIRED_SERIAL_LOGOUT_FAILED: 'SET_EXPIRED_SERIAL_LOGOUT_FAILED',
} as const;

/**
 * Action
 */
type ExpiredSerialLogoutAction = {
  data?: ExpiredSerialLogoutResult;
  type: keyof typeof EXPIRED_SERIAL_LOGOUT_ACTION_TYPE;
};

/**
 * reducer関数
 *
 * @param state
 * @param action
 * @returns
 */
const reducer: Reducer<ExpiredSerialLogoutState, ExpiredSerialLogoutAction> = (
  state: ExpiredSerialLogoutState,
  action: ExpiredSerialLogoutAction,
) => {
  switch (action.type) {
    case EXPIRED_SERIAL_LOGOUT_ACTION_TYPE.SET_EXPIRED_SERIAL_LOGOUT:
      return {
        ...state,
        status: API_STATUS.LOADING,
      };
    case EXPIRED_SERIAL_LOGOUT_ACTION_TYPE.SET_EXPIRED_SERIAL_LOGOUT_SUCCESS:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.SUCCESS,
      };
    case EXPIRED_SERIAL_LOGOUT_ACTION_TYPE.SET_EXPIRED_SERIAL_LOGOUT_FAILED:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.FAILED,
      };
    default:
      return state;
  }
};

/**
 * 期限が切れたシリアルでログイン中の場合用のログアウト処理 カスタムフック
 *
 * @returns
 */
export const useLogoutExpiredSerial = () => {
  const { doCleanLocalStorage } = useClearLocalStorage();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(reducer, {
    data: undefined,
    status: API_STATUS.IDLE,
  });

  // シリアル画面遷移時に付与するシリアルコード
  const serialCodeRef = useRef<string>('');

  /**
   * ログアウトに成功したらコード付きでシリアル画面に遷移
   * 遷移前にローカルストレージを初期化する
   */
  const doNavigateSerialView = useCallback(() => {
    // ローカルストレージ初期化
    doCleanLocalStorage();
    // コード付きでシリアル画面に遷移
    navigate(
      `${PAGE_ROUTE_PATH.SERIAL}?${URL_PARAMS_KEY.SERIAL.code}=${serialCodeRef.current}`,
    );
  }, [doCleanLocalStorage, navigate]);

  /**
   * シリアルの期限をチェックして期限切れだった場合に以下の処理実行
   * ・自動ログアウト
   * ・ローカルストレージ初期化
   * ・コード付きでシリアル画面に遷移
   */
  const logoutIfExpiredSerial = useCallback(
    async (serialCode: string) => {
      dispatch({
        type: EXPIRED_SERIAL_LOGOUT_ACTION_TYPE.SET_EXPIRED_SERIAL_LOGOUT,
      });

      // シリアル画面遷移時に付与するシリアルコードを保存
      serialCodeRef.current = serialCode;

      // ライセンス確認APIを呼んで期限切れならログアウトAPIを呼び出す
      const result = await callLogoutApiIfLicenseExpired();
      if (result === EXPIRED_SERIAL_LOGOUT_RESULT.FAILED) {
        // ログアウト失敗
        dispatch({
          type: EXPIRED_SERIAL_LOGOUT_ACTION_TYPE.SET_EXPIRED_SERIAL_LOGOUT_FAILED,
          data: EXPIRED_SERIAL_LOGOUT_RESULT.FAILED,
        });

        return;
      }

      // ログアウト成功
      doNavigateSerialView();
      dispatch({
        type: EXPIRED_SERIAL_LOGOUT_ACTION_TYPE.SET_EXPIRED_SERIAL_LOGOUT_SUCCESS,
        data: EXPIRED_SERIAL_LOGOUT_RESULT.SUCCESS,
      });
    },
    [doNavigateSerialView],
  );

  return {
    expiredSerialLogoutState: state,
    logoutIfExpiredSerial,
  };
};
