/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useRef } from 'react';

import { useTranslationDisplay } from './useTranslationDisplay';

const WATCH_EXPAND_EVENTS = 'click';
const WATCH_UN_EXPAND_EVENTS = 'click,blur,keyup';

/**
 * 翻訳画面で使用している <select> 要素の制御 hooks
 */
export const useTranslationSelect = () => {
  const { setIsOpenSetting } = useTranslationDisplay();
  const selectRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    // <select>要素
    const selectElement: HTMLSelectElement | null = selectRef.current;

    /**
     * <select>要素が開いたときの処理
     */
    const expanding = () => {
      // Select要素が開いている
      setIsOpenSetting(true);

      selectElement?.removeEventListener(WATCH_EXPAND_EVENTS, expanding);
      WATCH_UN_EXPAND_EVENTS.split(',').forEach((eventName) => {
        selectElement?.addEventListener(eventName, unExpanding);
      });
    };

    /**
     * <select>要素が閉じたときの処理
     */
    const unExpanding = () => {
      // Select要素が閉じている
      setIsOpenSetting(false);

      WATCH_UN_EXPAND_EVENTS.split(',').forEach((eventName) => {
        selectElement?.removeEventListener(eventName, unExpanding);
      });
      selectElement?.addEventListener(WATCH_EXPAND_EVENTS, expanding);
    };

    // このカスタムフックがマウントされたときに実行される
    selectElement?.addEventListener(WATCH_EXPAND_EVENTS, expanding);

    // アンマウント時に実行される
    return () => {
      selectElement?.removeEventListener(WATCH_EXPAND_EVENTS, expanding);
      WATCH_UN_EXPAND_EVENTS.split(',').forEach((eventName) => {
        selectElement?.removeEventListener(eventName, unExpanding);
      });
    };
  }, [setIsOpenSetting]);

  return {
    selectRef,
  };
};
