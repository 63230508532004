/**
 * 全画面共通で表示する共通エラーダイアログのエラーコード
 */
export const COMMON_ERROR_DIALOG_CODE = {
  // アクセス期限切れエラー
  ACCESS_ERROR: 'accessError',
  // PTID期限切れエラー
  PTID_ERROR: 'ptidError',
  // 利用規約同意エラー
  AGREEMENT_ERROR: 'agreementError',
  // ライセンス不正エラー
  LICENSE_ERROR: 'licenseError',
};
export type CommonErrorDialogType =
  (typeof COMMON_ERROR_DIALOG_CODE)[keyof typeof COMMON_ERROR_DIALOG_CODE];
