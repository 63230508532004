import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import {
  COOKIE_KEY_NAME,
  PAGE_ROUTE_PATH,
  TranslationState,
  URL_PARAMS_KEY,
} from '@/constants';
import { useUrlParameter } from '@/hooks/useUrlParameter';
import { findCookieValue } from '@/utils/cookie';

import { AgreementState } from '../../../features/agreement/constants';

/**
 * 翻訳画面にstateが指定されているか否か
 *
 * @param target
 * @returns true=指定されている
 */
const isTranslationStateType = (
  target: TranslationState | unknown,
): target is TranslationState => {
  if (typeof target !== 'string') {
    return false;
  }

  return true;
};

/**
 * 利用規約画面にstateが指定されているか否か
 *
 * @param target
 * @returns true=指定されている
 */
const isAgreementStateType = (
  target: AgreementState | unknown,
): target is AgreementState => {
  if (typeof target !== 'string') {
    return false;
  }

  return true;
};

/**
 * Cookieにトークンがある場合の画面遷移制御
 *
 * @param param0
 * @returns
 */
const TokenWrapperContainer = ({
  children,
  pathname,
  state,
}: {
  children: ReactNode;
  pathname: string;
  state: any;
}) => {
  const { findParams } = useUrlParameter();

  // ホーム画面の場合は遷移許可
  if (pathname === PAGE_ROUTE_PATH.HOME) {
    return <> {children}</>;
  }

  // 翻訳画面に遷移する場合、stateが指定されている場合は遷移許可
  if (
    pathname === PAGE_ROUTE_PATH.TRANSLATION.MIC ||
    pathname === PAGE_ROUTE_PATH.TRANSLATION.SPEAKER
  ) {
    if (isTranslationStateType(state)) {
      return <> {children}</>;
    }
  }

  // 利用規約画面の場合、指定のstateがある時のみ遷移許可
  if (pathname === PAGE_ROUTE_PATH.AGREEMENT) {
    if (isAgreementStateType(state)) {
      return <> {children}</>;
    }
  }

  // コード付きシリアル画面URLだった場合、stateを付与してホーム画面に遷移
  const serialCode = findParams(URL_PARAMS_KEY.SERIAL.code);
  if (serialCode) {
    return (
      <Navigate to={PAGE_ROUTE_PATH.HOME} state={{ serialCode }} replace />
    );
  }

  // ホーム画面を表示
  return <Navigate to={PAGE_ROUTE_PATH.HOME} replace />;
};

/**
 * Cookieにトークンが無い場合の画面遷移制御
 *
 * @param param0
 * @returns
 */
const UnTokenWrapperContainer = ({
  children,
  pathname,
}: {
  children: ReactNode;
  pathname: string;
}) => {
  // シリアル入力画面の場合は遷移許可
  if (pathname === PAGE_ROUTE_PATH.SERIAL) {
    return <> {children}</>;
  }

  // 利用方法選択画面の場合は遷移許可
  if (pathname === PAGE_ROUTE_PATH.SELECT_USAGE) {
    return <> {children}</>;
  }

  // 利用方法選択画面を表示
  return <Navigate to={PAGE_ROUTE_PATH.SELECT_USAGE} replace />;
};

/**
 * 画面遷移制御
 *
 * @param param0
 * @returns
 */
export const TransitionWrapperContainer = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { pathname, state } = useLocation();

  // ドメイン直打ちの場合は利用方法選択画面を表示
  if (pathname === '/') {
    return <Navigate to={PAGE_ROUTE_PATH.SELECT_USAGE} replace />;
  }

  // Cookieからトークンを取得
  const token = findCookieValue(COOKIE_KEY_NAME.PTBR_TOKEN);

  // トークンありの場合の画面遷移制御
  if (token) {
    return TokenWrapperContainer({ children, pathname, state });
  }

  // トークンなしの場合の画面遷移制御
  return UnTokenWrapperContainer({ children, pathname });
};
