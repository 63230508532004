import { BROWSER } from '@/constants';
import { browserAxios } from '@/lib/axios';

import { API_AUTH_RESULT_CODE } from './apiAuth';

/**
 * 結果コード
 */
export const AGREEMENT_UPDATE_API_RESULT_CODE = {
  ...({
    // 成功
    OK: 'OK',
    // 不明なエラー
    ERR_UNKNOWN: 'ERR_UNKNOWN',
  } as const),
  ...API_AUTH_RESULT_CODE,
} as const;

export type AgreementUpdateApiResultCode =
  (typeof AGREEMENT_UPDATE_API_RESULT_CODE)[keyof typeof AGREEMENT_UPDATE_API_RESULT_CODE];

/**
 * 結果コードのTypeGuard
 *
 * @param resultCode
 * @returns
 */
export const isAgreementUpdateApiResultCode = (
  resultCode: string,
): resultCode is AgreementUpdateApiResultCode => {
  if (resultCode in AGREEMENT_UPDATE_API_RESULT_CODE) {
    return true;
  }

  return false;
};

/**
 * レスポンス
 */
export type AgreementUpdateApiResponse = {
  // 結果コード
  resultCode: AgreementUpdateApiResultCode;
};

/**
 * API 呼び出し
 *
 * @returns
 */
const callApi = (): Promise<AgreementUpdateApiResponse> => {
  // リクエスト送信（GET）
  const response = browserAxios
    .get<AgreementUpdateApiResponse>(BROWSER.API_URL.AGREEMENT_UPDATE, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then((apiResponse) => apiResponse.data);

  return response;
};

/**
 * 利用許諾API リクエスト
 *
 * @returns
 */
export const agreementUpdateApi = (): Promise<AgreementUpdateApiResponse> =>
  callApi();
