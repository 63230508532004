import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
  CREDENTIALS_API_RESULT_CODE,
  CreateViewApiResponse,
  ViewInfoApiResponse,
} from '@/features/api';
import { useFirestoreLoginUseToken } from '@/features/firestore';
import {
  SHARE_VIEW_STATUS,
  ShareViewInfoState,
  ShareViewStatus,
  shareViewInfoSlice,
} from '@/states/slices/shareViewInfoSlice';
import { AppDispatch, RootState, useAppDispatch } from '@/states/store';

/**
 * 共有画面に関する情報を保存 hooks
 *
 * @returns
 */
export const useShareViewInfo = () => {
  const { signInFirestore } = useFirestoreLoginUseToken();
  const dispatch: AppDispatch = useAppDispatch();

  const {
    setShareViewStatus,
    setShareURLKey,
    setUid,
    setTotalGuests,
    setMaxGuests,
    setIsOpenShareInfoDialogAfterStart,
    setIsOpenShareRejoinDialog,
    setIsOpenShareInvalidDialog,
    resetToInitialState,
  } = shareViewInfoSlice.actions;

  const {
    shareViewStatus,
    shareURLKey,
    uid,
    totalGuests,
    maxGuests,
    isOpenShareInfoDialogAfterStart,
    isOpenShareRejoinDialog,
    isOpenShareInvalidDialog,
  } = useSelector<RootState, ShareViewInfoState>(
    (state) => state.shareViewInfo,
  );

  /**
   * 共有画面の共有状況を更新
   */
  const changeShareViewStatus = useCallback(
    (value: ShareViewStatus) => {
      dispatch(setShareViewStatus(value));
    },
    [dispatch, setShareViewStatus],
  );

  /**
   * 共有画面のURLキーを更新
   */
  const changeShareURLKey = useCallback(
    (value: string) => {
      dispatch(setShareURLKey(value));
    },
    [dispatch, setShareURLKey],
  );

  /**
   * FirestoreのドキュメントIDを更新
   */
  const changeUid = useCallback(
    (value: string) => {
      dispatch(setUid(value));
    },
    [dispatch, setUid],
  );

  /**
   * 共有画面利用者の合計を更新
   */
  const changeTotalGuests = useCallback(
    (value: number) => {
      dispatch(setTotalGuests(value));
    },
    [dispatch, setTotalGuests],
  );

  /**
   * 共有可能な人数の上限を更新
   */
  const changeMaxGuests = useCallback(
    (value: number) => {
      dispatch(setMaxGuests(value));
    },
    [dispatch, setMaxGuests],
  );

  /**
   * 共有開始直後に共有ダイアログを表示するか否か
   */
  const changeIsOpenShareInfoDialogAfterStart = useCallback(
    (value: boolean) => {
      dispatch(setIsOpenShareInfoDialogAfterStart(value));
    },
    [dispatch, setIsOpenShareInfoDialogAfterStart],
  );

  /**
   * 共有復帰ダイアログを表示するか否か
   */
  const changeIsOpenShareRejoinDialog = useCallback(
    (value: boolean) => {
      dispatch(setIsOpenShareRejoinDialog(value));
    },
    [dispatch, setIsOpenShareRejoinDialog],
  );

  /**
   * 共有終了ダイアログを表示するか否か
   */
  const changeIsOpenShareInvalidDialog = useCallback(
    (value: boolean) => {
      dispatch(setIsOpenShareInvalidDialog(value));
    },
    [dispatch, setIsOpenShareInvalidDialog],
  );

  /**
   * 共有URLに関する全ての情報をリセット
   */
  const resetState = useCallback(() => {
    dispatch(resetToInitialState());
  }, [dispatch, resetToInitialState]);

  /**
   * 共有画面情報を更新
   *
   * @param urlKey 共有画面のURLキー
   * @param maxGuestsValue 共有可能な人数の上限
   * @param isOpenShareInfoDialog 共有開始直後に共有ダイアログを表示するか否か
   */
  const updateShareViewInfo = useCallback(
    (
      urlKey: string,
      maxGuestsValue: number,
      isOpenShareInfoDialog: boolean,
    ) => {
      // Firestore接続情報取得APIを呼んでFirestoreにログイン
      signInFirestore().then((result) => {
        if (
          result.credentialsApiResultCode !== CREDENTIALS_API_RESULT_CODE.OK
        ) {
          // 共有情報取得失敗
          changeShareViewStatus(SHARE_VIEW_STATUS.SHARE_INFO_ERROR);

          return;
        }

        // uidをReduxに保存
        changeUid(result.uid);
        // 共有有効(開始)
        changeShareViewStatus(SHARE_VIEW_STATUS.SHARED);
        changeShareURLKey(urlKey);
        changeMaxGuests(maxGuestsValue);
        if (isOpenShareInfoDialog) {
          changeIsOpenShareInfoDialogAfterStart(isOpenShareInfoDialog); // trueの場合のみ更新
        }
      });
    },
    [
      changeIsOpenShareInfoDialogAfterStart,
      changeMaxGuests,
      changeShareURLKey,
      changeShareViewStatus,
      changeUid,
      signInFirestore,
    ],
  );

  /**
   * 共有画面情報取得APIから返却された結果をもとに共有情報を更新
   */
  const updateShareViewInfoOnViewInfoApiResponse = useCallback(
    (apiResponse: ViewInfoApiResponse) => {
      if (!apiResponse.isBrowsable) {
        return; // 無効の場合は何もしない
      }

      updateShareViewInfo(apiResponse.urlKey, apiResponse.limitGuests, false);
    },
    [updateShareViewInfo],
  );

  /**
   * 共有画面作成APIから返却された結果をもとに共有情報を更新
   */
  const updateShareViewInfoOnCreateViewApiResponse = useCallback(
    (apiResponse: CreateViewApiResponse) => {
      updateShareViewInfo(
        apiResponse.urlKey,
        apiResponse.limitGuests,
        true, // 共有開始直後に共有ダイアログを自動で表示
      );
    },
    [updateShareViewInfo],
  );

  /**
   * 共有中か否か
   *
   * @returns 共有中の場合はtrue
   */
  const isShared = useCallback(
    (currentShareViewStatus: ShareViewStatus): boolean => {
      if (currentShareViewStatus === SHARE_VIEW_STATUS.SHARED) {
        return true;
      }

      return false;
    },
    [],
  );

  return {
    shareViewStatus,
    shareURLKey,
    uid,
    totalGuests,
    maxGuests,
    isOpenShareInfoDialogAfterStart,
    isOpenShareRejoinDialog,
    isOpenShareInvalidDialog,
    setShareViewStatus: changeShareViewStatus,
    setShareURLKey: changeShareURLKey,
    setUid: changeUid,
    setTotalGuests: changeTotalGuests,
    setMaxGuests: changeMaxGuests,
    setIsOpenShareInfoDialogAfterStart: changeIsOpenShareInfoDialogAfterStart,
    setIsOpenShareRejoinDialog: changeIsOpenShareRejoinDialog,
    setIsOpenShareInvalidDialog: changeIsOpenShareInvalidDialog,
    resetState,
    updateShareViewInfoOnViewInfoApiResponse,
    updateShareViewInfoOnCreateViewApiResponse,
    isShared,
  };
};
