// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */
import React, { useEffect } from 'react';

import {
  LIST_DISPLAY_TYPE,
  RecognizedItem,
  RecognizedList,
  TRANSLATION_LIST_VIEW_TYPE,
  TranslationListViewType,
} from '@/constants';
import { useTranslationDisplay } from '@/hooks/useTranslationDisplay';

import { useRecognizedListScroll } from '../translation/hooks/useRecognizedListScroll';

import { FixedInterimView } from './FixedInterimView';
import { InterimListView } from './InterimListView';
import styles from './ListView.module.scss';

/**
 * 翻訳一覧の高さ用className
 * フッター開閉＆固定暫定テキスト表示有無で変更する
 *
 * @param shouldDisplayFooter フッター開閉状況(true=開いている)
 * @param isDisplayInterim 暫定テキストを固定表示するか否か(true=固定表示)
 * @param translationListViewType 画面のタイプ（ホストorゲスト）
 * @param isShowGuestBannerBottomHeader 共有バナーが表示されているか否か
 * @returns 翻訳一覧の高さ用CSS
 */
const recognizedListViewHeightClassName = (
  shouldDisplayFooter: boolean,
  isDisplayInterim: boolean,
  translationListViewType: TranslationListViewType,
  isShowGuestBannerBottomHeader: boolean,
): string => {
  // ゲスト画面で表示する場合
  if (translationListViewType === TRANSLATION_LIST_VIEW_TYPE.CONFERENCE) {
    if (isShowGuestBannerBottomHeader) {
      return styles['footer-open-fixed-interim-recognized'];
    }

    return '';
  }
  // 暫定テキストの固定表示する時
  if (!isDisplayInterim) {
    if (shouldDisplayFooter) {
      return '';
    }

    return styles['footer-open-fixed-interim-recognized'];
  }

  // 固定表示が必要でフッターが開いている場合は固定表示分の幅を用意
  if (shouldDisplayFooter) {
    return styles['footer-open-fixed-interim-recognized'];
  }

  return styles['footer-close-fixed-interim-recognized'];
};

type Props = RecognizedList & {
  // 画面のタイプ（ホストorゲスト）
  translationListViewType?: TranslationListViewType;
  // 共有バナーが表示されているか否か
  isShowGuestBannerBottomHeader?: boolean;
};

/**
 * 音声認識テキストリストビュー コンポーネント
 */
export const RecognizedListView = React.memo(
  ({
    lists,
    displayType,
    interimContent,
    translationListViewType = TRANSLATION_LIST_VIEW_TYPE.TRANSLATION,
    isShowGuestBannerBottomHeader = false,
  }: Props) => {
    // 自動スクロール制御
    const autoScroll = useRecognizedListScroll();
    useEffect(() => {
      autoScroll.scrollListView();
      autoScroll.fixedInterimView();
    }); // 第二引数なしで、再レンダリングのたびに必ず実行

    const { shouldDisplayFooter } = useTranslationDisplay();

    return (
      <>
        <ul
          className={`${styles.recognized} ${recognizedListViewHeightClassName(
            shouldDisplayFooter,
            autoScroll.isDisplayInterim,
            translationListViewType,
            isShowGuestBannerBottomHeader,
          )}`}
          data-testid="recognized-list-view"
          id="recognized-list-view"
          ref={autoScroll.recognizedListRef}
        >
          {displayType === LIST_DISPLAY_TYPE.onePhrase
            ? // 縦表示
              lists.map((content: RecognizedItem) => (
                <li key={content.id}>
                  <div
                    lang={
                      content.value.isReversed
                        ? content.value.srclang
                        : content.value.destlang
                    }
                    className={`${styles.translated} ${
                      content.value.isReversed ? styles.reversed : ''
                    }`}
                  >
                    {content.value.isReversed
                      ? content.value.stt
                      : content.value.ttt}
                  </div>
                </li> // ※Reactはindexの指定が必須(indexがあれば変更があった部分のみレンダリングされる)
              ))
            : // 横表示
              lists.map((content: RecognizedItem) => (
                <li key={content.id}>
                  <div
                    lang={
                      content.value.isReversed
                        ? content.value.destlang
                        : content.value.srclang
                    }
                    className={`${styles.raw} ${styles['stt-text']} ${
                      !content.value.isReversed ? styles.reversed : ''
                    }`}
                  >
                    {content.value.isReversed
                      ? content.value.ttt
                      : content.value.stt}
                  </div>
                  <div
                    lang={
                      content.value.isReversed
                        ? content.value.srclang
                        : content.value.destlang
                    }
                    className={`${styles.translated} ${
                      content.value.isReversed ? styles.reversed : ''
                    }`}
                  >
                    {content.value.isReversed
                      ? content.value.stt
                      : content.value.ttt}
                  </div>
                </li>
              ))}

          {/* 暫定テキスト（スクロール最下部時の表示） */}
          {interimContent.stt && (
            <InterimListView
              displayType={displayType}
              interimContent={interimContent}
            />
          )}
        </ul>

        {/* 暫定テキスト（固定表示） */}
        {autoScroll.isDisplayInterim && (
          <FixedInterimView
            displayType={displayType}
            interimContent={interimContent}
          />
        )}
      </>
    );
  },
);
