import { useCallback, useState } from 'react';

import { useWakeLock } from '@/hooks/useWakeLock';
import { isAppleMobileDevice } from '@/utils/device';

/**
 * ゲスト画面ようこそダイアログ カスタムフック
 */
export const useWelcomeGuestDialog = () => {
  const [isWelcomeGuestDialogOpen, setIsWelcomeGuestDialogOpen] =
    useState<boolean>(isAppleMobileDevice());

  const { addWakeLock } = useWakeLock();

  /**
   * ゲスト画面ようこそダイアログのOKボタン押下時の処理
   */
  const clickWelcomeGuestOKButton = useCallback(() => {
    setIsWelcomeGuestDialogOpen(false);
    addWakeLock();
  }, [addWakeLock]);

  return { isWelcomeGuestDialogOpen, clickWelcomeGuestOKButton };
};
