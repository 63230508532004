import { useEffect } from 'react';

import { URL_PARAMS_KEY } from '@/constants';
import { useLoginAuth } from '@/features/auth';
import { useUrlParameter } from '@/hooks/useUrlParameter';

/**
 * ようこそ画面用 カスタムフック
 */
export const useWelcome = () => {
  const { findParams } = useUrlParameter();
  const { loginAuth } = useLoginAuth({
    redirectPath: findParams(URL_PARAMS_KEY.WELCOME.path),
  });

  /**
   * マウント時の処理
   */
  useEffect(() => {
    // ログイン認証実行
    loginAuth();

    // マウント時の1度のみ実行したいので無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
