import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Button,
  SpinnerOverlayView,
  ALIGN_TYPE,
  FONT_VARIANT_TYPE,
  TextBlock,
} from '@/components/Elements';
import { FormInput } from '@/components/Form';
import { ContentLayout } from '@/components/Layout';
import { API_STATUS } from '@/constants';

import { SerialForm, SERIAL_FORM_SCHEMA, SERIAL_VALIDATE } from '../constants';
import { useSerial } from '../hooks/useSerial';

import styles from './Serial.module.scss';

/**
 * シリアル画面 子コンポーネント
 *
 * @returns
 */
const SerialView = () => {
  // hooks
  const { onClickSend, serialAuthState, serialErrorMassage, defaultSerial } =
    useSerial();
  const { t } = useTranslation();

  // フォーム設定
  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
    setValue,
  } = useForm<SerialForm>({
    resolver: zodResolver(SERIAL_FORM_SCHEMA),
  });

  // 自動フォーカスとデフォルト値設定
  useEffect(() => {
    setFocus('serial');
    setValue('serial', defaultSerial);
  }, [defaultSerial, setFocus, setValue]);

  return (
    <>
      <SpinnerOverlayView
        isOpen={serialAuthState.status === API_STATUS.LOADING}
      />
      <div className={styles.serial}>
        <div className={styles['serial-title']}>
          <TextBlock text={t('serial.ログイン')} align={ALIGN_TYPE.center} />
        </div>

        <form onSubmit={handleSubmit(onClickSend)}>
          <div className={styles['serial-form']}>
            <div>
              <TextBlock
                text={t(
                  'serial.シリアルコードはハイフン（-）を含む、半角英数字22～25桁で入力してください。',
                )}
              />

              <div>
                <div className={styles['serial-form-title']}>
                  <TextBlock text={t('serial.シリアルコード')} />
                </div>
                <FormInput
                  type="text"
                  id="serial"
                  name="serial"
                  placeholder="AAAAA-1111-2222-3333-4444"
                  errorMessage={
                    errors.serial
                      ? t(`serial.${errors.serial.message}`).toString()
                      : undefined
                  }
                  maxLength={SERIAL_VALIDATE.LENGTH.MAX}
                  registration={register('serial')}
                />
              </div>
            </div>

            <div className={`${styles['serial-button']} dts-auth`}>
              <Button
                title={t('serial.認証')}
                type="submit"
                disabled={
                  errors.serial !== undefined ||
                  serialAuthState.status === API_STATUS.LOADING
                }
              />
            </div>

            {serialAuthState.status === API_STATUS.FAILED && (
              <div className={styles['serial-form-error']}>
                <TextBlock
                  text={t(`serial.${serialErrorMassage}`)}
                  align={ALIGN_TYPE.center}
                  fontVariant={FONT_VARIANT_TYPE.alert}
                />
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

/**
 * シリアル画面
 *
 * @returns
 */
export const Serial = () => (
  <ContentLayout>
    <SerialView />
  </ContentLayout>
);
