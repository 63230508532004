import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  DIALOG_BUTTON_TYPE,
  DialogMessageContents,
  ModalDialog,
} from '@/components/Elements';

import { useSilenceContinuousError } from '../../hooks/error/useSilenceContinuousError';

type SilenceErrorDialog = {
  // ダイアログ表示/非表示
  isOpen: boolean;
  // STTスタート
  startSTT: () => void;
};

/**
 * 5分無音エラーダイアログ コンポーネント
 *
 * @param param0 本ダイアログ表示/非表示
 * @returns
 */
export const SilenceErrorDialog = React.memo(
  ({ isOpen, startSTT }: SilenceErrorDialog) => {
    const { clickCancelButtonSilence, clickRestartButton } =
      useSilenceContinuousError();

    const { t } = useTranslation();

    return (
      <ModalDialog
        isOpen={isOpen}
        title={t('translationDialog.利用を再開しますか？').toString()}
        buttons={[
          {
            label: t('translationDialog.キャンセル'),
            onClick: clickCancelButtonSilence,
            type: DIALOG_BUTTON_TYPE.CHANCEL,
            gtmClassTag: 'dts-error-5minutes-cancel',
          },
          {
            label: t('translationDialog.再開'),
            onClick: () => {
              clickRestartButton(startSTT);
            },
            gtmClassTag: 'dts-error-5minutes-restart',
          },
        ]}
      >
        <DialogMessageContents>
          {t(
            'translationDialog.音声入力が一定時間ないため自動的に停止しました。',
          )}
        </DialogMessageContents>
      </ModalDialog>
    );
  },
);
