import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type BrowserUserSettingState = {
  // 入力元デバイス
  inputDevice: string;
  // 出力元デバイス
  outputDevice: string;
  // UI言語
  language: string;
  // 翻訳履歴を保存するか否か(true=保存)
  isHistory: boolean;
};

/**
 * 初期State
 */
const initialState: BrowserUserSettingState = {
  inputDevice: '',
  outputDevice: '',
  language: '',
  isHistory: false,
};

/**
 * [設定ダイアログ]の設定内容を管理 Slice
 */
export const browserUserSettingSlice = createSlice({
  name: 'browserUserSetting',
  initialState,
  reducers: {
    // 入力元デバイスを更新
    setInputDevice: (state, action: PayloadAction<string>) => {
      state.inputDevice = action.payload;
    },
    // 出力元デバイスを更新
    setOutputDevice: (state, action: PayloadAction<string>) => {
      state.outputDevice = action.payload;
    },
    // UI言語を更新
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    // 翻訳履歴を保存するか否かを更新
    setIsHistory: (state, action: PayloadAction<boolean>) => {
      state.isHistory = action.payload;
    },
    // 初期化
    clearUserSetting: () => initialState,
  },
});
