import { useCallback } from 'react';

import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { STT_ERROR_TYPE } from '@/states/slices/translationInfoSlice';

/**
 * 音声信号無音エラー カスタムフック
 */
export const useNoSoundError = () => {
  const { setSttErrorType } = useTranslationInfo();

  const clickOkButtonNoSound = useCallback(() => {
    // エラーダイアログを閉じる
    setSttErrorType(STT_ERROR_TYPE.NONE);
  }, [setSttErrorType]);

  return {
    clickOkButtonNoSound,
  }
};
