import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ModalDialog,
  SpinnerOverlayView,
  TextBlock,
} from '@/components/Elements';
import { FormInput } from '@/components/Form';
import { API_STATUS } from '@/constants';
import { ContractRenewalDialog } from '@/features/contract';
import { AccessExpiredDialog, LicenseInvalidDialog } from '@/features/expired';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { convertMillisToMinutes } from '@/utils/date';

import { PLAN_INFO_STATE } from '../hooks/usePlan';
import { usePlanDialog } from '../hooks/usePlanDialog';

import styles from './PlanDialog.module.scss';

/**
 * プランダイアログ プロパティ
 */
type PlanDialog = {
  // ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // ダイアログを閉じる
  onClose: () => void;
};

/**
 * お試しプランダイアログ プロパティ
 */
type FreePlanDialog = {
  // 利用可能残時間
  remainingTime: number;
  // [ライセンス購入]ボタンクリック処理
  onClickPurchaseButton: () => void;
};

/**
 * プラン確認ダイアログ プロパティ
 */
type PurchasedPlanDialog = {
  // シリアル
  serial: string;
  // [アカウント情報]ボタンクリック処理
  onClickAccountButton: () => void;
  // [アカウント情報]ボタンを非表示にするか否か
  isHiddenAccountButton: boolean;
  // ライセンス有効期限
  licenseExpDate: string | undefined;
};

/**
 * お試しプランダイアログ
 */
const FreePlanDialog = React.memo(
  ({
    isOpen,
    onClose,
    remainingTime,
    onClickPurchaseButton,
  }: PlanDialog & FreePlanDialog) => {
    const { t } = useTranslation();

    return (
      <ModalDialog
        isOpen={isOpen}
        isHiddenCloseButton={false}
        onClickClose={onClose}
        title={t('plan.お試しプラン').toString()}
      >
        <div className={styles['plan-container']}>
          <div className={styles['remaining-time']}>
            {t('plan.残り時間{num}分', {
              num: convertMillisToMinutes(remainingTime),
            })}
          </div>

          <div className={styles['trial-plan-notice']}>
            <p>{t('plan.毎月1日にリセットされます。')}</p>
            <p>
              {t(
                'plan.月30分までご利用いただけます。ライセンスを購入するか、コードを入力すると、無制限でご利用いただけます。',
              )}
            </p>
          </div>

          <div className={styles['purchase-license']}>
            <Button
              type="button"
              title={t('plan.ライセンス購入')}
              onClick={onClickPurchaseButton}
            />
          </div>
        </div>
      </ModalDialog>
    );
  },
);

/**
 * サブスク購入済みプランダイアログ
 */
const PurchasedPlanDialog = React.memo(
  ({
    isOpen,
    onClose,
    serial,
    onClickAccountButton,
    isHiddenAccountButton,
    licenseExpDate,
  }: PlanDialog & PurchasedPlanDialog) => {
    const { t } = useTranslation();

    return (
      <ModalDialog
        isOpen={isOpen}
        isHiddenCloseButton={false}
        onClickClose={onClose}
        title={t('plan.プラン').toString()}
      >
        <div className={styles['plan-container']}>
          <div>
            <div className={styles['remaining-time']}>
              {t('plan.サブスクリプション購入済み')}
            </div>
            {licenseExpDate && (
              <div className={styles['license-exp']}>
                {t('plan.有効期限')}
                {licenseExpDate}
              </div>
            )}
          </div>

          <div className={styles['serial-container']}>
            <div className={styles.serial}>
              <TextBlock text={t('plan.シリアルコード')} />
              <FormInput
                type="text"
                id="serial"
                name="serial"
                disabled
                defaultValue={serial}
              />
            </div>

            <div className={styles['account-info']}>
              {!isHiddenAccountButton && (
                <Button
                  type="button"
                  title={t('plan.アカウント情報')}
                  onClick={onClickAccountButton}
                />
              )}
            </div>
          </div>
        </div>
      </ModalDialog>
    );
  },
);

/**
 * プランダイアログ
 * ※利用中のプランによってダイアログ内の内容を出し分ける
 */
export const PlanDialog = React.memo(({ isOpen, onClose }: PlanDialog) => {
  const {
    licenseInfoStatus,
    licenseExpDate,
    onClickPurchaseButton,
    serial,
    onClickAccountButton,
    isHiddenAccountButton,
    needsLoginAgain,
    planInfoResult,
  } = usePlanDialog({ onFailedFun: onClose });
  const { isFreePlan, remainingTime } = useTranslationInfo();

  if (needsLoginAgain) {
    return (
      // 契約状態更新後の自動ログインダイアログを表示
      // 必ず表示するのでisOpenにtrueを直指定
      <ContractRenewalDialog isOpen />
    );
  }

  return (
    <>
      {/* プログレスサークルを表示 */}
      <SpinnerOverlayView isOpen={licenseInfoStatus === API_STATUS.LOADING} />
      {/* ライセンス不正ダイアログを表示 */}
      <LicenseInvalidDialog
        isOpen={planInfoResult === PLAN_INFO_STATE.LICENSE_EXP}
      />
      {/* アクセス期限切れダイアログを表示 */}
      <AccessExpiredDialog
        isOpen={planInfoResult === PLAN_INFO_STATE.ACCESS_EXP}
      />
      {licenseInfoStatus !== API_STATUS.LOADING &&
        planInfoResult === PLAN_INFO_STATE.OK &&
        (isFreePlan ? (
          // お試しプランダイアログを表示
          <FreePlanDialog
            isOpen={isOpen}
            onClose={onClose}
            remainingTime={remainingTime}
            onClickPurchaseButton={onClickPurchaseButton}
          />
        ) : (
          // サブスク購入済みプランダイアログを表示
          <PurchasedPlanDialog
            isOpen={isOpen}
            onClose={onClose}
            serial={serial}
            onClickAccountButton={onClickAccountButton}
            isHiddenAccountButton={isHiddenAccountButton}
            licenseExpDate={licenseExpDate}
          />
        ))}
    </>
  );
});
