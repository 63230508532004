import React from 'react';

import useOpen from '@/hooks/useOpen';

import { GuestViewErrorDialog } from './error/GuestViewErrorDialog';

/**
 * プロパティ
 */
type Props = {
  // ゲスト画面コンポーネント
  guestViewBasicComponent: JSX.Element;
};

/**
 * ゲスト画面エラービュー
 */
export const GuestErrorView = React.memo(
  ({ guestViewBasicComponent }: Props) => {
    const { isOpen, onClose } = useOpen(true);

    return (
      <div>
        {/* 画面表示エラーダイアログを表示 */}
        <GuestViewErrorDialog isOpen={isOpen} onClickCancel={onClose} />

        {guestViewBasicComponent}
      </div>
    );
  },
);
