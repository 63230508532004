/* eslint-disable react/require-default-props */
import React, { ReactElement } from 'react';

import { PullDownMenuItem } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import {
  FONT_SIZE_INFO,
  InterimContent,
  ListDisplayType,
  RecognizedItem,
  TRANSLATION_LIST_VIEW_TYPE,
} from '@/constants';
import { TranslationListView } from '@/features/translationlist';

import { ConferenceViewFooter } from './ConferenceViewFooter';

/**
 * プロパティ
 */
export type Props = {
  // 暫定テキスト
  interimContent: InterimContent;
  // 確定テキスト
  recognizedTexts: RecognizedItem[];
  // メニュー
  menuContent: ReactElement;
  // フォントサイズ
  fontSize: number;
  // フォントサイズが変更された場合に実行したい処理
  onChangeFontSizeFunc: (value: number) => void;
  // 翻訳元テキストの表示/非表示
  displayType: ListDisplayType;
  // 翻訳先言語
  destlang: string;
  // 翻訳先言語リスト
  destlangList: PullDownMenuItem[];
  // 翻訳先言語が変更された時の処理
  onChangeDestlang: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  // 翻訳先言語リストをDisabledにするか否か(true=Disabled, false=Enabled)
  isDisabledDestlangList: boolean;
  // 任意の共有バナーを表示
  shareGuestBanner?: JSX.Element;
};

/**
 * カンファレンス画面(ゲスト画面共通用) コンポーネント
 *
 * @returns
 */
export const ConferenceView = React.memo(
  ({
    interimContent,
    recognizedTexts,
    menuContent,
    fontSize,
    onChangeFontSizeFunc,
    displayType,
    destlang,
    destlangList,
    onChangeDestlang,
    isDisabledDestlangList,
    shareGuestBanner = undefined,
  }: Props) => (
    <ContentLayout
      footerChildren={
        <ConferenceViewFooter
          menuContent={menuContent}
          fontSize={fontSize}
          onChangeFontSizeFunc={onChangeFontSizeFunc}
          destlang={destlang}
          destlangList={destlangList}
          onChangeDestlang={onChangeDestlang}
          isDisabledDestlangList={isDisabledDestlangList}
        />
      }
    >
      {shareGuestBanner}
      <TranslationListView
        interimContent={interimContent}
        recognizedList={recognizedTexts}
        displayType={displayType}
        fontSize={fontSize}
        point={FONT_SIZE_INFO.point}
        translationListViewType={TRANSLATION_LIST_VIEW_TYPE.CONFERENCE}
        isShowGuestBannerBottomHeader={shareGuestBanner !== undefined}
      />
    </ContentLayout>
  ),
);
