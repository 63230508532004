import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  translationDisplaySlice,
  TranslationDisplayState,
} from '@/states/slices/translationDisplaySlice';
import { AppDispatch, RootState, useAppDispatch } from '@/states/store';

/**
 * 翻訳画面の表示に関する情報を保存(Redux用) hooks
 *
 * @returns
 */
export const useTranslationDisplay = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const {
    setIsDisplay,
    setIsOpenSetting,
    setIsScrollingTranslation,
    resetToInitialState,
  } = translationDisplaySlice.actions;

  const { isDisplayFooter, isOpenSetting, isScrollingTranslation } =
    useSelector<RootState, TranslationDisplayState>(
      (state) => state.translationDisplay,
    );

  /**
   * 再ログインが必要か否か(true=必要)
   */
  const [needsLoginAgain, setNeedsLoginAgain] = useState<boolean>(false);

  /**
   * フッターの表示状態を更新
   */
  const changeFooterDisplay = useCallback(
    (value: boolean) => {
      dispatch(setIsDisplay(value));
    },
    [dispatch, setIsDisplay],
  );

  /**
   * 文字サイズスライダー、入力元デバイスドロップダウンが表示されているかどうか
   * (true=表示中)
   */
  const changeIsOpenSetting = useCallback(
    (value: boolean) => {
      dispatch(setIsOpenSetting(value));
    },
    [dispatch, setIsOpenSetting],
  );

  /**
   * 全てのStateをリセット
   */
  const resetState = useCallback(() => {
    dispatch(resetToInitialState());
  }, [dispatch, resetToInitialState]);

  /**
   * 契約状態更新後の再ログインダイアログを表示
   */
  const openLoginAgainDialog = useCallback(() => {
    setNeedsLoginAgain(true);
  }, []);

  /**
   * フッターを表示すべきか(true=表示)
   * 文字サイズスライダー、入力元デバイスドロップダウンが表示されている場合は表示
   */
  const shouldDisplayFooter = useMemo(() => {
    if (isOpenSetting) {
      return true;
    }

    if (isScrollingTranslation) {
      return false;
    }

    return isDisplayFooter;
  }, [isDisplayFooter, isOpenSetting, isScrollingTranslation]);

  /**
   * 翻訳結果をスクロール中かどうか
   * (true=スクロール中)
   */
  const changeIsScrollingTranslation = useCallback(
    (value: boolean) => {
      dispatch(setIsScrollingTranslation(value));
    },
    [dispatch, setIsScrollingTranslation],
  );

  return {
    resetState,
    needsLoginAgain,
    openLoginAgainDialog,
    isDisplayFooter,
    setFooterDisplay: changeFooterDisplay,
    isOpenSetting,
    setIsOpenSetting: changeIsOpenSetting,
    shouldDisplayFooter,
    isScrollingTranslation,
    setIsScrollingTranslation: changeIsScrollingTranslation,
  };
};
