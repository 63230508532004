import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { ListDisplayType } from '@/constants';
import {
  browserTranslationDisplaySlice,
  BrowserTranslationDisplayState,
} from '@/states/slices/browserTranslationDisplaySlice';
import { AppDispatch, RootState, useAppDispatch } from '@/states/store';

/**
 * 翻訳画面の表示に関する情報を保存(ローカルストレージ用) hooks
 *
 * @returns
 */
export const useBrowserTranslationDisplay = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const { setDisplayType, setFontSize, setIsInteractive, resetToInitialState } =
    browserTranslationDisplaySlice.actions;

  const { displayType, fontSize, isInteractive } = useSelector<
    RootState,
    BrowserTranslationDisplayState
  >((state) => state.browserTranslationDisplay);

  /**
   * 翻訳元テキストの表示/非表示を更新
   */
  const changeDisplayType = useCallback(
    (value: ListDisplayType) => {
      dispatch(setDisplayType(value));
    },
    [dispatch, setDisplayType],
  );

  /**
   * 翻訳関連テキストの文字サイズを更新
   */
  const changeFontSize = useCallback(
    (value: number) => {
      dispatch(setFontSize(value));
    },
    [dispatch, setFontSize],
  );

  /**
   * 双方向モードで翻訳するか否かのフラグを更新
   */
  const changeIsInteractive = useCallback(
    (value: boolean) => {
      dispatch(setIsInteractive(value));
    },
    [dispatch, setIsInteractive],
  );

  /**
   * 全てのStateをリセット
   */
  const resetState = useCallback(() => {
    dispatch(resetToInitialState());
  }, [dispatch, resetToInitialState]);

  return {
    displayType,
    fontSize,
    isInteractive,
    setDisplayType: changeDisplayType,
    setFontSize: changeFontSize,
    setIsInteractive: changeIsInteractive,
    resetState,
  };
};
