import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';

import {
  POPUP_CONTENT_ALIGN_POSITION,
  PopupContents,
  PopupDetails,
  PopupSummary,
} from '@/components/Elements';
import { SERIAL_MODE } from '@/features/api';
import { DisplayMenuContainer } from '@/features/display';
import { AccessExpiredDialog } from '@/features/expired';
import { LicenseDialog } from '@/features/license';
import { useLicenseInfoContainer } from '@/features/setting';
import { useBrowserTranslationDisplay } from '@/hooks/useBrowserTranslationDisplay';
import { useBrowserUserInfo } from '@/hooks/useBrowserUserInfo';
import { useKeyDown } from '@/hooks/useKeyDown';
import useOpen from '@/hooks/useOpen';
import { useShareViewInfo } from '@/hooks/useShareViewInfo';
import { SHARE_VIEW_STATUS } from '@/states/slices/shareViewInfoSlice';

import { useTranslationPath } from '../../hooks/useTranslationPath';
import styles from '../Header.module.scss';
import { HistoryDownloadContainer } from '../history/HistoryDownloadContainer';
import { LogoutContainer } from '../logout/LogoutContainer';
import { ManualContainer } from '../manual/ManualContainer';
import { PlanContainer } from '../plan/PlanContainer';
import { SettingMenuContainer } from '../setting/SettingMenuContainer';
import { ShareInfoContainer } from '../share/ShareInfoContainer';
import { ShareStartContainer } from '../share/ShareStartContainer';

import { TtsMenuContainer } from './TtsMenuContainer';

/**
 * ヘッダー > メインメニュー
 */
export const MainMenuContainer = React.memo(() => {
  // メニュークリックでopen要素削除する
  const detailsRef = useRef<HTMLDetailsElement | null>(null);
  const { pathname } = useLocation();
  const { isTranslationPath } = useTranslationPath();
  const { shouldLicenseDialog, onClickLicenseMenu, onCloseLicenseDialog } =
    useLicenseInfoContainer();
  const { isOpen: shouldAccessExpDialog, onOpen: onOpenAccessExpDialog } =
    useOpen();
  const { shareViewStatus, isShared } = useShareViewInfo();
  const { displayType, setDisplayType } = useBrowserTranslationDisplay();
  const { isConferenceMode } = useBrowserUserInfo();

  /**
   * クローズ処理
   */
  const onClose = (): void => {
    detailsRef.current?.removeAttribute('open');
  };
  // Escキーが押下されたらメニューを閉じる
  useKeyDown({ onEscapeKeyDown: onClose });

  // ライセンス情報ダイアログを表示
  if (shouldLicenseDialog) {
    return (
      // shouldLicenseDialogでレンダリング制御しているため、常にtrue
      <LicenseDialog isOpen onClose={onCloseLicenseDialog} />
    );
  }

  // アクセス期限切れダイアログを表示
  if (shouldAccessExpDialog) {
    // shouldAccessExpDialogでレンダリング制御しているため、常にtrue
    return <AccessExpiredDialog isOpen />;
  }

  return (
    <div>
      <PopupDetails detailsRef={detailsRef}>
        <PopupSummary>
          <div className={styles.menu}>
            <div className={styles['main-menu']} data-testid="main-menu" />
          </div>
        </PopupSummary>
        <PopupContents align={POPUP_CONTENT_ALIGN_POSITION.RIGHT}>
          <div className={styles['menu-contents']}>
            <ul>
              {/* 一般メニュー */}
              <li>
                <SettingMenuContainer
                  onCloseMenu={() => {
                    detailsRef.current?.removeAttribute('open');
                  }}
                  onOpenLicenseDialog={onClickLicenseMenu}
                  onOpenAccessExpDialog={onOpenAccessExpDialog}
                />
              </li>
              {isTranslationPath(pathname) ? (
                <>
                  <li>
                    {/* 翻訳元テキストの表示/非表示切り替えメニュー(翻訳画面でのみ表示) */}
                    <DisplayMenuContainer
                      onCloseMenu={onClose}
                      currentDisplayType={displayType}
                      onChangeDisplayTypeFunc={setDisplayType}
                      gtmClassTag="dts-transswitch"
                    />
                  </li>
                  <li>
                    {/* 翻訳音声を再生/翻訳音声を停止メニュー(翻訳画面でのみ表示) */}
                    <TtsMenuContainer
                      onCloseMenu={() => {
                        detailsRef.current?.removeAttribute('open');
                      }}
                    />
                  </li>
                </>
              ) : (
                ''
              )}
              {/* 通訳履歴をダウンロードメニュー */}
              <li>
                <HistoryDownloadContainer
                  onCloseMenu={() => {
                    detailsRef.current?.removeAttribute('open');
                  }}
                />
              </li>
              {/* 共有メニュー */}
              {isTranslationPath(pathname) && isConferenceMode && (
                <>
                  <li
                    className={
                      isShared(shareViewStatus)
                        ? styles['menu-display-none']
                        : ''
                    }
                  >
                    {/* 共有を開始メニュー */}
                    <ShareStartContainer
                      onCloseMenu={() => {
                        onClose();
                      }}
                    />
                  </li>
                  <li
                    className={
                      shareViewStatus === SHARE_VIEW_STATUS.NONE
                        ? styles['menu-display-none']
                        : ''
                    }
                  >
                    {/* 共有URLを表示/終了メニュー */}
                    <ShareInfoContainer
                      onCloseMenu={() => {
                        onClose();
                      }}
                    />
                  </li>
                </>
              )}
              {/* プランメニュー */}
              <li>
                <PlanContainer
                  onCloseMenu={() => {
                    detailsRef.current?.removeAttribute('open');
                  }}
                />
              </li>
              {/* マニュアルメニュー */}
              <li>
                <ManualContainer onCloseMenu={onClose} />
              </li>
              <li>
                {/* ログアウト */}
                <LogoutContainer
                  onCloseMenu={() => {
                    detailsRef.current?.removeAttribute('open');
                  }}
                />
              </li>
            </ul>
          </div>
        </PopupContents>
      </PopupDetails>
    </div>
  );
});
