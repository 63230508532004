import { useCallback, useState } from 'react';

import { COOKIE_KEY_NAME, COOKIE_USER_TYPE } from '@/constants';
import { useLogout } from '@/features/logout';
import { removeBeforeunloadEvent } from '@/utils';
import { findCookieValue } from '@/utils/cookie';

/**
 * ヘッダー > メインメニュー > ログアウトメニュー用 hooks
 *
 * @returns
 */
export const useLogoutContainer = () => {
  const { doLogoutForPTID, logoutRedirectStatus } = useLogout({
    onSuccessFun: removeBeforeunloadEvent, // beforeunloadイベントによるwindows.alert削除
  });
  /**
   * ログアウト確認ダイアログを表示するか否か(true=表示)
   */
  const [shouldOpenLogoutDialog, setShouldOpenLogoutDialog] =
    useState<boolean>(false);

  /**
   * [ログアウト]メニューをクリックした場合の処理
   */
  const onClickLogoutMenu = useCallback(
    (closeMenu: () => void) => {
      // メニュー閉じる
      closeMenu();

      const userType = findCookieValue(COOKIE_KEY_NAME.PTBR_TYPE);
      if (userType === COOKIE_USER_TYPE.SERIAL) {
        // シリアルで利用中はログアウト確認ダイアログを表示
        setShouldOpenLogoutDialog(true);
      } else {
        // PTIDで利用中はログアウト確認ダイアログを表示せずにログアウト実行
        doLogoutForPTID();
      }
    },
    [doLogoutForPTID],
  );

  return {
    // ログアウト確認ダイアログを表示するか否か(true=表示)
    shouldOpenLogoutDialog,
    // [ログアウト]メニューをクリックした場合の処理
    onClickLogoutMenu,
    // ログアウトAPI(リダイレクト用)呼び出し状況
    logoutRedirectStatus,
  };
};
