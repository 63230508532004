// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */
import React from 'react';
import { useTranslation } from 'react-i18next';

import screenShare from '@/assets/screen_share.svg';

/**
 * プロパティ
 */
type Props = {
  // メニューを閉じる処理
  onCloseMenu: () => void;
  // 表示メニューがクリックされた場合の処理
  onClickOpenMenu: () => void;
  // メニューをDisabledで表示するかどうか(true=Disabledで表示する)(default=false)
  isMenuDisabled?: boolean;
};

/**
 * ゲスト画面 共有URLを表示メニュー コンポーネント
 */
export const GuestShareDialogContainer = React.memo(
  ({ onCloseMenu, onClickOpenMenu, isMenuDisabled = false }: Props) => {
    const { t } = useTranslation();

    return (
      <button
        type="button"
        onClick={() => {
          onCloseMenu();
          onClickOpenMenu();
        }}
        disabled={isMenuDisabled}
        className="guest-06"
        data-testid="share-dialog"
      >
        <img src={screenShare} alt="screenShare" />
        {t('shareGuest.共有URLを表示')}
      </button>
    );
  },
);
