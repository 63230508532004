import { doc, onSnapshot, Unsubscribe } from 'firebase/firestore';
import { useRef, useCallback, useEffect } from 'react';

import {
  DocErrorFunction,
  FIRESTORE_PATH,
  ViewConfigData,
} from '@/constants/firestore';
import { firestore } from '@/utils/firebases/firebaseFirestore';

/**
 * 対象ドキュメントの変更を検知したら実行したい関数の型
 */
export type ViewConfigChangesFunction = (
  viewConfigData: ViewConfigData,
) => void;

/**
 * プロパティ
 */
type Props = {
  // 監視対象のFirestoreのドキュメントID
  documentId: string;
  // 確定テキストの変更結果を受け取る
  onDocChanges: ViewConfigChangesFunction;
  // firestoreへの接続失敗・切断されたことを受け取る
  onDocError: DocErrorFunction;
};

/**
 * Firestore 共有画面設定 監視 hooks
 *
 * @param documentId 監視対象のFirestoreのドキュメントID
 * @param onResult 共有画面設定の変更結果を受け取る
 * @param onError firestoreへの接続失敗・切断されたことを受け取る
 * @returns
 */
export const useViewConfigCollection = ({
  documentId,
  onDocChanges,
  onDocError,
}: Props) => {
  const unsubscribeRef = useRef<Unsubscribe | undefined>(undefined);

  /**
   * onDocChangesをRefで管理
   * ※Refで保持することでキャプチャされても関数内の値が最新のものになる
   */
  const onResultFuncRef = useRef<ViewConfigChangesFunction>(onDocChanges);
  useEffect(() => {
    onResultFuncRef.current = onDocChanges;
  }, [onDocChanges]);

  /**
   * onDocErrorをRefで管理
   * ※Refで保持することでキャプチャされても関数内の値が最新のものになる
   */
  const onDocErrorRef = useRef<DocErrorFunction>(onDocError);
  useEffect(() => {
    onDocErrorRef.current = onDocError;
  }, [onDocError]);

  /**
   * Firestoreの「共有画面設定」を監視する
   *
   * @param documentId 監視対象のFirestoreのドキュメントID
   * @param onResult 共有画面設定の変更結果を受け取る
   * @param onError firestoreへの接続失敗・切断されたことを受け取る
   */
  const subscribeViewConfig = useCallback(() => {
    // すでに監視済の場合はスキップ
    if (unsubscribeRef.current) {
      return;
    }

    const docRef = doc(
      firestore,
      FIRESTORE_PATH.COLLECTION.ITEMS,
      documentId,
      FIRESTORE_PATH.COLLECTION.VIEW,
      FIRESTORE_PATH.DOCUMENT.CONFIG,
    );

    // 監視開始
    unsubscribeRef.current = onSnapshot(
      docRef,
      async (docSnap) => {
        const docData = docSnap.data();
        if (!docData) {
          return;
        }
        const json = JSON.stringify(docData);
        const viewConfigData: ViewConfigData = JSON.parse(json);
        if (!viewConfigData) {
          return;
        }
        onResultFuncRef.current?.(viewConfigData);
      },
      async (e) => {
        if (documentId && e.code === 'permission-denied') {
          return; // 監視成功後にdocumentIdがある(Firestoreにログイン済)&&権限エラーになった場合はスキップ
        }
        onDocErrorRef.current?.();
      },
    );
  }, [documentId]);

  /**
   * Firestoreのデータ監視をデタッチする
   */
  const unsubscribeViewConfig = useCallback(
    () => unsubscribeRef.current?.(),
    [],
  );

  /**
   * マウント/アンマウント
   */
  useEffect(() => {
    // マウント時に監視を開始
    subscribeViewConfig();

    return () => {
      // アンマウント時に解放
      unsubscribeViewConfig();
    };

    // マウント時の1度だけ実行したいので無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    // Firestoreの「共有画面設定」を監視する
    subscribeViewConfig,
  };
};
