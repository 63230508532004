import { useTranslation } from 'react-i18next';

import { ALIGN_TYPE, Button, TextBlock } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';

import { useSelectUsage } from '../hooks/useSelectUsage';

import styles from './SelectUsage.module.scss';

/**
 * 利用方法選択画面 子コンポーネント
 *
 * @returns
 */
export const SelectUsageView = () => {
  const { onClickLogin, onClickSerial } = useSelectUsage();
  const { t } = useTranslation();

  return (
    <div className={styles.selectusage}>
      <div className={styles['selectusage-title']}>
        <TextBlock
          text={t('selectusage.ログイン方法を選択')}
          align={ALIGN_TYPE.center}
        />
      </div>
      <div className={styles['selectusage-contents']}>
        <Button
          title={t('selectusage.ポケトークIDでログイン')}
          type="button"
          onClick={onClickLogin}
        />
        <Button
          title={t('selectusage.シリアルコードを入力する')}
          type="button"
          onClick={onClickSerial}
        />
      </div>
    </div>
  );
};

/**
 * 利用方法選択画面
 *
 * @returns
 */
export const SelectUsage = () => (
  <ContentLayout>
    <SelectUsageView />
  </ContentLayout>
);
