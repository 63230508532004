import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TOAST_ICON_TYPE } from '@/components/Elements';
import {
  API_STATUS,
  ApiStatus,
  COOKIE_KEY_NAME,
  COOKIE_USER_TYPE,
} from '@/constants';
import {
  FREE_LICENSE_KEY,
  LICENSE_INFO_API_RESULT_CODE,
  LICENSE_INVALID_REMAINING_TIME,
  LicenseInfoApiResponse,
  LicenseInfoResultCode,
  useLicenseInfoApi,
} from '@/features/api';
import { useNeedAgreement, usePtidExpired } from '@/features/expired';
import { useToastInfo } from '@/hooks/useToastInfo';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { STT_STATUS } from '@/states/slices/translationInfoSlice';
import { findCookieValue } from '@/utils/cookie';

/**
 * プラン状態コード
 */
export const PLAN_INFO_STATE = {
  // OK
  OK: 'OK',
  // アクセス期限切れ
  ACCESS_EXP: 'ACCESS_EXP',
  // ライセンス期限切れ
  LICENSE_EXP: 'LICENSE_EXP',
  // その他エラー
  ERROR: 'ERROR',
};
/**
 * プラン状態
 */
export type PlanInfoState =
  (typeof PLAN_INFO_STATE)[keyof typeof PLAN_INFO_STATE];

/**
 * 本カスタムフックからの返却値
 */
export type UsePlanValue = {
  // ライセンス確認API呼び出し状況
  licenseInfoStatus: ApiStatus;
  // ライセンス確認APIから返却された結果コード
  licenseInfoResultCode: LicenseInfoResultCode | undefined;
  // ライセンス確認APIから返却されたライセンスの有効期限
  licenseExp: string | undefined;
  // シリアル(ライセンス確認APIから返却された値)
  serial: string;
  // プラン確認結果
  planInfoResult: PlanInfoState;
};

/**
 * プラン確認処理 hooks
 *
 * @returns
 */
export const usePlan = (): UsePlanValue => {
  const { setLicenseStr, setRemainingTime, remainingTime, sttStatus } =
    useTranslationInfo();
  const { licenseInfoState, fetchLicenseInfo } = useLicenseInfoApi();
  const { addToastMessage } = useToastInfo();
  const { ptidExpired } = usePtidExpired();
  const { needAgreement } = useNeedAgreement();

  const { t } = useTranslation();

  /**
   * プラン確認結果
   */
  const [planInfoResult, setPlanInfoResult] = useState<PlanInfoState>('');

  /**
   * ライセンス確認APIから返却されたシリアル
   */
  const serial = useMemo((): string => {
    if (licenseInfoState.data) {
      return licenseInfoState.data.serial;
    }

    return '';
  }, [licenseInfoState.data]);

  /**
   * マウント時の処理
   */
  useEffect(() => {
    // ライセンス確認API呼び出し
    fetchLicenseInfo();

    // コンポーネントのマウント時に一度だけ実行
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * エラー処理準備
   */
  const setupError = useCallback(() => {
    // アクセス期限期限切れ
    if (
      licenseInfoState.data?.resultCode ===
      LICENSE_INFO_API_RESULT_CODE.WARN_AUTH
    ) {
      setPlanInfoResult(PLAN_INFO_STATE.ACCESS_EXP);

      return;
    }
    // ライセンス期限切れ
    if (
      licenseInfoState.data?.resultCode ===
      LICENSE_INFO_API_RESULT_CODE.INFO_EXPIRED_LICENSE
    ) {
      setPlanInfoResult(PLAN_INFO_STATE.LICENSE_EXP);

      return;
    }

    // PTID期限切れ処理実行
    if (
      licenseInfoState.data?.resultCode ===
      LICENSE_INFO_API_RESULT_CODE.INFO_EXPIRED_IDTOKEN
    ) {
      ptidExpired();

      return;
    }

    // INFO_NEED_AGREEMENTの場合、利用規約画面に遷移
    if (
      licenseInfoState.data?.resultCode ===
      LICENSE_INFO_API_RESULT_CODE.INFO_NEED_AGREEMENT
    ) {
      needAgreement();

      return;
    }

    // お試しプランで利用中の場合のみ、INFO_INVALID_LICENSEエラーはOK扱いとする
    // (お試しプランの場合はライセンストークン発行APIを呼ばないとINFO_INVALID_LICENSEになるため)
    if (
      licenseInfoState.data?.resultCode ===
        LICENSE_INFO_API_RESULT_CODE.INFO_INVALID_LICENSE &&
      findCookieValue(COOKIE_KEY_NAME.PTBR_TYPE) === COOKIE_USER_TYPE.FREE
    ) {
      setLicenseStr(FREE_LICENSE_KEY);
      setRemainingTime(LICENSE_INVALID_REMAINING_TIME); // APIからは0が返ってくるので30分固定で表示
      setPlanInfoResult(PLAN_INFO_STATE.OK);

      return;
    }

    // ライセンス確認失敗エラーメッセージを表示
    setPlanInfoResult(PLAN_INFO_STATE.ERROR);
    addToastMessage(
      t('plan.プラン情報の取得に失敗しました。'),
      TOAST_ICON_TYPE.WARNING,
    );
  }, [
    addToastMessage,
    licenseInfoState.data?.resultCode,
    needAgreement,
    ptidExpired,
    setLicenseStr,
    setRemainingTime,
    t,
  ]);

  /**
   * プランダイアログ表示準備
   */
  const setupPlanDialog = useCallback(
    (response: LicenseInfoApiResponse | undefined): void => {
      // APIから返却されたライセンストークンと利用残時間をReduxに保存
      const licenseStr: string = response ? response.licenseStr : '';
      const timeMillis: number = response ? response.remainingTimeMillis : -1;
      setLicenseStr(licenseStr);
      setPlanInfoResult(PLAN_INFO_STATE.OK);
      if (sttStatus === STT_STATUS.CONNECTING && remainingTime >= 0) {
        return; // STT開始中でかつすでにバナー用の残時間を取得済の場合は何もしない
      }
      if (timeMillis >= 0) {
        setRemainingTime(timeMillis); // APIから取得した残時間で更新
      }
    },
    [remainingTime, setLicenseStr, setRemainingTime, sttStatus],
  );

  /**
   * ライセンス情報確認APIリクエスト完了
   */
  useEffect(() => {
    // 失敗時の処理
    if (licenseInfoState.status === API_STATUS.FAILED) {
      setupError();

      return;
    }

    // 成功時の処理
    if (licenseInfoState.status === API_STATUS.SUCCESS) {
      setupPlanDialog(licenseInfoState.data);
    }
  }, [
    licenseInfoState.data,
    licenseInfoState.status,
    setupError,
    setupPlanDialog,
  ]);

  return {
    licenseInfoStatus: licenseInfoState.status,
    licenseInfoResultCode: licenseInfoState.data?.resultCode,
    licenseExp: licenseInfoState.data?.licenseExp,
    serial,
    planInfoResult,
  };
};
