import { Reducer, useCallback, useReducer } from 'react';

import { ApiStatus, API_STATUS } from '@/constants';

import {
  guestUpdateApi,
  GuestUpdateApiResponse,
  isGuestUpdateApiResultCode,
  GUEST_UPDATE_API_RESULT_CODE,
  GuestUpdateApiRequest,
} from '../guestUpdateApi';

/**
 * State
 */
export type guestUpdateState = {
  // レスポンスデータ
  data?: GuestUpdateApiResponse;
  // APIの呼び出し状態
  status: ApiStatus;
};

/**
 * Actionタイプ
 */
const GUEST_UPDATE_ACTION_TYPE = {
  SET_GUEST_UPDATE: 'SET_GUEST_UPDATE',
  SET_GUEST_UPDATE_SUCCESS: 'SET_GUEST_UPDATE_SUCCESS',
  SET_GUEST_UPDATE_FAILED: 'SET_GUEST_UPDATE_FAILED',
} as const;

/**
 * Action
 */
type guestUpdateAction = {
  data?: GuestUpdateApiResponse;
  type: keyof typeof GUEST_UPDATE_ACTION_TYPE;
};

/**
 * reducer関数
 *
 * @param state
 * @param action
 * @returns
 */
const reducer: Reducer<guestUpdateState, guestUpdateAction> = (
  state: guestUpdateState,
  action: guestUpdateAction,
) => {
  switch (action.type) {
    case GUEST_UPDATE_ACTION_TYPE.SET_GUEST_UPDATE:
      return {
        ...state,
        status: API_STATUS.LOADING,
      };

    case GUEST_UPDATE_ACTION_TYPE.SET_GUEST_UPDATE_SUCCESS:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.SUCCESS,
      };

    case GUEST_UPDATE_ACTION_TYPE.SET_GUEST_UPDATE_FAILED:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.FAILED,
      };
    default:
      return state;
  }
};

/**
 * ゲスト情報設定API 呼び出しhooks
 *
 * @returns
 */
export const useGuestUpdateApi = () => {
  const [state, dispatch] = useReducer(reducer, {
    data: undefined,
    status: API_STATUS.IDLE,
  });

  /**
   * API呼び出し
   */
  const updateGuest = useCallback(
    (request: GuestUpdateApiRequest) => {
      if (state.status === API_STATUS.LOADING) {
        return;
      }

      dispatch({ type: GUEST_UPDATE_ACTION_TYPE.SET_GUEST_UPDATE });

      guestUpdateApi(request)
        .then((response: GuestUpdateApiResponse) => {
          // 成功
          if (response.resultCode === GUEST_UPDATE_API_RESULT_CODE.OK) {
            dispatch({
              type: GUEST_UPDATE_ACTION_TYPE.SET_GUEST_UPDATE_SUCCESS,
              data: response,
            });

            return;
          }

          // 意図しない結果コードの型だった場合は失敗とする
          if (!isGuestUpdateApiResultCode(response.resultCode)) {
            dispatch({
              type: GUEST_UPDATE_ACTION_TYPE.SET_GUEST_UPDATE_FAILED,
              data: {
                ...response,
                resultCode: GUEST_UPDATE_API_RESULT_CODE.ERR_UNKNOWN,
              } as GuestUpdateApiResponse,
            });

            return;
          }

          // 失敗
          dispatch({
            type: GUEST_UPDATE_ACTION_TYPE.SET_GUEST_UPDATE_FAILED,
            data: response,
          });
        })
        .catch((_) => {
          dispatch({
            type: GUEST_UPDATE_ACTION_TYPE.SET_GUEST_UPDATE_FAILED,
            data: {
              resultCode: GUEST_UPDATE_API_RESULT_CODE.ERR_UNKNOWN,
            } as GuestUpdateApiResponse,
          });
        });
    },
    [state.status],
  );

  return {
    guestUpdateState: state,
    updateGuest,
  };
};
