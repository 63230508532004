import { useCallback, useEffect, useRef } from 'react';
/**
 * 5分間の無音監視時間(ミリ秒)
 */
const SILENCE_TIME = 300000;

/**
 * 時間が経過したら実行したい関数の型
 */
export type SilenceTimeOverFunction = () => void;

/**
 * 5分無音監視タイマー カスタムフック
 *
 * ※
 * 無音状態かは音声ストリームAPIからのレスポンスの有無で判断。
 * このカスタムフック内で音声ストリームAPIからのレスポンスを確認できないため、
 * 使う側で音声ストリームAPIのレスポンスを監視しsetTimeoutを再登録する。
 *
 * @param param0
 * @returns
 */
export const useSilenceTimer = () => {
  const timeOverCallbackIdsRef = useRef<NodeJS.Timeout | undefined>(undefined);

  /**
   * setTimeoutを登録して時間が経過したらプロパティに指定された関数を実行
   */
  const observe = useCallback((onSilenceFun?: SilenceTimeOverFunction) => {
    const timerId: NodeJS.Timeout = setTimeout(() => {
      if (onSilenceFun) {
        onSilenceFun();
      }
    }, SILENCE_TIME);
    timeOverCallbackIdsRef.current = timerId;
  }, []);

  /**
   * 登録済のsetTimeoutを削除
   */
  const disconnect = useCallback(() => {
    // setTimeoutをキャンセル
    clearTimeout(timeOverCallbackIdsRef.current);
    timeOverCallbackIdsRef.current = undefined;
  }, []);

  /**
   * 登録済のsetTimeoutを削除して
   * 新しくsetTimeoutを登録する
   */
  const reRegisterObserve = useCallback(
    (onSilenceFun?: SilenceTimeOverFunction) => {
      // 登録済の監視削除
      disconnect();
      // 新規監視
      observe(onSilenceFun);
    },
    [disconnect, observe],
  );

  /**
   * アンマウント時の処理
   */
  useEffect(
    () => () => {
      // 監視削除
      disconnect();
    },

    // アンマウント時の一度のみ実行したいので無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    // setTimeoutを削除
    disconnectSilence: disconnect,
    // setTimeoutを再登録
    reObserveSilence: reRegisterObserve,
  };
};
