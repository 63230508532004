import { useWelcome } from '../hooks/useWelcome';

import styles from './Welcome.module.scss';

/**
 * ようこそ画面コンポーネント
 *
 * ※ログイン認証を実行するためだけの画面。グレーの画面を表示するのみ。ユーザ操作は一切できない。
 */
export const Welcome = () => {
  useWelcome();

  return (
    <div className={styles.welcome}>
      <p />
    </div>
  );
};
