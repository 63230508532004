import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ShareGuestBanner.module.scss';

/**
 * プロパティ
 */
type Props = {
  // バナーに表示するメッセージ
  bannerMessage: string;
};

/**
 * 共有用ゲスト画面バナー コンポーネント
 */
export const ShareGuestBanner = React.memo(({ bannerMessage }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles['share-guest-banner']}>
      <div className={styles['share-guest-banner-container']}>
        <p>{t(`shareGuest.${bannerMessage}`)}</p>
      </div>
    </div>
  );
});
