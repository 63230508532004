// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */
import styles from './LinkText.module.scss';

/**
 * プロパティ
 */
export type LinkText = {
  // リンク名(必須)
  title: string;
  // リンク先(必須)
  href: string;
  // 別タブで開くか否か(true=別タブで開く)
  isNewTab: boolean;
  // リンクにOPENアイコンを表示するか否か(default=表示しない)
  isShowOpenIcon?: boolean;
  // リンクに付与するGTM用クラスタグ
  gtmClassTag?: string;
};

/**
 * リンク コンポーネント
 *
 * @param param0
 * @returns
 */
export const LinkText = ({
  title,
  href,
  isNewTab = false,
  isShowOpenIcon = false,
  gtmClassTag = '',
}: LinkText) => (
  <a
    className={`${isShowOpenIcon ? styles['open-link'] : ''} ${gtmClassTag}`}
    data-testid="link"
    target={isNewTab ? '_blank' : '_self'}
    href={href}
    rel={isNewTab ? 'noopener noreferrer' : ''}
  >
    <span data-testid="link-title">{title}</span>
  </a>
);
