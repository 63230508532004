import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  DIALOG_BUTTON_TYPE,
  DialogMessageContents,
  ModalDialog,
} from '@/components/Elements';
import { reloadWindow } from '@/utils/reload';
/**
 * プロパティ
 */
type Props = {
  // ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // キャンセルボタン押下時の処理
  onClickCancel: () => void;
};

/**
 * ゲスト画面表示エラーダイアログ コンポーネント
 */
export const GuestViewErrorDialog = React.memo(
  ({ isOpen, onClickCancel }: Props) => {
    const { t } = useTranslation();

    return (
      <ModalDialog
        isOpen={isOpen}
        title={t(
          'guestViewErrorDialog.エラーが発生したため表示できません',
        ).toString()}
        buttons={[
          {
            label: t('guestViewErrorDialog.キャンセル'),
            onClick: onClickCancel,
            type: DIALOG_BUTTON_TYPE.CHANCEL,
            gtmClassTag: 'dialog-11',
          },
          {
            label: t('guestViewErrorDialog.再読み込み'),
            onClick: reloadWindow,
            gtmClassTag: 'dialog-10',
          },
        ]}
      >
        <DialogMessageContents>
          {t('guestViewErrorDialog.画面を読み込み直してください。')}
        </DialogMessageContents>
      </ModalDialog>
    );
  },
);
