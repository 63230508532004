/**
 * 暫定/確定TTTテキストを表示できるか判定
 *
 * @param selectedSrclang 選択中の翻訳元言語
 * @param selectedDestlang 選択中の翻訳先言語
 * @param apiSrclang 音声ストリームAPIから返却された翻訳元言語
 * @param apiDestlang 音声ストリームAPIから返却された翻訳先言語
 * @returns true=表示可能, false=表示不可
 */
export const canDisplayTranslationText = (
  selectedSrclang: string,
  selectedDestlang: string,
  apiSrclang: string,
  apiDestlang: string,
): boolean => {
  // 選択中のsrclang/destlangと、音声ストリームAPIから返却されたsrclang/destlangが一致
  if (selectedSrclang === apiSrclang && selectedDestlang === apiDestlang) {
    return true;
  }

  // 選択中のsrclang/destlangと、音声ストリームAPIから返却されたsrclang/destlangが反転
  if (selectedSrclang === apiDestlang && selectedDestlang === apiSrclang) {
    return true;
  }

  return false;
};
