/**
 * X-Optionsで指定可能な値
 */
export const X_OPTION_VALUE = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
} as const;
export type XOptionValue = (typeof X_OPTION_VALUE)[keyof typeof X_OPTION_VALUE];

/**
 * リクエストヘッダ
 */
export type StreamRequestHeader = {
  accessKey: string;
  pushMode?: boolean;
  autoDetect?: boolean;
  licenseToken: string;
  noiseSuppression: XOptionValue; // ノイズキャンセリング
  interimResults: boolean; // ストリーミング翻訳するか否か
};

/**
 * 結果コード
 */
export const STREAM_API_RESULT_CODE = {
  // 成功
  OK: 'OK',
  // ストリームIDが存在しない
  WARN_NO_ITEM: 'WARN_NO_ITEM',
  // データが不正
  WARN_INVALID_DATA: 'WARN_INVALID_DATA',
  // ストリーム失敗エラー
  ERR_STREAM_FAILED: 'ERR_STREAM_FAILED',
  // 不明なエラー
  ERR_UNKNOWN: 'ERR_UNKNOWN',
} as const;
export type StreamApiResultCode =
  (typeof STREAM_API_RESULT_CODE)[keyof typeof STREAM_API_RESULT_CODE];

/**
 * 切断時の処理結果コード
 */
export const CLOSE_RESULT_CODE = {
  // 利用時間上限超過
  INFO_LIMIT_EXCEEDED: 'INFO_LIMIT_EXCEEDED',
  // 接続数管理による切断
  INFO_CONNECTION_SHIFTED: 'INFO_CONNECTION_SHIFTED',
  // 言語設定失敗
  INFO_FAILED_LANG: 'INFO_FAILED_LANG',
  // 共有画面有効無効の切り替え
  INFO_SWITCH_BROWSABLE: 'INFO_SWITCH_BROWSABLE',
};
export type CloseResultCode =
  (typeof CLOSE_RESULT_CODE)[keyof typeof CLOSE_RESULT_CODE];

/**
 * レスポンス
 */
export type StreamApiResponse = {
  // 音声認識テキスト(STT)
  stt: string;
  // 翻訳テキスト(TTT)
  ttt: string;
  // 音声認識テキストのPocketalkの言語ID
  srclang: string;
  // 翻訳言語テキストのPocketalkの言語ID
  destlang: string;
  // 確定フラグ(音声認識が完了し、文章が確定したかどうかのフラグ)
  isFinal: boolean;
  // 結果コード
  resultCode: StreamApiResultCode;
};

/**
 * 切断時のレスポンス
 */
export type CloseResponse = {
  // 結果コード
  result_code: CloseResultCode;
};

/**
 * 結果コードのTypeGuard
 *
 * @param resultCode
 * @returns
 */
export const isStreamApiResultCode = (
  resultCode: string,
): resultCode is StreamApiResultCode => {
  if (resultCode in STREAM_API_RESULT_CODE) {
    return true;
  }

  return false;
};

/**
 * 切断時の処理結果コードのTypeGuard
 *
 * @param resultCode
 * @returns
 */
export const isCloseResultCode = (
  resultCode: string,
): resultCode is CloseResultCode => {
  if (resultCode in CLOSE_RESULT_CODE) {
    return true;
  }

  return false;
};
