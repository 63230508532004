import { useCallback, useEffect } from 'react';

import { ViewConfigData } from '@/constants/firestore';
import { useShareViewInfo } from '@/hooks/useShareViewInfo';
import { SHARE_VIEW_STATUS } from '@/states/slices/shareViewInfoSlice';

/**
 * 共有中の翻訳画面ビュー用 カスタムフック
 */
export const useTranslationShareView = () => {
  const { setShareViewStatus, setIsOpenShareInvalidDialog, resetState } =
    useShareViewInfo();

  /**
   * Firestoreの「画面表示設定(view > config)」の変更を検知した時の処理
   */
  const onChangeViewConfig = useCallback(
    (viewConfigData: ViewConfigData) => {
      if (viewConfigData.browsable) {
        return; // 監視中はすでに共有中なので何もしない
      }
      // 共有終了ダイアログを表示
      setIsOpenShareInvalidDialog(true);
    },
    [setIsOpenShareInvalidDialog],
  );

  /**
   * Firestoreへの接続に失敗か
   * 接続が切れた
   */
  const firestoreConnectionError = useCallback(() => {
    // 共有情報取得失敗
    setShareViewStatus(SHARE_VIEW_STATUS.SHARE_INFO_ERROR);
  }, [setShareViewStatus]);

  /**
   * マウント/アンマウント時の処理
   */
  useEffect(
    () => () => {
      // 共有情報リセット
      resetState();
    },

    // コンポーネントのマウント/アンマウント時に一度だけ実行したいので無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    // Firestoreの「画面表示設定(view > config)」の変更を検知した時の処理
    onChangeViewConfig,
    // firestoreへの接続に失敗か接続が切れた時の処理
    firestoreConnectionError,
  };
};
