import { useCallback, useEffect, useRef } from 'react';

/**
 * 無音監視時間(ミリ秒)
 */
const SILENCE_TIME = 10000 as const;

/**
 * 時間が経過したら実行したい関数の型
 */
export type NoSoundTimeOverFunction = () => void;

/**
 * 1回だけ実行させる無音監視タイマー カスタムフック
 *
 * @param param0
 * @returns
 */
export const useNoSoundTimer = () => {
  // タイマーID
  const timeOverCallbackIdsRef = useRef<NodeJS.Timeout | undefined>(undefined);
  // 実行済フラグ
  const isExecutedFun = useRef<boolean>(false);

  /**
   * タイマーを登録して時間が経過したらプロパティに指定された関数を実行
   */
  const observe = useCallback((onNoSoundFun?: NoSoundTimeOverFunction) => {
    // すでに時間経過の処理を実行済みの場合とタイマーがセットされている場合は何もしない。
    if (isExecutedFun.current || timeOverCallbackIdsRef.current) {
      return;
    }

    const timerId: NodeJS.Timeout = setTimeout(() => {
      if (onNoSoundFun) {
        onNoSoundFun();
      }
      isExecutedFun.current = true;
    }, SILENCE_TIME);
    timeOverCallbackIdsRef.current = timerId;
  }, []);

  /**
   * 登録済のタイマーを削除
   */
  const disconnect = useCallback(() => {
    if (!timeOverCallbackIdsRef.current) {
      return;
    }

    clearTimeout(timeOverCallbackIdsRef.current);
    timeOverCallbackIdsRef.current = undefined;
  }, []);

  /**
   * アンマウント時の処理
   */
  useEffect(
    () => () => {
      // タイマー削除
      disconnect();
    },

    // アンマウント時の1度のみ実行したいため無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    // setTimeoutを登録
    observeNoSound: observe,
    // setTimeoutを削除
    disconnectNoSound: disconnect,
  };
};
