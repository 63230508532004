import { useRef } from 'react';

/**
 * WakeLock API が利用可能かチェック
 *
 * @returns
 */
export const isWakeLockSupported = (): boolean => 'wakeLock' in navigator;

/**
 * 画面の消灯、暗転、ロック用 hooks
 *
 * @returns
 */
export const useWakeLock = () => {
  const wakeLockRef = useRef<WakeLockSentinel | null>(null);

  /**
   * 画面の消灯、暗転、ロックを制御する
   */
  const addWakeLock = () => {
    if (isWakeLockSupported()) {
      navigator.wakeLock.request('screen').then((object: WakeLockSentinel) => {
        wakeLockRef.current = object;
      });
    }

    // 復帰時の処理
    document.addEventListener('visibilitychange', async () => {
      if (
        navigator.wakeLock !== null &&
        document.visibilityState === 'visible'
      ) {
        wakeLockRef.current = await navigator.wakeLock.request('screen');
      }
    });
  };

  /**
   * 画面の消灯、暗転、ロックの制御を解放する
   */
  const removeWakeLock = () => {
    if (wakeLockRef.current) {
      wakeLockRef.current.release().then(() => {
        wakeLockRef.current = null;
      });
    }
  };

  return {
    addWakeLock,
    removeWakeLock,
  };
};
