import { useCallback } from 'react';

import {
  LOCALE_LANG,
  PURCHASE_LINK_URL_EN,
  PURCHASE_LINK_URL_JA,
} from '@/constants';
import { useGuestDisplay } from '@/hooks/useGuestDisplay';

/**
 * キャンペーン用の用 hooks
 *
 * @returns
 */
export const useCampaignDialog = () => {
  const { uiLang } = useGuestDisplay();

  /**
   * 購入ページリンク
   * UI言語ごとに変える
   */
  const openPurchaseLink = useCallback(() => {
    let url = PURCHASE_LINK_URL_JA;
    if (uiLang !== LOCALE_LANG.JA) {
      url = PURCHASE_LINK_URL_EN;
    }

    window.open(url, '_blank');
  }, [uiLang]);

  return {
    openPurchaseLink,
  };
};
