import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * 共有画面の共有状況
 */
export const SHARE_VIEW_STATUS = {
  // 共有前
  NONE: 'none',
  // 共有中
  SHARED: 'shared',
  // 共有画面情報取得に失敗
  SHARE_INFO_ERROR: 'shareInfoError',
  // アクセス期限切れ
  WARN_AUTH: 'warnAuth',
};
export type ShareViewStatus =
  (typeof SHARE_VIEW_STATUS)[keyof typeof SHARE_VIEW_STATUS];

/**
 * State
 */
export type ShareViewInfoState = {
  // 共有画面の共有状況
  shareViewStatus: ShareViewStatus;
  // 共有画面のURLキー
  shareURLKey: string;
  // FirestoreのドキュメントID
  uid: string;
  // 共有画面の利用者合計
  totalGuests: number;
  // 共有可能な人数の上限
  maxGuests: number;
  // 共有開始直後に共有ダイアログを表示するか否か
  isOpenShareInfoDialogAfterStart: boolean;
  // 共有復帰ダイアログを表示するか否か
  isOpenShareRejoinDialog: boolean;
  // 共有終了ダイアログを表示するか否か
  isOpenShareInvalidDialog: boolean;
};

/**
 * 初期State
 */
const initialState: ShareViewInfoState = {
  shareViewStatus: SHARE_VIEW_STATUS.NONE,
  shareURLKey: '',
  uid: '',
  totalGuests: 0,
  maxGuests: 0,
  isOpenShareInfoDialogAfterStart: false,
  isOpenShareRejoinDialog: false,
  isOpenShareInvalidDialog: false,
};

/**
 * 共有画面情報 Slice
 */
export const shareViewInfoSlice = createSlice({
  name: 'shareViewInfo',
  initialState,
  reducers: {
    // 共有画面の共有状況を更新
    setShareViewStatus: (state, action: PayloadAction<ShareViewStatus>) => {
      state.shareViewStatus = action.payload;
    },
    // 共有画面のURLキーを更新
    setShareURLKey: (state, action: PayloadAction<string>) => {
      state.shareURLKey = action.payload;
    },
    // FirestoreのドキュメントIDを更新
    setUid: (state, action: PayloadAction<string>) => {
      state.uid = action.payload;
    },
    // 共有画面の利用者合計を更新
    setTotalGuests: (state, action: PayloadAction<number>) => {
      state.totalGuests = action.payload;
    },
    // 共有可能な人数の上限を更新
    setMaxGuests: (state, action: PayloadAction<number>) => {
      state.maxGuests = action.payload;
    },
    // 共有開始直後に共有ダイアログを表示するか否か
    setIsOpenShareInfoDialogAfterStart: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isOpenShareInfoDialogAfterStart = action.payload;
    },
    // 共有復帰ダイアログを表示するか否か
    setIsOpenShareRejoinDialog: (state, action: PayloadAction<boolean>) => {
      state.isOpenShareRejoinDialog = action.payload;
    },
    // 共有期限終了ダイアログを表示するか否か
    setIsOpenShareInvalidDialog: (state, action: PayloadAction<boolean>) => {
      state.isOpenShareInvalidDialog = action.payload;
    },
    // リセット
    resetToInitialState: () => initialState,
  },
});
