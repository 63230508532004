import { useEffect } from 'react';

import { API_STATUS, COOKIE_KEY_NAME, COOKIE_USER_TYPE } from '@/constants';
import {
  FREE_LICENSE_KEY,
  LICENSE_INFO_API_RESULT_CODE,
  LICENSE_INVALID_REMAINING_TIME,
  useLicenseInfoApi,
} from '@/features/api';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { findCookieValue } from '@/utils/cookie';

/**
 * お試しプランの残り時間に関するhooks
 */
export const useRemainingTime = () => {
  const { setRemainingTime, setLicenseStr } = useTranslationInfo();
  const { licenseInfoState, fetchLicenseInfo } = useLicenseInfoApi();

  /**
   * マウント時にライセンス確認API呼び出し(お試しプランの場合のみ)
   */
  useEffect(() => {
    if (findCookieValue(COOKIE_KEY_NAME.PTBR_TYPE) === COOKIE_USER_TYPE.FREE) {
      fetchLicenseInfo();
    }

    // マウント時の1度だけ実行したいので無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * ライセンス情報確認APIリクエスト完了
   * ※お試しプラン時のみライセンス確認APIを呼んでいるので、ここではお試しプランかはチェックしない
   */
  useEffect(() => {
    // 失敗時の処理
    if (licenseInfoState.status === API_STATUS.FAILED) {
      if (
        licenseInfoState.data?.resultCode ===
        LICENSE_INFO_API_RESULT_CODE.INFO_INVALID_LICENSE
      ) {
        // INFO_INVALID_LICENSEエラーはOK扱いとする
        setLicenseStr(FREE_LICENSE_KEY);
        setRemainingTime(LICENSE_INVALID_REMAINING_TIME); // APIからは0が返ってくるので30分固定で表示
      }

      return;
    }

    // 成功時の処理
    if (
      licenseInfoState.status === API_STATUS.SUCCESS &&
      licenseInfoState.data
    ) {
      setLicenseStr(licenseInfoState.data.licenseStr);
      setRemainingTime(licenseInfoState.data.remainingTimeMillis);
    }
  }, [
    licenseInfoState.data,
    licenseInfoState.status,
    setLicenseStr,
    setRemainingTime,
  ]);
};
