import { useCallback, useMemo, useState } from 'react';

import { LangData } from '@/constants/language';
import { useBrowserLanguage } from '@/hooks/useBrowserLanguage';
import { useBrowserTranslationDisplay } from '@/hooks/useBrowserTranslationDisplay';
import { useLanguageList } from '@/hooks/useLanguageList';
import { sendChangeDestLangEvent, sendChangeSrcLangEvent } from '@/utils/ga';

import { defaultSttLanguage, defaultTttLanguage } from './useLanguageInfo';

/**
 * プロパティ
 */
type Props = {
  // 選択された言語をローカルストレージに即時保存するか(デフォルト=false)
  isSaveOnSelectLang?: boolean;
};

/**
 * 言語選択ダイアログ hooks
 *
 * @returns
 */
export const useLanguageSelectDialog = ({
  isSaveOnSelectLang = false,
}: Props) => {
  const { isInteractive, setIsInteractive } = useBrowserTranslationDisplay();
  const { srclang, destlang, setSrclang, setDestlang } = useBrowserLanguage();
  const { destLangList } = useLanguageList();

  // 翻訳元言語
  const [selectedSrclangValue, setSelectedSrclangValue] = useState<string>(
    srclang || defaultSttLanguage,
  ); // キャッシュ削除後はsrclangが空なのでデフォルト言語を設定(srclangにデフォルト言語が設定される前にプルダウンメニューが表示されてリストの先頭が選択された状態になるため)
  // 翻訳先言語
  const [selectedDestlangValue, setSelectedDestlangValue] = useState<string>(
    destlang || defaultTttLanguage,
  ); // selectedSrclangValueと同じ理由
  // 指定された双方向モード(ON/OFF)
  const [isSelectedInteractiveMode, setIsSelectedInteractiveMode] =
    useState<boolean>(isInteractive);

  /**
   * ダイアログで選択された翻訳先言語が双方向モードの対象かどうか
   * (true=双方向モード対象の言語を選択中)
   */
  const isInteractiveModeSelectedDestlang = useMemo((): boolean => {
    if (!destLangList) {
      return false;
    }
    const langData: LangData | undefined = destLangList.find(
      (value: LangData) => value[selectedDestlangValue],
    );

    if (!langData) {
      return false;
    }

    return langData[selectedDestlangValue].interactive;
  }, [destLangList, selectedDestlangValue]);

  /**
   * OKボタンがクリックされた時の処理
   */
  const onClickOKButton = useCallback(() => {
    // ローカルストレージに保存
    setSrclang(selectedSrclangValue);
    setDestlang(selectedDestlangValue);
    setIsInteractive(isSelectedInteractiveMode);
    // GAイベントを送信
    sendChangeSrcLangEvent({ src_lang: selectedSrclangValue });
    sendChangeDestLangEvent({ dest_lang: selectedDestlangValue });
  }, [
    setSrclang,
    selectedSrclangValue,
    setDestlang,
    selectedDestlangValue,
    setIsInteractive,
    isSelectedInteractiveMode,
  ]);

  /**
   * 翻訳元言語が変更された時の処理
   */
  const onChangeSrclang = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.currentTarget;
      setSelectedSrclangValue(value);

      // 即時保存ONの場合は選択された翻訳元言語をローカルストレージに保存
      if (isSaveOnSelectLang) {
        setSrclang(value);
      }
    },
    [isSaveOnSelectLang, setSrclang],
  );

  /**
   * 翻訳先言語が変更された時の処理
   */
  const onChangeDestlang = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const { value } = e.currentTarget;
      setSelectedDestlangValue(value);

      // 即時保存ONの場合は選択された翻訳先言語をローカルストレージに保存
      if (isSaveOnSelectLang) {
        setDestlang(value);
      }

      const langData: LangData | undefined = destLangList.find(
        (selectedValue: LangData) => selectedValue[value],
      );

      if (!langData) {
        return;
      }

      if (langData[value].interactive) {
        return;
      }

      // 選択された翻訳先言語が双方向モードの対象でない場合、双方向モードをOFFにする
      setIsSelectedInteractiveMode(false);
      // 即時保存ONの場合はローカルストレージに保存
      if (isSaveOnSelectLang) {
        setIsInteractive(false);
      }
    },
    [destLangList, isSaveOnSelectLang, setDestlang, setIsInteractive],
  );

  /**
   * 双方向モードトグルON/OFFの処理
   */
  const onChangeIsInteractive = useCallback(
    (value: boolean) => {
      setIsSelectedInteractiveMode(value);

      // 即時保存ONの場合は指定された状態をローカルストレージに保存
      if (isSaveOnSelectLang) {
        setIsInteractive(value);
      }
    },
    [isSaveOnSelectLang, setIsInteractive],
  );

  return {
    // OKボタンがクリックされた時の処理
    onClickOKButton,
    // 翻訳元言語が変更された時の処理
    onChangeSrclang,
    // 翻訳先言語が変更された時の処理
    onChangeDestlang,
    // 双方向モードトグルON/OFFの処理
    onChangeIsInteractive,
    // 翻訳元言語
    selectedSrclangValue,
    // 翻訳先言語
    selectedDestlangValue,
    // 選択中の翻訳先言語が双方向モードかどうか
    isInteractiveModeSelectedDestlang,
    // 指定された双方向モード(ON/OFF)
    isInteractive: isSelectedInteractiveMode,
  };
};
