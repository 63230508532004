import { BROWSER } from '@/constants';
import { browserAxios } from '@/lib/axios';
import { convertObjectToSneakJson } from '@/utils';

import { API_AUTH_RESULT_CODE } from './apiAuth';

/**
 * リクエストパラメータ
 */
export type LangApiRequest = {
  // 音声認識言語
  srclang: string;
  // 翻訳先言語
  destlang: string;
};

/**
 * 結果コード
 */
export const LANG_API_RESULT_CODE = {
  ...({
    // 成功
    OK: 'OK',
    // 入力パラメータエラー
    WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
    // 不明なエラー
    ERR_UNKNOWN: 'ERR_UNKNOWN',
  } as const),
  ...API_AUTH_RESULT_CODE,
} as const;
export type LangApiResultCode =
  (typeof LANG_API_RESULT_CODE)[keyof typeof LANG_API_RESULT_CODE];

/**
 * 結果コードのTypeGuard
 *
 * @param resultCode
 * @returns
 */
export const isLangApiResultCode = (
  resultCode: string,
): resultCode is LangApiResultCode => {
  if (resultCode in LANG_API_RESULT_CODE) {
    return true;
  }

  return false;
};

/**
 * レスポンス
 */
export type LangApiResponse = {
  // 結果コード
  resultCode: LangApiResultCode;
};

/**
 * API 呼び出し
 *
 * @param request
 * @returns
 */
const callApi = (request: LangApiRequest): Promise<LangApiResponse> => {
  // パラメータをスネークケースのJSONに変換
  const params = convertObjectToSneakJson(request);

  // リクエスト送信(POST)
  const response = browserAxios
    .post<LangApiResponse>(BROWSER.API_URL.LANG, params, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then((apiResponse) => apiResponse.data);

  return response;
};

/**
 * 言語設定API リクエスト
 *
 * @param request
 * @returns
 */
export const langApi = (request: LangApiRequest): Promise<LangApiResponse> =>
  callApi(request);
