import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  DialogMessageContents,
  ModalDialog,
  SpinnerOverlayView,
} from '@/components/Elements';
import { useBrowserTranslationDisplay } from '@/hooks/useBrowserTranslationDisplay';
import { useBrowserUserInfo } from '@/hooks/useBrowserUserInfo';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { MIC_STATUS } from '@/states/slices/translationInfoSlice';

import { useTranslationMic } from '../../hooks/mic/useTranslationMic';
import { ShareTranslationView } from '../share/ShareTranslationView';
import { TranslationView } from '../TranslationView';

/**
 * 翻訳画面(マイク翻訳モード) 子コンポーネント
 *
 * @returns
 */
const TranslationMicView = React.memo(() => {
  const { interimContent, clickSttButton, clickMicErrorDialogButton } =
    useTranslationMic();
  const { micStatus, recognizedList } = useTranslationInfo();
  const { displayType } = useBrowserTranslationDisplay();
  const { t } = useTranslation();
  const { isConferenceMode } = useBrowserUserInfo();

  return (
    <>
      <SpinnerOverlayView isOpen={micStatus === MIC_STATUS.NONE} />
      <ModalDialog
        isOpen={micStatus === MIC_STATUS.ERROR}
        buttons={[
          {
            label: 'OK',
            onClick: clickMicErrorDialogButton,
            gtmClassTag: 'dts-error-mic',
          },
        ]}
        title={t('translationDialog.通訳機能が利用できません').toString()}
      >
        <DialogMessageContents>
          {t(
            'translationDialog.マイクが認識できませんでした。ホーム画面に戻ります。',
          )}
        </DialogMessageContents>
      </ModalDialog>

      {/* 共有機能用ビュー(カンファレンス用シリアルでログイン中の場合のみ読み込む) */}
      {isConferenceMode && <ShareTranslationView />}

      {/* 翻訳画面ビュー */}
      <div>
        <TranslationView
          interimContent={interimContent}
          recognizedList={recognizedList}
          displayType={displayType}
          onClickSttButton={clickSttButton}
        />
      </div>
    </>
  );
});

/**
 * 翻訳画面(マイク翻訳モード)
 *
 * @returns
 */
export const TranslationMic = React.memo(() => <TranslationMicView />);
