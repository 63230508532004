import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { TOAST_ICON_TYPE } from '@/components/Elements';
import { API_STATUS } from '@/constants';
import { LOGOUT_API_RESULT_CODE } from '@/features/api';
import { useToastInfo } from '@/hooks/useToastInfo';
import { removeBeforeunloadEvent } from '@/utils';

import { useLogout } from './useLogout';

/**
 * ログアウト処理完了時に実行したい関数の型
 */
export type CompleteFunction = () => void;

/**
 * 本カスタムフックのプロパティ
 */
type Props = {
  // 処理完了時に実行したい処理
  onCompleteFun?: CompleteFunction;
};

/**
 * ログアウトダイアログ hooks
 *
 * @param param0
 */
export const useLogoutDialog = ({ onCompleteFun }: Props) => {
  const { doLogoutForSerial, logoutStatus, logoutResultCode } = useLogout({
    onSuccessFun: removeBeforeunloadEvent, // beforeunloadイベントによるwindows.alert削除
  });
  const { addToastMessage } = useToastInfo();

  const { t } = useTranslation();

  const onCompleteFuncRef = useRef<CompleteFunction>();
  useEffect(() => {
    onCompleteFuncRef.current = onCompleteFun;
  }, [onCompleteFun]);

  /**
   * ・ログアウト処理に成功・失敗した場合はプロパティに指定された関数を実行
   *   ただし、アクセス期限切れの場合は実行しない
   * ・アクセス期限切れ以外でログアウト処理に失敗した場合はエラーメッセージを表示
   */
  useEffect(() => {
    if (
      logoutStatus === API_STATUS.FAILED &&
      logoutResultCode === LOGOUT_API_RESULT_CODE.WARN_AUTH
    ) {
      removeBeforeunloadEvent(); // beforeunloadイベントによるwindows.alert削除

      return;
    }
    if (logoutStatus === API_STATUS.FAILED) {
      addToastMessage(
        t('logout.ログアウトに失敗しました'),
        TOAST_ICON_TYPE.WARNING,
      );
    }
    if (
      logoutStatus === API_STATUS.SUCCESS ||
      logoutStatus === API_STATUS.FAILED
    ) {
      onCompleteFuncRef.current?.();
    }
  }, [logoutStatus, logoutResultCode, addToastMessage, t]);

  return {
    // シリアル利用時にログアウトを実行する
    doLogoutForSerial,
    // ログアウト処理状況
    logoutStatus,
    // ログアウトAPIから返却された結果コード
    logoutResultCode,
  };
};
