import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  FONT_SIZE_INFO,
  LIST_DISPLAY_TYPE,
  ListDisplayType,
} from '@/constants';

/**
 * State
 */
export type BrowserTranslationDisplayState = {
  // 翻訳元テキストの表示/非表示を管理
  displayType: ListDisplayType;
  // 翻訳関連テキストの文字サイズを管理
  fontSize: number;
  // 双方向モードで翻訳するか否かを管理
  isInteractive: boolean;
};

/**
 * 初期State
 */
const initialState: BrowserTranslationDisplayState = {
  displayType: LIST_DISPLAY_TYPE.list,
  fontSize: FONT_SIZE_INFO.defaultValue,
  isInteractive: false,
};

/**
 * 翻訳画面の表示に関する情報を管理(ローカルストレージ用)
 */
export const browserTranslationDisplaySlice = createSlice({
  name: 'browserTranslationDisplay',
  initialState,
  reducers: {
    // 翻訳元テキストの表示/非表示を更新
    setDisplayType: (state, action: PayloadAction<ListDisplayType>) => {
      state.displayType = action.payload;
    },
    // 翻訳関連テキストの文字サイズを更新
    setFontSize: (state, action: PayloadAction<number>) => {
      state.fontSize = action.payload;
    },
    // 双方向モードで翻訳するか否かのフラグを更新
    setIsInteractive: (state, action: PayloadAction<boolean>) => {
      state.isInteractive = action.payload;
    },
    // リセット
    resetToInitialState: () => initialState,
  },
});
