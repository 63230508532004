import { Reducer, useCallback, useReducer } from 'react';

import { API_STATUS, ApiStatus } from '@/constants';

import { logoutRedirectApi } from '../logoutApi';

/**
 * State
 */
export type LogoutRedirectState = {
  // APIの呼び出し状態
  status: ApiStatus;
};

/**
 * Actionタイプ
 */
const LOGOUT_REDIRECT_ACTION_TYPE = {
  SET_LOGOUT_REDIRECT: 'SET_LOGOUT_REDIRECT',
} as const;

/**
 * Action
 */
type LogoutRedirectAction = {
  type: keyof typeof LOGOUT_REDIRECT_ACTION_TYPE;
};

/**
 * reducer関数
 *
 * @param state
 * @param action
 * @returns
 */
const reducer: Reducer<LogoutRedirectState, LogoutRedirectAction> = (
  state: LogoutRedirectState,
  action: LogoutRedirectAction,
) => {
  switch (action.type) {
    case LOGOUT_REDIRECT_ACTION_TYPE.SET_LOGOUT_REDIRECT:
      return {
        ...state,
        status: API_STATUS.LOADING,
      };
    default:
      return state;
  }
};

/**
 * ログアウトAPI(リダイレクト用) 呼び出しhooks
 *
 * @returns
 */
export const useLogoutRedirectApi = () => {
  const [state, dispatch] = useReducer(reducer, {
    status: API_STATUS.IDLE,
  });

  /**
   * API呼び出し
   *
   * ※ステータスは「呼び出し中」のみを管理(API呼び出したら自動でリダイレクトされるので、成功・失敗判定はできない)
   */
  const doLogoutRedirect = useCallback(() => {
    if (state.status === API_STATUS.LOADING) {
      return;
    }

    dispatch({ type: LOGOUT_REDIRECT_ACTION_TYPE.SET_LOGOUT_REDIRECT });

    logoutRedirectApi();
  }, [state.status]);

  return {
    logoutRedirectState: state,
    doLogoutRedirect,
  };
};
