/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { useLocation } from 'react-router-dom';

import logo from '@/assets/logo.svg';
import { LanguageAreaContainer } from '@/features/selectlanguage';

import { useBack } from '../hooks/useBack';
import { useCampaignBanner } from '../hooks/useCampaignBanner';
import { useMenuValidator } from '../hooks/useMenuValidator';
import { useTranslationPath } from '../hooks/useTranslationPath';

import campaignStyles from './CampaignBanner.module.scss';
import styles from './Header.module.scss';
import { MainMenuContainer } from './menu/MainMenuContainer';

/**
 * ヘッダー 子コンポーネント
 *
 * @returns
 */
const HeaderView = React.memo(() => {
  const { pathname, state } = useLocation();
  const { isTranslationPath, isDisplayBack } = useTranslationPath();
  const { onClickBackButton } = useBack();
  const { isMenuHidden } = useMenuValidator();
  const { shouldShowCampaignBanner, onClickCampaignBanner } =
    useCampaignBanner();

  return (
    <header data-testid="header">
      <div
        className={styles['header-contents']}
        role="button"
        tabIndex={-1}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <div className={styles['header-logo']}>
          {isDisplayBack(pathname, state) ? (
            <button
              type="button"
              onClick={() => {
                onClickBackButton(pathname);
              }}
              className={styles['back-btn']}
            />
          ) : (
            <div>
              <img
                className={styles['header-logo-img']}
                src={logo}
                alt="Workflow"
                decoding="async"
              />
            </div>
          )}
          {!isMenuHidden && shouldShowCampaignBanner && (
            <button
              type="button"
              onClick={onClickCampaignBanner}
              className={`${campaignStyles['campaign-banner']} dts-campaign`}
            />
          )}
        </div>
        {/* 言語選択エリア(翻訳画面のみ表示) */}
        {isTranslationPath(pathname) ? <LanguageAreaContainer /> : ''}
        <div className={styles['header-right-contents']}>
          {/* メインメニュー */}
          {!isMenuHidden ? <MainMenuContainer /> : ''}
        </div>
      </div>
    </header>
  );
});

/**
 * ヘッダー コンポーネント
 *
 * @returns
 */
export const Header = React.memo(() => <HeaderView />);
