/**
 * GAのカスタムイベント名
 */
export const GA_EVENT_NAME = {
  // UI言語切り替え
  CHANGE_UI_LANG: 'change_ui_lang',
  // 翻訳元言語切り替え
  CHANGE_SRC_LANG: 'change_src_lang',
  // 翻訳先言語切り替え
  CHANGE_DEST_LANG: 'change_dest_lang',
  // ゲスト画面キャンペーンポップアップ
  GUEST_CAMPAIGN: 'guest_campaign',
};

/**
 * ゲスト画面キャンペーンポップアップの表示条件
 */
export const GUEST_CAMPAIGN_DISPLAY_TYPE = {
  // ボタンクリックで表示
  BUTTON_CLICK: 'button_click',
  // 共有終了で表示
  SHARE_END: 'share_end',
};
export type GuestCampaignDisplayType =
  (typeof GUEST_CAMPAIGN_DISPLAY_TYPE)[keyof typeof GUEST_CAMPAIGN_DISPLAY_TYPE];
