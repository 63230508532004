/* eslint-disable react/jsx-props-no-spreading */
// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */
import { UseFormRegisterReturn } from 'react-hook-form';

import styles from './FormInput.module.scss';

/**
 * プロパティ
 */
export type FormInput = {
  // テキストタイプ(必須)
  type: 'text' | 'password';
  // ID(FormLabelと同じidを指定した場合、ラベルをクリックするとインプットにフォーカスが移る)
  id: string;
  // name属性(必須)
  name: string;
  // バリデーションエラーメッセージ
  errorMessage?: string;
  // useFormが持つregister(入力フォームの要素の参照を登録)
  registration?: Partial<UseFormRegisterReturn>;
  // プレースホルダー(デフォルト:空欄)
  placeholder?: string;
  // 最小文字数(デフォルト:無制限)
  minLength?: number;
  // 最大文字数(デフォルト:無制限)
  maxLength?: number;
  // 有効|無効(デフォルト:有効)
  disabled?: boolean;
  // デフォルト入力値(デフォルト:空)
  defaultValue?: string;
};

/**
 * 入力フォーム コンポーネント
 *
 * @param param0
 * @returns
 */
export const FormInput = ({
  type = 'text',
  id,
  name,
  errorMessage,
  registration,
  placeholder = '',
  minLength = undefined,
  maxLength = undefined,
  disabled = false,
  defaultValue = '',
}: FormInput) => {
  const mode = errorMessage
    ? 'form-input-border-red'
    : 'form-input-border-gray';

  return (
    <div className={styles['form-input-block']}>
      <input
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        minLength={minLength}
        maxLength={maxLength}
        className={`${styles['form-input']} ${styles[mode]}`}
        {...registration}
        disabled={disabled}
        defaultValue={defaultValue}
      />
      {errorMessage && (
        <div className={styles['form-input-error-message']}>{errorMessage}</div>
      )}
    </div>
  );
};
