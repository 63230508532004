import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * State
 */
export type TranslationDisplayState = {
  // フッターの表示状態を管理
  isDisplayFooter: boolean;
  // 文字サイズスライダー、入力元デバイスドロップダウンが表示されているかどうか(true=表示中)
  isOpenSetting: boolean;
  // 翻訳結果をスクロール中かどうか(true=スクロール中)
  isScrollingTranslation: boolean;
};

/**
 * 初期State
 */
const initialState: TranslationDisplayState = {
  isDisplayFooter: true,
  isOpenSetting: false,
  isScrollingTranslation: false,
};

/**
 * 翻訳画面の表示に関する情報を管理(Redux用)
 */
export const translationDisplaySlice = createSlice({
  name: 'translationDisplay',
  initialState,
  reducers: {
    // フッターの表示状態を更新
    setIsDisplay: (state, action: PayloadAction<boolean>) => {
      state.isDisplayFooter = action.payload;
    },
    // 文字サイズスライダー、入力元デバイスドロップダウンが表示されているかどうか(true=表示中)
    setIsOpenSetting: (state, action: PayloadAction<boolean>) => {
      state.isOpenSetting = action.payload;
    },
    // 翻訳結果をスクロール中かどうか(true=スクロール中)
    setIsScrollingTranslation: (state, action: PayloadAction<boolean>) => {
      state.isScrollingTranslation = action.payload;
    },
    // リセット
    resetToInitialState: () => initialState,
  },
});
