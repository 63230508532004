import React from 'react';
import { useTranslation } from 'react-i18next';

import { ModalDialog, SpinnerOverlayView } from '@/components/Elements';
import { FILE_LOAD_STATUS } from '@/hooks/useLoadFile';

import { useLoadLicenseList } from '../hooks/useLoadLicenseList';

import styles from './LicenseDialog.module.scss';

/**
 * ライセンス情報ダイアログ プロパティ
 */
type LicenseDialog = {
  // ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // ダイアログを閉じる
  onClose: () => void;
};

/**
 * ライセンス情報ダイアログ
 */
export const LicenseDialog = React.memo(
  ({ isOpen, onClose }: LicenseDialog) => {
    const { state, licenseList } = useLoadLicenseList();
    const { t } = useTranslation();

    return (
      <>
        <SpinnerOverlayView
          isOpen={state.status === FILE_LOAD_STATUS.LOADING}
        />
        <ModalDialog
          isOpen={isOpen}
          isHiddenCloseButton={false}
          onClickClose={onClose}
          title={t('settingMenu.ライセンス情報').toString()}
        >
          <div className={styles['license-list-container']}>
            <div className={styles['license-list']}>
              {/* 読み込み失敗時 */}
              {/* 静的ファイル読み込みのため通常発生し得ないのでエラーメッセージだけ表示 */}
              {state.status === FILE_LOAD_STATUS.FAILED && (
                <div className={styles['license-error-message']}>
                  {t('translationDialog.エラーが発生しました')}
                </div>
              )}

              {/* 読み込み成功時 */}
              {state.status === FILE_LOAD_STATUS.SUCCESS &&
                licenseList.length &&
                licenseList.map((e) => (
                  <div
                    className={styles['license-list-cell-container']}
                    key={`${e.moduleName}`}
                  >
                    {`${e.moduleName}(${e.license})`}
                    <br />
                    {e.repository}
                  </div>
                ))}
            </div>
          </div>
        </ModalDialog>
      </>
    );
  },
);
