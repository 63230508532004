export * from './api';
export * from './translation';
export * from './ui';
export * from './cookie';
export * from './websocket';
export * from './campaign';
export * from './serial';

/**
 * APIリクエストのタイムアウト時間
 */
export const DEFAULT_API_TIMEOUT = 30000 as const;

/**
 * 入力元/出力元デバイスの初期値
 */
export const DEFAULT_DEVICE = 'default' as string;

/**
 * ページURL
 */
export const PAGE_ROUTE_PATH = {
  // ようこそ画面
  WELCOME: '/login',
  // ようこそ画面(サインアップ用)
  SIGNUP: '/signup',
  // シリアル画面
  SERIAL: '/serial',
  // ホーム画面
  HOME: '/home',
  // 翻訳画面
  TRANSLATION: {
    // マイク翻訳モード
    MIC: '/translation/mic',
    // スピーカー翻訳モード
    SPEAKER: '/translation/speaker',
  },
  // 利用規約画面
  AGREEMENT: '/agreement',
  // 認証エラー画面
  AUTH_ERROR: '/auth/error',
  // 利用方法選択画面
  SELECT_USAGE: '/selectusage',
  // 共有用ゲスト画面
  GUEST: '/guest',
  // 404エラー画面(存在しないパスでも表示されるが404エラー画面にリダイレクトしたい時用のパス)
  NOT_FOUND: '/404',
} as const;

/**
 * UELパラメータに指定されるKey
 */
export const URL_PARAMS_KEY = {
  // ようこそ画面
  WELCOME: {
    path: 'path', // 画面パス
  },
  // シリアル画面
  SERIAL: {
    code: 'code', // シリアル
  },
  // 全画面
  lang: 'lang', // 利用言語
  // 利用規約画面
  AGREEMENT: {
    path: 'path', // 呼び出し元のパス
  },
  // ゲスト画面
  GUEST: {
    urlKey: 'key', // 共有画面のURLキー
  },
} as const;

/**
 * Payサーバ：新規作成メールアドレス入力画面 URL
 */
export const PAY_AAAWEB_URL = process.env.REACT_APP_PAY_AAAWEB_URL as string;

/**
 * マニュアル URL(日本語用)
 */
export const MANUAL_LINK_URL_JA =
  'https://pocketalk.jp/hubfs/images/product/forbusiness/livetranslation/manual/JP/index.html' as string;
/**
 * マニュアル URL(その他用)
 */
export const MANUAL_LINK_URL_EN =
  'https://www.sourcenext.com/produce/app/manual/pocketalksubtitles/EN/index.html' as string;

/**
 * フィードバック URL(日本語用)
 */
export const FEEDBACK_LINK_URL_JA = 'https://rd.snxt.jp/PT060' as string;
/**
 * フィードバック URL(その他用)
 */
export const FEEDBACK_LINK_URL_EN = 'https://rd.snxt.jp/PT061' as string;

/**
 * 利用規約 URL(日本語用)
 */
export const AGREEMENT_LINK_URL_JA =
  'https://pocketalk.co.jp/rule/livetranslation' as string;
/**
 * 利用規約 URL(その他用)
 */
export const AGREEMENT_LINK_URL_EN =
  'https://pocketalk.co.jp/rule/livetranslation/en-US' as string;

/**
 * 利用権限
 * PermissionStateはprompt、granted、deniedの3種で固定
 */
export const PERMISSION_STATE = {
  // 確認する
  ASK: 'prompt',
  // 許可
  ALLOW: 'granted',
  // 不許可
  BLOCK: 'denied',
} as const;

/**
 * 通訳履歴の保存期間(現在日+何日分保存するか)
 */
export const HISTORY_SAVE_LIMIT_DATE = 1 as number;

/**
 * 購入ページ URL(日本語用)
 */
export const PURCHASE_LINK_URL_JA =
  'https://pocketalk.jp/forbusiness/livetranslation/share_feature' as string;
/**
 * 購入ページ URL(その他用)
 */
export const PURCHASE_LINK_URL_EN =
  'https://pocketalk.jp/forbusiness/livetranslation/share_feature_en' as string;
