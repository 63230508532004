import React from 'react';

import styles from './PullDownMenu.module.scss';

// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */
/**
 * メニューの型
 */
export type PullDownMenuItem = {
  value: string;
  label: string;
};

/**
 * プロパティ
 */
export type PullDownMenu = {
  // name属性
  name: string;
  // メニューの選択リスト
  optionItems: PullDownMenuItem[];
  // 選択されたメニュー値
  selectedValue: string;
  // 無効化(default=false)
  disabled?: boolean;
  // 変更時の処理(必須)
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  // 要素(参照、操作したい場合に指定)
  selectRef?: React.RefObject<HTMLSelectElement>;
  // GTM用クラスタグ
  gtmClassTag?: string;
};

/**
 * プルダウンメニュー コンポーネント
 * @param param0
 * @returns
 */
export const PullDownMenu = ({
  name,
  optionItems,
  selectedValue,
  disabled = false,
  onChange,
  selectRef,
  gtmClassTag = '',
}: PullDownMenu) => {
  const options = optionItems.map((i: PullDownMenuItem) => (
    <option
      className={styles.option}
      key={i.value} // keyを指定しないと警告がでるのでプロパティに指定されたvalueを設定
      value={i.value}
    >
      {i.label}
    </option>
  ));

  return (
    <select
      className={`${styles.select} ${gtmClassTag}`}
      data-testid="select"
      name={name}
      value={selectedValue}
      disabled={disabled}
      onChange={(e) => onChange(e)}
      ref={selectRef}
      role="button"
      tabIndex={-1}
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
    >
      {options}
    </select>
  );
};
