import { BROWSER } from '@/constants';
import { browserAxios } from '@/lib/axios';

import { API_AUTH_RESULT_CODE } from './apiAuth';

/**
 * 結果コード
 */
export const TOKEN_AUTH_API_RESULT_CODE = {
  ...({
    // 成功
    OK: 'OK',
    // 不明なエラー
    ERR_UNKNOWN: 'ERR_UNKNOWN',
  } as const),
  ...API_AUTH_RESULT_CODE,
} as const;

export type TokenAuthApiResultCode =
  (typeof TOKEN_AUTH_API_RESULT_CODE)[keyof typeof TOKEN_AUTH_API_RESULT_CODE];

/**
 * 結果コードのTypeGuard
 *
 * @param resultCode
 * @returns
 */
export const isTokenAuthApiResultCode = (
  resultCode: string,
): resultCode is TokenAuthApiResultCode => {
  if (resultCode in TOKEN_AUTH_API_RESULT_CODE) {
    return true;
  }

  return false;
};

/**
 * レスポンス
 */
export type TokenAuthApiResponse = {
  // 結果コード
  resultCode: TokenAuthApiResultCode;
};

/**
 * API 呼び出し
 *
 * @returns
 */
const callApi = (): Promise<TokenAuthApiResponse> => {
  // リクエスト送信
  const response = browserAxios
    .get<TokenAuthApiResponse>(BROWSER.API_URL.TOKEN_AUTH, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then((apiResponse) => apiResponse.data);

  return response;
};

/**
 * トークンチェックAPI リクエスト
 *
 * @returns
 */
export const tokenAuthApi = (): Promise<TokenAuthApiResponse> => callApi();
