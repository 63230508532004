import QRCodeStyling, { Options } from 'qr-code-styling';
import { useEffect, useRef } from 'react';

import QRCodeOption from '../../../config/qrcodeOption/qrcodeOption.json';

const optionJson: Options = JSON.parse(JSON.stringify(QRCodeOption));

const qrCode = new QRCodeStyling(optionJson);

/**
 * QRコード用 hooks
 *
 * @returns
 */
export const useQRCode = (url: string) => {
  const qrCodeRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    qrCode.update({
      data: url,
    });
  }, [url]);

  useEffect(() => {
    qrCode.append(qrCodeRef.current || undefined);
  }, []);

  return {
    qrCodeRef,
  };
};
