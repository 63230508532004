// // Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */

import { useQRCode } from './useQRCode';

/**
 * プロパティ
 */
export type Props = {
  // URL文字列
  url: string;
};

/**
 * QRコード コンポーネント
 *
 * @param param0
 * @returns
 */
export const QRCode = ({ url }: Props) => {
  const { qrCodeRef } = useQRCode(url);

  return <div ref={qrCodeRef} />;
};
