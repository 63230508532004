import { BROWSER } from '@/constants';
import { browserAxios } from '@/lib/axios';
import { convertObjectToSneakJson } from '@/utils';

/**
 * リクエストパラメータ
 */
export type CredentialsApiRequest = {
  // 共有画面のURLキー(ホストの場合は未指定、ゲストの場合は設定)
  urlKey?: string;
};

/**
 * 結果コード定数
 */
export const CREDENTIALS_API_RESULT_CODE = {
  // 成功
  OK: 'OK',
  // 入力パラメータエラー
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
  // 認証エラー(Firestoreトークン取得可能なユーザではない)
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
  // 不明なエラー
  ERR_UNKNOWN: 'ERR_UNKNOWN',
} as const;
export type CredentialsApiResultCode =
  (typeof CREDENTIALS_API_RESULT_CODE)[keyof typeof CREDENTIALS_API_RESULT_CODE];

/**
 * 結果コードのTypeGuard
 *
 * @param resultCode
 * @returns
 */
export const isCredentialsApiResultCode = (
  resultCode: string,
): resultCode is CredentialsApiResultCode => {
  if (resultCode in CREDENTIALS_API_RESULT_CODE) {
    return true;
  }

  return false;
};

/**
 * レスポンス
 */
export type CredentialsApiResponse = {
  // 結果コード
  resultCode: CredentialsApiResultCode;
  // Firestoreトークン
  firestoreToken: string;
};

/**
 * API 呼び出し
 *
 * @returns
 */
const callApi = (
  request: CredentialsApiRequest,
): Promise<CredentialsApiResponse> => {
  let params = {};
  if (request.urlKey) {
    // パラメータが指定されていた場合はパラメータをスネークケースのJSONに変換
    params = convertObjectToSneakJson(request);
  }

  // リクエスト送信(POST)
  const response = browserAxios
    .post<CredentialsApiResponse>(BROWSER.API_URL.CREDENTIALS, params, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then((apiResponse) => apiResponse.data);

  return response;
};

/**
 * Firestore接続情報取得API
 *
 * @returns
 */
export const credentialsApi = (
  request: CredentialsApiRequest,
): Promise<CredentialsApiResponse> => callApi(request);
