import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { PullDownMenu, PullDownMenuItem } from '@/components/Elements';
import { FontSizeContainer } from '@/features/font';
import { useTtsButton } from '@/features/texttospeech';
import { useGoogleTtsVoiceList } from '@/hooks/useGoogleTtsVoiceList';

import styles from './ConferenceViewFooter.module.scss';

/**
 * プロパティ
 */
export type Props = {
  // メニュー
  menuContent: ReactElement;
  // フォントサイズ
  fontSize: number;
  // フォントサイズが変更された場合に実行したい処理
  onChangeFontSizeFunc: (value: number) => void;
  // 翻訳先言語
  destlang: string;
  // 翻訳先言語リスト
  destlangList: PullDownMenuItem[];
  // 翻訳先言語が変更された時の処理
  onChangeDestlang: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  // 翻訳先言語リストをDisabledにするか否か(true=Disabled, false=Enabled)
  isDisabledDestlangList: boolean;
};

/**
 * カンファレンス画面(ゲスト画面共通用) フッター コンポーネント
 */
export const ConferenceViewFooter = React.memo(
  ({
    menuContent,
    fontSize,
    onChangeFontSizeFunc,
    destlang,
    destlangList,
    onChangeDestlang,
    isDisabledDestlangList,
  }: Props) => {
    const { t } = useTranslation();
    const { clickTtsButton, pauseTtsOnDestLang, isTTSPlaying } = useTtsButton();
    const { isSupportedLangGoogleTTS } = useGoogleTtsVoiceList();

    return (
      <div className={styles['footer-container']}>
        <div className={styles['footer-container-left']}>
          <div className={styles['tts-button-container']}>
            <button
              data-testid="tts-button"
              type="button"
              aria-label="guest-tts-button"
              onClick={clickTtsButton}
              disabled={!isSupportedLangGoogleTTS(destlang)}
              className={`${styles['tts-button-image']} ${
                isTTSPlaying
                  ? styles['tts-button-play-icon']
                  : styles['tts-button-stop-icon']
              } ${'guest-01'}`}
            />
            <p
              className={`${styles['tts-disabled-message']} ${
                isSupportedLangGoogleTTS(destlang) &&
                styles['tts-disabled-message-none']
              }`}
            >
              {t('shareGuest.非対応')}
            </p>
          </div>
        </div>
        <div
          className={`${styles['lang-list']} ${styles['footer-container-center']}`}
        >
          <PullDownMenu
            name="sttLanguage"
            optionItems={destlangList}
            selectedValue={destlang}
            disabled={destlangList.length < 1 || isDisabledDestlangList}
            onChange={(e) => {
              onChangeDestlang(e);
              pauseTtsOnDestLang(e.currentTarget.value);
            }}
            gtmClassTag="guest-02"
          />
        </div>

        <div className={styles['footer-container-right']}>
          <FontSizeContainer
            currentFontSize={fontSize}
            onChangeFontSizeFunc={onChangeFontSizeFunc}
            gtmClassTag="guest-03"
          />
          <div>{menuContent}</div>
        </div>
      </div>
    );
  },
);
