import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { PAGE_ROUTE_PATH } from '@/constants';

/**
 * 利用方法選択画面 hooks
 */
export const useSelectUsage = () => {
  const navigate = useNavigate();

  /**
   * [ログイン]ボタンがクリックされた場合の処理
   */
  const onClickLogin = useCallback(() => {
    // ようこそ画面に遷移
    navigate(PAGE_ROUTE_PATH.WELCOME);
  }, [navigate]);

  /**
   * [シリアル入力]ボタンがクリックされた場合の処理
   */
  const onClickSerial = useCallback(() => {
    // シリアル入力画面に遷移(ヘッダーに戻るボタンを表示するためstateをtrueにする)
    navigate(PAGE_ROUTE_PATH.SERIAL, { state: true });
  }, [navigate]);

  return {
    onClickLogin,
    onClickSerial,
  };
};
