import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * 定期実行関数の型
 */
export type UpdateFunction = () => void;

/**
 * 定期実行のステータス
 */
const TIMER_STATUS = {
  // 実行中
  START: 'start',
  // 停止中
  STOP: 'stop',
};
export type TimerStatus = (typeof TIMER_STATUS)[keyof typeof TIMER_STATUS];

/**
 * プロパティ
 */
type Props = {
  // 定期実行したい関数
  onUpdate: UpdateFunction;
  // 定期実行間隔(ミリ秒)
  interval: number;
  // 本カスタムフックを呼んだ時点で定期実行を自動で開始するか(default=自動開始)
  autoStart?: boolean;
};

/**
 * プロパティに指定した関数の定期実行 カスタムフック
 */
export const useInterval = ({
  onUpdate,
  interval,
  autoStart = true,
}: Props) => {
  const timerIdRef = useRef<NodeJS.Timeout | undefined>(undefined);
  const onUpdateRef = useRef<UpdateFunction>();
  const [intervalStatus, setIntervalStatus] = useState<TimerStatus>(
    TIMER_STATUS.STOP,
  );
  /**
   * 定期実行開始
   */
  const startTimer = useCallback(() => {
    setIntervalStatus(TIMER_STATUS.START);
  }, []);

  /**
   * 定期実行停止
   */
  const stopTimer = useCallback(() => {
    setIntervalStatus(TIMER_STATUS.STOP);
  }, []);

  useEffect(() => {
    onUpdateRef.current = onUpdate;
  }, [onUpdate]);

  /**
   * 本カスタムフック表示時にautoStart=trueなら定期実行を自動で開始
   */
  useEffect(() => {
    if (autoStart) {
      setIntervalStatus(TIMER_STATUS.START);
    }
  }, [autoStart]);

  useEffect(() => {
    switch (intervalStatus) {
      case TIMER_STATUS.START:
        timerIdRef.current = setInterval(
          () => onUpdateRef.current?.(),
          interval,
        );
        break;
      default:
        if (timerIdRef.current) {
          // 停止時にタイマー破棄
          clearInterval(timerIdRef.current);
        }
    }

    // クリーンアップ関数で、タイマー破棄
    return () => {
      clearInterval(timerIdRef.current);
    };
  }, [interval, intervalStatus]);

  return {
    // 定期実行開始
    startTimer,
    // 定期実行停止
    stopTimer,
  };
};
