import React, { Suspense, lazy } from 'react';
import { useTranslation } from 'react-i18next';

import { DialogMessageContents, ModalDialog } from '@/components/Elements';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { SHARE_DISPLAY_STATUS } from '@/states/slices/translationInfoSlice';

import { useTranslationSpeaker } from '../../hooks/speaker/useTranslationSpeaker';

const SpeakerStart = lazy(() => import('./SpeakerStart'));

const SpeakerConfirm = lazy(() => import('./SpeakerConfirm'));

/**
 * 翻訳画面(スピーカー翻訳モード) 子コンポーネント
 *
 * @returns
 */
export const TranslationSpeakerView = React.memo(() => {
  const {
    interimContent,
    clickSttButton,
    clickStartMediaCapture,
    shouldOpenDeviceChangeDialog,
    noAudioDialogButtons,
    shareDisplayErrorDialogButtons,
    deviceChangeDialogButtons,
  } = useTranslationSpeaker();
  const { shareDisplayStatus, isSharingDisplay } = useTranslationInfo();

  const { t } = useTranslation();

  return (
    <>
      <ModalDialog
        isOpen={shareDisplayStatus === SHARE_DISPLAY_STATUS.NO_AUDIO}
        buttons={noAudioDialogButtons}
        title={t('translationDialog.通訳機能が利用できません').toString()}
      >
        <DialogMessageContents>
          {t(
            'translationDialog.音声が共有されていません。画面共有開始時に「音声を共有」のチェックがONになっていることを確認してください。',
          )}
        </DialogMessageContents>
      </ModalDialog>
      <ModalDialog
        isOpen={shareDisplayStatus === SHARE_DISPLAY_STATUS.ERROR}
        buttons={shareDisplayErrorDialogButtons}
        title={t('translationDialog.エラーが発生しました').toString()}
      >
        <DialogMessageContents>
          {t('translationDialog.ホーム画面に戻って操作をやり直してください。')}
        </DialogMessageContents>
      </ModalDialog>
      <ModalDialog
        isOpen={shouldOpenDeviceChangeDialog}
        buttons={deviceChangeDialogButtons}
        title={t('translationDialog.通訳機能を停止しました').toString()}
      >
        <DialogMessageContents>
          {t('translationDialog.スピーカーが切り替わりました。')}
        </DialogMessageContents>
      </ModalDialog>
      {isSharingDisplay ? (
        <SpeakerStart
          interimContent={interimContent}
          clickStt={clickSttButton}
        />
      ) : (
        <SpeakerConfirm clickStart={clickStartMediaCapture} />
      )}
    </>
  );
});

/**
 * 翻訳画面(スピーカー翻訳モード)
 *
 * @returns
 */
export const TranslationSpeaker = React.memo(() => (
  <Suspense>
    <TranslationSpeakerView />
  </Suspense>
));
