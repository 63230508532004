import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TOAST_ICON_TYPE } from '@/components/Elements';
import { API_STATUS, COOKIE_KEY_NAME, COOKIE_USER_TYPE } from '@/constants';
import { LANG_UPDATE_API_RESULT_CODE, useLangUpdateApi } from '@/features/api';
import { usePtidExpired } from '@/features/expired';
import { useToastInfo } from '@/hooks/useToastInfo';
import { findCookieValue } from '@/utils/cookie';

/**
 * 利用言語同期 カスタムフック
 */
export const useLangSynchronize = () => {
  const { fetchLangUpdate, langUpdateState } = useLangUpdateApi();
  const { ptidExpired } = usePtidExpired();
  const { addToastMessage } = useToastInfo();

  const { t } = useTranslation();

  /**
   * 利用言語同期
   */
  const synchronizeLang = useCallback(
    (uiLang: string) => {
      // 利用中のユーザ種別チェック
      const userType = findCookieValue(COOKIE_KEY_NAME.PTBR_TYPE);
      if (userType === COOKIE_USER_TYPE.SERIAL) {
        // シリアルで利用中の場合は何もしない
        return;
      }

      // 利用言語更新APIにリクエスト送信
      fetchLangUpdate({ lang: uiLang });
    },
    [fetchLangUpdate],
  );

  /**
   * エラーダイアログ or エラーメッセージ表示
   */
  const showErrorDialogOrMessage = useCallback(() => {
    if (
      langUpdateState.data?.resultCode ===
      LANG_UPDATE_API_RESULT_CODE.INFO_EXPIRED_IDTOKEN
    ) {
      // PTIDエラー処理
      ptidExpired();

      return;
    }

    if (
      langUpdateState.data?.resultCode === LANG_UPDATE_API_RESULT_CODE.WARN_AUTH
    ) {
      // アクセス期限切れダイアログを表示
      return;
    }

    // 利用言語同期失敗エラーメッセージを表示
    addToastMessage(
      t('langSynchronized.利用言語の同期に失敗しました'),
      TOAST_ICON_TYPE.WARNING,
    );
  }, [addToastMessage, langUpdateState.data?.resultCode, ptidExpired, t]);

  /**
   * 利用言語更新APIリクエスト状態を監視
   */
  useEffect(() => {
    if (langUpdateState.status === API_STATUS.FAILED) {
      showErrorDialogOrMessage();
    }
  }, [langUpdateState.status, showErrorDialogOrMessage]);

  return {
    synchronizeLang,
    langUpdateState,
  };
};
