import React from 'react';

import {
  InterimContent,
  LIST_DISPLAY_TYPE,
  ListDisplayType,
} from '@/constants';

import styles from './FixedInterimView.module.scss';
import { InterimView } from './InterimView';

/**
 * プロパティ
 */
export type FixedInterimView = {
  // 表示タイプ
  displayType: ListDisplayType;
  // 暫定テキスト情報
  interimContent: InterimContent;
};

/**
 * 固定表示用暫定テキストビュー コンポーネント
 */
export const FixedInterimView = React.memo(
  ({ displayType, interimContent }: FixedInterimView) => (
    <div className={`${styles[`interim-fixed-footer`]}`}>
      {displayType === LIST_DISPLAY_TYPE.onePhrase ? (
        <InterimView
          isReversed={interimContent.isReversed}
          langCode={
            interimContent.isReversed
              ? interimContent.srclang
              : interimContent.destlang
          }
          text={
            interimContent.isReversed ? interimContent.stt : interimContent.ttt
          }
          isAutoLeftScrollInterimText
        />
      ) : (
        <>
          <div className={styles['interim-fixed-content']}>
            <InterimView
              isReversed={!interimContent.isReversed}
              langCode={
                !interimContent.isReversed
                  ? interimContent.srclang
                  : interimContent.destlang
              }
              text={
                !interimContent.isReversed
                  ? interimContent.stt
                  : interimContent.ttt
              }
              isAutoLeftScrollInterimText
            />
          </div>
          <div className={`${styles['interim-fixed-content']}`}>
            <InterimView
              isReversed={interimContent.isReversed}
              langCode={
                !interimContent.isReversed
                  ? interimContent.destlang
                  : interimContent.srclang
              }
              text={
                !interimContent.isReversed
                  ? interimContent.ttt
                  : interimContent.stt
              }
              isAutoLeftScrollInterimText
            />
          </div>
        </>
      )}
    </div>
  ),
);
