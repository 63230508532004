import React from 'react';

import { useInterimListScroll } from '../translation/hooks/useInterimListScroll';

import styles from './ListView.module.scss';

/**
 * プロパティ
 */
export type InterimView = {
  // CSSを反転するか否か
  isReversed: boolean;
  // 言語コード
  langCode: string;
  // 暫定テキスト
  text: string;
  // 暫定テキストを左方向に自動スクロールするか否か(true=自動スクロールする)
  isAutoLeftScrollInterimText: boolean;
};

/**
 * 暫定テキストビュー コンポーネント
 */
export const InterimView = React.memo(
  ({
    isReversed,
    langCode,
    text,
    isAutoLeftScrollInterimText,
  }: InterimView) => {
    const autoScroll = useInterimListScroll({
      isAutoLeftScroll: isAutoLeftScrollInterimText,
    });

    return (
      <div
        lang={langCode}
        className={`${styles.raw} ${styles.translated} ${styles.interim} ${
          isReversed ? styles['interim-reversed'] : ''
        }`}
        ref={autoScroll.interimListRef}
      >
        {text}
      </div>
    );
  },
);
