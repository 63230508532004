import React from 'react';

import { PullDownMenu, SpinnerOverlayView } from '@/components/Elements';
import { API_STATUS, UILanguageList } from '@/constants';
import { useBrowserUserSetting } from '@/hooks/useBrowserUserSetting';

import { useUILanguage } from '../hooks/useUILanguage';

/**
 * プロパティ
 */
type UILanguageContainer = {
  // 設定ダイアログを閉じる
  onCloseDialog: () => void;
  // アクセス期限切れダイアログを表示する
  onOpenAccessExpDialog: () => void;
};

/**
 * UI言語ドロップダウンリスト
 */
export const UILanguageContainer = React.memo(
  ({ onCloseDialog, onOpenAccessExpDialog }: UILanguageContainer) => {
    const { language } = useBrowserUserSetting();
    const { onChangeUILang, langUpdateStatus } = useUILanguage({
      onFailedFun: onCloseDialog,
      onWarnAuthFun: onOpenAccessExpDialog,
    });

    return (
      <>
        <SpinnerOverlayView isOpen={langUpdateStatus === API_STATUS.LOADING} />
        <PullDownMenu
          optionItems={UILanguageList}
          name="uiLanguageSelect"
          selectedValue={language}
          onChange={onChangeUILang}
        />
      </>
    );
  },
);
