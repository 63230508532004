import { useState } from 'react';

/**
 * ライセンス情報メニュー hooks
 *
 * @returns
 */
export const useLicenseInfoContainer = () => {
  /**
   * ライセンス情報ダイアログを開くか否か(true=開く)
   */
  const [shouldLicenseDialog, setShouldLicenseDialog] =
    useState<boolean>(false);

  /**
   * ライセンス情報メニューがクリックされた場合の処理
   */
  const onClickLicenseMenu = () => {
    setShouldLicenseDialog(true);
  };

  /**
   * ライセンス情報ダイアログを閉じる
   */
  const onCloseLicenseDialog = () => {
    setShouldLicenseDialog(false);
  };

  return {
    // ライセンス情報ダイアログを開くか否か(true=開く)
    shouldLicenseDialog,
    // ライセンス情報メニューがクリックされた場合の処理
    onClickLicenseMenu,
    // ライセンス情報ダイアログを閉じる
    onCloseLicenseDialog,
  };
};
