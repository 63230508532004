import { Reducer, useCallback, useReducer } from 'react';

import { API_STATUS, ApiStatus } from '@/constants';

import {
  SERIAL_AUTH_API_RESULT_CODE,
  SerialAuthApiRequest,
  SerialAuthApiResponse,
  isSerialAuthApiResultCode,
  serialAuthApi,
} from '../serialAuthApi';

/**
 * State
 */
export type SerialAuthState = {
  // レスポンスデータ
  data?: SerialAuthApiResponse;
  // APIの呼び出し状態
  status: ApiStatus;
};

/**
 * Actionタイプ
 */
const SERIAL_AUTH_ACTION_TYPE = {
  SET_SERIAL: 'SET_SERIAL',
  SET_SERIAL_SUCCESS: 'SET_SERIAL_SUCCESS',
  SET_SERIAL_FAILED: 'SET_SERIAL_FAILED',
} as const;

/**
 * Action
 */
type SerialAuthAction = {
  data?: SerialAuthApiResponse;
  type: keyof typeof SERIAL_AUTH_ACTION_TYPE;
};

/**
 * reducer関数
 *
 * @param state
 * @param action
 * @returns
 */
const reducer: Reducer<SerialAuthState, SerialAuthAction> = (
  state: SerialAuthState,
  action: SerialAuthAction,
) => {
  switch (action.type) {
    case SERIAL_AUTH_ACTION_TYPE.SET_SERIAL:
      return {
        ...state,
        status: API_STATUS.LOADING,
      };
    case SERIAL_AUTH_ACTION_TYPE.SET_SERIAL_SUCCESS:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.SUCCESS,
      };
    case SERIAL_AUTH_ACTION_TYPE.SET_SERIAL_FAILED:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.FAILED,
      };
    default:
      return state;
  }
};

/**
 * シリアル認証API 呼び出しhooks
 *
 * @returns
 */
export const useSerialAuthApi = () => {
  const [state, dispatch] = useReducer(reducer, {
    data: undefined,
    status: API_STATUS.IDLE,
  });

  /**
   * API呼び出し
   */
  const fetchSerialAuth = useCallback(
    (request: SerialAuthApiRequest) => {
      if (state.status === API_STATUS.LOADING) {
        return;
      }

      dispatch({ type: SERIAL_AUTH_ACTION_TYPE.SET_SERIAL });

      serialAuthApi(request)
        .then((response: SerialAuthApiResponse) => {
          // 成功
          if (response.resultCode === SERIAL_AUTH_API_RESULT_CODE.OK) {
            dispatch({
              type: SERIAL_AUTH_ACTION_TYPE.SET_SERIAL_SUCCESS,
              data: response,
            });

            return;
          }

          // 意図しない結果コードの型の場合は失敗とする
          if (!isSerialAuthApiResultCode(response.resultCode)) {
            dispatch({
              type: SERIAL_AUTH_ACTION_TYPE.SET_SERIAL_FAILED,
              data: {
                ...response,
                resultCode: SERIAL_AUTH_API_RESULT_CODE.ERR_UNKNOWN,
              } as SerialAuthApiResponse,
            });

            return;
          }

          // 失敗
          dispatch({
            type: SERIAL_AUTH_ACTION_TYPE.SET_SERIAL_FAILED,
            data: response,
          });
        })
        .catch((_) => {
          dispatch({
            type: SERIAL_AUTH_ACTION_TYPE.SET_SERIAL_FAILED,
            data: {
              resultCode: SERIAL_AUTH_API_RESULT_CODE.ERR_UNKNOWN,
            } as SerialAuthApiResponse,
          });
        });
    },
    [state.status],
  );

  return {
    serialAuthState: state,
    fetchSerialAuth,
  };
};
