import { Reducer, useCallback, useReducer } from 'react';

import { API_STATUS, ApiStatus } from '@/constants';

import {
  TOKEN_AUTH_API_RESULT_CODE,
  TokenAuthApiResponse,
  isTokenAuthApiResultCode,
  tokenAuthApi,
} from '../tokenAuthApi';

/**
 * State
 */
export type TokenAuthState = {
  // レスポンスデータ
  data?: TokenAuthApiResponse;
  // APIの呼び出し状態
  status: ApiStatus;
};

/**
 * Actionタイプ
 */
const TOKEN_AUTH_ACTION_TYPE = {
  SET_TOKEN: 'SET_TOKEN',
  SET_TOKEN_SUCCESS: 'SET_TOKEN_SUCCESS',
  SET_TOKEN_FAILED: 'SET_TOKEN_FAILED',
} as const;

/**
 * Action
 */
type TokenAuthAction = {
  data?: TokenAuthApiResponse;
  type: keyof typeof TOKEN_AUTH_ACTION_TYPE;
};

/**
 * reducer関数
 *
 * @param state
 * @param action
 * @returns
 */
const reducer: Reducer<TokenAuthState, TokenAuthAction> = (
  state: TokenAuthState,
  action: TokenAuthAction,
) => {
  switch (action.type) {
    case TOKEN_AUTH_ACTION_TYPE.SET_TOKEN:
      return {
        ...state,
        status: API_STATUS.LOADING,
      };
    case TOKEN_AUTH_ACTION_TYPE.SET_TOKEN_SUCCESS:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.SUCCESS,
      };
    case TOKEN_AUTH_ACTION_TYPE.SET_TOKEN_FAILED:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.FAILED,
      };
    default:
      return state;
  }
};

/**
 * トークンチェックAPI 呼び出しhooks
 *
 * @returns
 */
export const useTokenAuthApi = () => {
  const [state, dispatch] = useReducer(reducer, {
    data: undefined,
    status: API_STATUS.IDLE,
  });

  /**
   * API呼び出し
   */
  const fetchTokenAuth = useCallback(() => {
    if (state.status === API_STATUS.LOADING) {
      return;
    }

    dispatch({ type: TOKEN_AUTH_ACTION_TYPE.SET_TOKEN });

    tokenAuthApi()
      .then((response: TokenAuthApiResponse) => {
        // 成功
        if (response.resultCode === TOKEN_AUTH_API_RESULT_CODE.OK) {
          dispatch({
            type: TOKEN_AUTH_ACTION_TYPE.SET_TOKEN_SUCCESS,
            data: response,
          });

          return;
        }

        // 意図しない結果コードの型だった場合は失敗とする
        if (!isTokenAuthApiResultCode(response.resultCode)) {
          dispatch({
            type: TOKEN_AUTH_ACTION_TYPE.SET_TOKEN_FAILED,
            data: {
              ...response,
              resultCode: TOKEN_AUTH_API_RESULT_CODE.ERR_UNKNOWN,
            } as TokenAuthApiResponse,
          });

          return;
        }

        // 失敗
        dispatch({
          type: TOKEN_AUTH_ACTION_TYPE.SET_TOKEN_FAILED,
          data: response,
        });
      })
      .catch((_) => {
        dispatch({
          type: TOKEN_AUTH_ACTION_TYPE.SET_TOKEN_FAILED,
          data: {
            resultCode: TOKEN_AUTH_API_RESULT_CODE.ERR_UNKNOWN,
          } as TokenAuthApiResponse,
        });
      });
  }, [state.status]);

  return {
    tokenAuthState: state,
    fetchTokenAuth,
  };
};
