import { useEffect } from 'react';

import { useFirestoreLoginUseToken } from './useFirestoreLoginUseToken';

/**
 * Firestoreからログアウトするためのカスタムフック
 */
export const useFirestoreLogout = () => {
  const { signOutFirestore } = useFirestoreLoginUseToken();

  useEffect(
    () => () => {
      // Firestoreからログアウト
      signOutFirestore();
    },

    // マウント/アンマウント時の1度だけ実行したいので無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};
