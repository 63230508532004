import { BROWSER } from '@/constants';
import { browserAxios } from '@/lib/axios';

import { API_AUTH_RESULT_CODE } from './apiAuth';

/**
 * リクエストパラメータ
 */
export type LangUpdateApiRequest = {
  // 言語コード
  lang: string;
};

/**
 * 結果コード定数
 */
export const LANG_UPDATE_API_RESULT_CODE = {
  ...({
    // 成功
    OK: 'OK',
    // 入力パラメータエラー
    WARN_INPUT_PARAM: 'WARN_INPUT_PARAM',
    // 利用言語更新が許可されていない
    WARN_NOT_ALLOWED: 'WARN_NOT_ALLOWED',
    // 外部API呼び出し失敗
    WARN_FAILED_CALL: 'WARN_FAILED_CALL',
    // DBのIDトークンが不正
    ERR_INVALID_IDTOKEN: 'ERR_INVALID_IDTOKEN',
    // 不明なエラー
    ERR_UNKNOWN: 'ERR_UNKNOWN',
  } as const),
  ...API_AUTH_RESULT_CODE,
} as const;
export type LangUpdateApiResultCode =
  (typeof LANG_UPDATE_API_RESULT_CODE)[keyof typeof LANG_UPDATE_API_RESULT_CODE];

/**
 * 結果コードのTypeGuard
 *
 * @param resultCode
 * @returns
 */
export const isLangUpdateApiResultCode = (
  resultCode: string,
): resultCode is LangUpdateApiResultCode => {
  if (resultCode in LANG_UPDATE_API_RESULT_CODE) {
    return true;
  }

  return false;
};

/**
 * レスポンス
 */
export type LangUpdateApiResponse = {
  // 結果コード
  resultCode: LangUpdateApiResultCode;
};

/**
 * API 呼び出し
 *
 * @returns
 */
const callApi = (
  request: LangUpdateApiRequest,
): Promise<LangUpdateApiResponse> => {
  // リクエスト送信(POST)
  const response = browserAxios
    .post<LangUpdateApiResponse>(BROWSER.API_URL.LANG_UPDATE, request, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then((apiResponse) => apiResponse.data);

  return response;
};

/**
 * 利用言語更新API(同通サーバ) リクエスト
 *
 * @returns
 */
export const langUpdateApi = (
  request: LangUpdateApiRequest,
): Promise<LangUpdateApiResponse> => callApi(request);
