import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RecognizedItem } from '@/constants';
import {
  HistoryData,
  BrowserHistory,
  browserHistoryAdapterSelectors,
  browserHistorySlice,
  BrowserHistoryState,
} from '@/states/slices/browserHistorySlice';
import { AppDispatch, RootState, useAppDispatch } from '@/states/store';
import { encryptStr, decryptStr } from '@/utils/aes';
import {
  YYYYMMDD_T_000000_FORMAT,
  YYYYMMDD_T_235959_FORMAT,
  YYYYMMDD_T_HH0000_FORMAT,
  convertDateUTCToString,
  convertUtcDateToFormatDate,
  convertYyyyMMddhhmmssToDateUTC,
  currentDateUTC,
  currentTimeMinusDateInUTC,
} from '@/utils/date';

/**
 * 通訳履歴をローカルストレージとReduxに保存 hooks
 *
 * @returns
 */
export const useBrowserHistory = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const {
    upsertOneHistoryList,
    removeOneHistoryList,
    setIsHistory,
    removeAllHistoryList,
    clearToken,
  } = browserHistorySlice.actions;
  const { t } = useTranslation();

  // 一覧
  const list = useSelector(browserHistoryAdapterSelectors.selectAll);
  // idがキーのエンティティ
  const entities = useSelector(browserHistoryAdapterSelectors.selectEntities);

  const { isHistory } = useSelector<RootState, BrowserHistoryState>(
    (state) => state.browserHistory,
  );

  /**
   * idがキーのエンティティを監視でしてuseRefに格納
   *
   * ローカルストレージの履歴が更新されるタイミングがWebsocketのonmessageが返ってきたタイミングであり、
   * その際にイベントリスナー内のReduxはキャプチャされた値が使われてしまうので、
   * useRefを使って現行の値を参照できるようにする
   *
   */
  const entitiesRef = useRef(entities);
  useEffect(() => {
    entitiesRef.current = entities;
  }, [entities]);

  /**
   * 指定したIDに紐づく通訳履歴データを取得
   */
  const pullHistoryList = useCallback((id: string): BrowserHistory => {
    const history = entitiesRef.current[id];
    let dataArray: HistoryData[] = [];
    if (history) {
      dataArray = JSON.parse(decryptStr(history.list));
    }

    return { id, list: dataArray };
  }, []);

  /**
   * ローカルストレージに保存されている全ての通訳履歴一覧を取得
   */
  const pushAllHistoryList = useCallback((): BrowserHistory[] => {
    const history: BrowserHistory[] = [];
    list.forEach((data) => {
      // 暗号化されたデータをデコード
      const dataArray: HistoryData[] = JSON.parse(decryptStr(data.list));
      history.push({ id: data.id, list: dataArray });
    });

    return history;
  }, [list]);

  /**
   * 1日分の通訳履歴データを更新、無ければ追加
   */
  const upsertHistoryList = useCallback(
    (value: BrowserHistory) => {
      const listStr = encryptStr(JSON.stringify(value.list));
      dispatch(upsertOneHistoryList({ id: value.id, list: listStr }));
    },
    [dispatch, upsertOneHistoryList],
  );

  /**
   * 1日分の通訳履歴データを削除
   */
  const removeHistoryList = useCallback(
    (id: string) => {
      dispatch(removeOneHistoryList(id));
    },
    [dispatch, removeOneHistoryList],
  );

  /**
   * 通訳履歴を全削除する（isHistoryは削除しない）
   */
  const clearAllHistoryList = useCallback(() => {
    dispatch(removeAllHistoryList());
  }, [dispatch, removeAllHistoryList]);

  /**
   * 通訳履歴を保存するか否かを変更する
   */
  const changeIsHistory = useCallback(
    (value: boolean) => {
      dispatch(setIsHistory(value));
    },

    [dispatch, setIsHistory],
  );

  /**
   * 全通訳履歴一覧を削除
   */
  const clearAllLogState = useCallback(() => {
    dispatch(clearToken());
  }, [dispatch, clearToken]);

  /**
   * 保存期間が過ぎた履歴を削除
   *
   * @param currentDate 現在時刻(UTC)
   * @param limitSaveDay 保存日数
   */
  const deleteHistoryOverLimit = useCallback(
    (limitSaveDay: number) => {
      if (!list || list.length <= 0) {
        return;
      }

      // 現在時刻(UTC)
      const currentDate: Date = currentDateUTC();
      // 現在時刻の１日前
      const limitSaveDate: Date = currentTimeMinusDateInUTC(
        currentDate,
        limitSaveDay,
      );

      // 保存期間開始日
      const startDate: Date = convertUtcDateToFormatDate(
        limitSaveDate,
        YYYYMMDD_T_000000_FORMAT,
      );
      // 保存期間終了日
      const endDate: Date = convertUtcDateToFormatDate(
        currentDate,
        YYYYMMDD_T_235959_FORMAT,
      );

      list.forEach((data) => {
        const { id } = data;
        // 保存日(id)
        const idDate: Date = convertYyyyMMddhhmmssToDateUTC(id);
        // 現在時刻の１日前の00:00:00 ＋ 当日の23:59:59以外は削除
        if (
          idDate.getTime() < startDate.getTime() ||
          endDate.getTime() < idDate.getTime()
        ) {
          removeHistoryList(id);
        }
      });
    },
    [list, removeHistoryList],
  );

  /**
   * 通訳履歴の一覧を作成
   */
  const createHistory = useCallback(
    (recognizedItems: RecognizedItem[], dataIdStr: string): HistoryData[] => {
      // 保存済の日付IDに紐づく履歴一覧を取得
      const savedHistoryList: HistoryData[] = pullHistoryList(dataIdStr).list;

      // 空の一覧 or 保存済の履歴一覧に新しい通訳データを追加
      recognizedItems.forEach((recognizedItem) => {
        savedHistoryList.push({
          通訳日時: recognizedItem.value.date,
          通訳元言語: t(`language.${recognizedItem.value.srclang}`),
          通訳元テキスト: recognizedItem.value.stt,
          通訳先言語: t(`language.${recognizedItem.value.destlang}`),
          通訳先テキスト: recognizedItem.value.ttt,
        });
      });

      return savedHistoryList;
    },
    [pullHistoryList, t],
  );

  /**
   * 現在時刻を元に通訳履歴IDを作成
   */
  const dataIdStr = useCallback(() => {
    // 現在時刻(UTC)
    const currentDate: Date = currentDateUTC();

    // 現在時刻を元にID作成
    return convertDateUTCToString(currentDate, YYYYMMDD_T_HH0000_FORMAT);
  }, []);

  /**
   * 通訳履歴を更新
   */
  const updateHistory = useCallback(
    (recognizedItem: RecognizedItem) => {
      // 通訳履歴ID
      const currentDataIdStr = dataIdStr();

      // 履歴を作成
      const historyList = createHistory([recognizedItem], currentDataIdStr);

      // 履歴を保存
      upsertHistoryList({ id: currentDataIdStr, list: historyList });
    },
    [createHistory, dataIdStr, upsertHistoryList],
  );

  /**
   * 複数の翻訳結果をもとに通訳履歴を更新
   */
  const updateHistoryOnRecognizedItems = useCallback(
    (recognizedItems: RecognizedItem[]) => {
      // 通訳履歴ID
      const currentDataIdStr = dataIdStr();

      // 履歴を作成
      const historyList = createHistory(recognizedItems, currentDataIdStr);

      // 履歴を保存
      upsertHistoryList({ id: currentDataIdStr, list: historyList });
    },
    [createHistory, dataIdStr, upsertHistoryList],
  );

  return {
    isHistory,
    changeIsHistory,
    pullHistoryList,
    clearAllHistoryList,
    pushAllHistoryList,
    updateHistory,
    updateHistoryOnRecognizedItems,
    deleteHistoryOverLimit,
    resetHistoryListState: clearAllLogState,
  };
};
