import { useCallback, useEffect } from 'react';

import { googleTtsVoiceList } from '@/features/api';
import { useGoogleTtsVoiceList } from '@/hooks/useGoogleTtsVoiceList';

/**
 * Google TTS API 発話情報一覧更新 hooks
 * @returns
 */
export const useGoogleTtsVoiceListInfo = () => {
  const { setGoogleTtsVoiceList } = useGoogleTtsVoiceList();

  const fetchGoogleTtsLangList = useCallback(() => {
    googleTtsVoiceList().then((response) => {
      setGoogleTtsVoiceList(response);
    });
  }, [setGoogleTtsVoiceList]);

  /**
   * マウント時の処理
   */
  useEffect(() => {
    fetchGoogleTtsLangList();

    // 画面表示処理のため無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
