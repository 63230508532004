import { subDays } from 'date-fns';
import {
  utcToZonedTime,
  format as formatTZ,
  formatInTimeZone,
} from 'date-fns-tz';

import { LOCALE_LANG } from '@/constants';

/**
 * UTC 形式
 */
export const UTC_FORMAT = "yyyy-MM-dd'T'HH:mm:ssXXX" as string;

/**
 * yyyyMMdd'T'HH0000XXX 形式
 */
export const YYYYMMDD_T_HH0000_FORMAT = "yyyyMMdd'T'HH0000XXX" as string;

/**
 * yyyyMMddHHmmss 形式
 */
export const YYYYMMDD_HHMMSS_FORMAT = 'yyyyMMddHHmmss' as string;

/**
 * yyyyMMdd'T'000000XXX 形式
 */
export const YYYYMMDD_T_000000_FORMAT = "yyyyMMdd'T'000000XXX" as string;

/**
 * yyyyMMdd'T'235959XXX 形式
 */
export const YYYYMMDD_T_235959_FORMAT = "yyyyMMdd'T'235959XXX" as string;

/**
 * yyyy-MM-dd 形式
 */
export const ISO8601_DATE_FORMAT = 'yyyy-MM-dd' as string;

/**
 * MM-dd-yyyy 形式
 */
export const MDY_DATE_FORMAT = 'MM-dd-yyyy' as string;

/**
 * dd-MM-yyyy 形式
 */
export const DMY_DATE_FORMAT = 'dd-MM-yyyy' as string;

/**
 * 現在時刻をUTCで取得
 *
 * @returns
 */
export const currentDateUTC = (): Date => utcToZonedTime(new Date(), 'UTC');

/**
 * 指定時刻から引数で指定された日付分前の日時をUTCで取得しUTCで返却
 *
 * @param minusDate 引く日数
 * @returns
 */
export const currentTimeMinusDateInUTC = (
  targetDate: Date,
  minusDate: number,
): Date => subDays(targetDate, minusDate);

/**
 * 引数で受け取ったDateを指定されたフォーマットの文字列に変換する
 *
 * @param targetDate 変換対象のDate
 * @param format フォーマットしたい日付文字列形式
 * @returns 日付文字列形式
 */
export const convertDateToString = (
  targetDate: Date,
  format: string,
): string => {
  const result = formatTZ(targetDate, format);

  return result;
};

/**
 * 引数で受け取ったDate(UTC)を指定されたフォーマットの文字列に変換する
 *
 * @param targetDateUTC 変換対象のDate(UTC)
 * @param format フォーマットしたい日付文字列形式
 * @return 日付文字列形式
 */
export const convertDateUTCToString = (
  targetDateUTC: Date,
  format: string,
): string => {
  const result = formatTZ(targetDateUTC, format, {
    timeZone: 'UTC',
  });

  return result;
};

/**
 * 引数で受け取ったDateを yyyy-MM-dd'T'HH:mm:ssXXX に変換する
 *
 * @param date
 * @returns
 */
export const convertDateToYyyyMMddTHhmmssUTC = (date: Date) => {
  const result = formatTZ(date, UTC_FORMAT, {
    timeZone: 'UTC',
  });

  return result;
};

/**
 * 引数で受け取ったDate(UTC)を指定されたフォーマット(Date)に変換する
 *
 * @param utcData 形式を変更したいDate(UTC)
 * @param format フォーマット
 * @returns 引数で受け取ったDate(UTC)を指定された形式にフォーマット後、Date(UTC)に再変換した値
 */
export const convertUtcDateToFormatDate = (utcData: Date, format: string) => {
  const result = formatTZ(utcData, format, {
    timeZone: 'UTC',
  });

  const date = utcToZonedTime(result, 'UTC');

  return date;
};

/**
 * yyyy-MM-dd'T'HH:mm:ssXXX 形式の日付文字列からDate型に変換する
 *
 * @param dateStr
 * @returns Date型の日付
 */
export const convertYyyyMMddhhmmssToDateUTC = (dateStr: string) => {
  const date = utcToZonedTime(dateStr, 'UTC');

  return date;
};

/**
 * yyyy-MM-dd'T'HH:mm:ssXXX 形式の日付文字列からユーザのタイムゾーンのDate型に変換する
 * @param dateStr
 * @returns Date型の日付
 */
export const convertYyyyMMddhhmmssToDateUserTimeZone = (dateStr: string) => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const date = utcToZonedTime(dateStr, timeZone);

  return date;
};

/**
 * FirestoreのTimestampのsecondsから日付文字列に変換する
 * @param seconds
 * @returns 日付文字列
 */
export const convertFirestoreTimestampToDateStr = (seconds: number) => {
  const date = formatInTimeZone(seconds * 1000, 'UTC', UTC_FORMAT);

  return date;
};

/**
 * UI言語に合わせたフォーマットを返す
 * @param language UI言語
 * @returns UI言語に則した日付のフォーマット
 */
export const dateFormatByLang = (language: string) => {
  switch (language) {
    case LOCALE_LANG.EN:
      return MDY_DATE_FORMAT;
    case LOCALE_LANG.JA:
    case LOCALE_LANG.KO:
    case LOCALE_LANG.ZH_CN:
    case LOCALE_LANG.ZH_TW:
      return ISO8601_DATE_FORMAT;
    default:
      return DMY_DATE_FORMAT;
  }
};
