import React from 'react';

import {
  useFirestoreLogout,
  useViewConfigCollection,
} from '@/features/firestore';
import { ShareInfoDialog } from '@/features/share';
import { useShareViewInfo } from '@/hooks/useShareViewInfo';
import { SHARE_VIEW_STATUS } from '@/states/slices/shareViewInfoSlice';

import { useShareCheck } from '../../hooks/share/useShareCheck';
import { useTranslationShareView } from '../../hooks/share/useTranslationShareView';

import { ShareInfoErrorDialog } from './ShareInfoErrorDialog';
import { ShareInvalidDialog } from './ShareInvalidDialog';

/**
 * プロパティ
 */
type Props = {
  // Firestoreトークン
  firestoreToken: string;
  // 共有終了ダイアログ表示フラグ
  isShowShareInvalidDialog: boolean;
  // 共有終了ダイアログOKボタンクリック時の処理
  onClickOKShareInvalidDialog: () => void;
};

/**
 * Firestore監視用ビュー
 */
export const FirestoreMonitoringView = React.memo(
  ({
    firestoreToken,
    isShowShareInvalidDialog,
    onClickOKShareInvalidDialog,
  }: Props) => {
    const { onChangeViewConfig, firestoreConnectionError } =
      useTranslationShareView();
    // 共有状況の監視
    useViewConfigCollection({
      documentId: firestoreToken,
      onDocChanges: onChangeViewConfig,
      onDocError: firestoreConnectionError,
    });

    // Firestoreからログアウト
    useFirestoreLogout();

    return (
      <>
        {/* 共有終了ダイアログを表示 */}
        {isShowShareInvalidDialog && (
          <ShareInvalidDialog isOpen onClickOK={onClickOKShareInvalidDialog} />
        )}
      </>
    );
  },
);

/**
 * 共有用翻訳画面ビュー
 */
export const ShareTranslationView = React.memo(() => {
  const {
    shareViewStatus,
    setShareViewStatus,
    uid,
    isOpenShareInfoDialogAfterStart,
    setIsOpenShareInfoDialogAfterStart,
    isOpenShareInvalidDialog,
    setIsOpenShareInvalidDialog,
    shareURLKey,
    isShared,
  } = useShareViewInfo();

  // 翻訳画面表示時に共有状況をチェック
  useShareCheck();

  // 共有終了ダイアログのOKボタンクリック時の処理
  const onClickOKShareInvalidDialog = () => {
    setIsOpenShareInvalidDialog(false);
    setShareViewStatus(SHARE_VIEW_STATUS.NONE);
  };

  return (
    <>
      {/* 共有画面情報取得失敗エラーダイアログを表示 */}
      <ShareInfoErrorDialog
        isOpen={shareViewStatus === SHARE_VIEW_STATUS.SHARE_INFO_ERROR}
      />

      {/* 共有開始成功直後に共有ダイアログを表示 */}
      <ShareInfoDialog
        isOpen={isOpenShareInfoDialogAfterStart}
        onClose={() => setIsOpenShareInfoDialogAfterStart(false)}
        shareURLKey={shareURLKey}
      />

      {/* 共有機能がONになった場合にFirestoreの監視開始 */}
      {uid &&
        (isShared(shareViewStatus) ||
          // 共有中にSHARE_INFO_ERRORになってもFirestoreMonitoringViewは解放しない(解放するとShareViewInfoがリセットされて共有画面情報取得失敗エラーダイアログが表示されないため)
          shareViewStatus === SHARE_VIEW_STATUS.SHARE_INFO_ERROR) && (
          <FirestoreMonitoringView
            firestoreToken={uid}
            isShowShareInvalidDialog={isOpenShareInvalidDialog}
            onClickOKShareInvalidDialog={onClickOKShareInvalidDialog}
          />
        )}
    </>
  );
});
