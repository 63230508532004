import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { PAGE_ROUTE_PATH } from '@/constants';

import { useBrowserUserSetting } from './useBrowserUserSetting';
import { useGuestDisplay } from './useGuestDisplay';

/**
 * ライブ通訳全体で使用する設定 hooks
 *
 * @returns
 */
export const useLiveInterpreterSetting = () => {
  const { pathname } = useLocation();
  const { language } = useBrowserUserSetting();
  const { uiLang } = useGuestDisplay();

  /**
   * 本システムで使用するUI言語
   */
  const uiLanguage = useMemo(() => {
    if (pathname.includes(PAGE_ROUTE_PATH.GUEST)) {
      // ゲスト画面の場合はゲスト画面の言語を使用
      return uiLang;
    }

    // それ以外は設定ダイアログの言語を使用
    return language;
  }, [language, pathname, uiLang]);

  return {
    uiLanguage,
  };
};
