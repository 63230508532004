import {
  query,
  collection,
  doc,
  onSnapshot,
  orderBy,
  Unsubscribe,
  where,
} from 'firebase/firestore';
import { useCallback, useEffect, useRef } from 'react';

import {
  DocErrorFunction,
  FIRESTORE_PATH,
  StreamingData,
} from '@/constants/firestore';
import { firestore } from '@/utils/firebases/firebaseFirestore';

/**
 * 対象ドキュメントの追加を検知したら実行したい関数の型
 */
export type StreamingAddFunction = (streamingData: StreamingData) => void;

/**
 * プロパティ
 */
type Props = {
  // 監視対象のFirestoreのドキュメントID
  documentId: string;
  // 指定中の翻訳先言語
  destlang: string;
  // ストリーミング翻訳に追加されたデータを受け取る
  onResultAddData: StreamingAddFunction;
  // firestoreへの接続失敗・切断されたことを受け取る
  onDocError: DocErrorFunction;
};

/**
 * Firestore ストリーミング翻訳 監視 hooks
 * @param documentId 監視対象のFirestoreのドキュメントID
 * @param destlang 指定中の翻訳先言語
 * @param onDocChanges ストリーミング翻訳の変更結果を受け取る
 * @param onDocError firestoreへの接続失敗・切断されたことを受け取る
 */
export const useStreamingCollection = ({
  documentId,
  destlang,
  onResultAddData,
  onDocError,
}: Props) => {
  const unsubscribeRef = useRef<Unsubscribe | undefined>(undefined);

  /**
   * onResultAddDataをRefで管理
   * ※Refで保持することでキャプチャされても関数内の値が最新のものになる
   */
  const onResultAddDataFuncRef = useRef<StreamingAddFunction>(onResultAddData);
  useEffect(() => {
    onResultAddDataFuncRef.current = onResultAddData;
  }, [onResultAddData]);

  /**
   * onDocErrorをRefで管理
   * ※Refで保持することでキャプチャされても関数内の値が最新のものになる
   */
  const onDocErrorRef = useRef<DocErrorFunction>(onDocError);
  useEffect(() => {
    onDocErrorRef.current = onDocError;
  }, [onDocError]);

  /**
   * Firestoreの「ストリーミング翻訳」を監視する
   */
  const subscribeStreaming = useCallback(() => {
    // すでに監視済の場合はスキップ
    if (unsubscribeRef.current) {
      return;
    }

    const docRef = doc(
      firestore,
      FIRESTORE_PATH.COLLECTION.ITEMS,
      documentId,
      FIRESTORE_PATH.COLLECTION.INTERIM_TEXTS,
      FIRESTORE_PATH.COLLECTION.LANGUAGES,
    );

    // クエリ
    const queryStreaming = query(
      collection(docRef, destlang),
      // 入室した瞬間以降を取得する
      where('timestamp', '>', new Date()),
      // 最新が一番下
      orderBy('timestamp', 'asc'),
    );

    // 監視開始
    unsubscribeRef.current = onSnapshot(
      queryStreaming,
      async (textSnapshot) => {
        await Promise.all(
          textSnapshot.docChanges().map(async (change) => {
            if (change.type !== 'added') {
              return;
            }

            const docData = change.doc.data();
            if (!docData) {
              return;
            }
            const json = JSON.stringify(docData);
            const streamingData: StreamingData = JSON.parse(json);
            if (!streamingData) {
              return;
            }
            onResultAddDataFuncRef.current?.(streamingData);
          }),
        );
      },
      async (e) => {
        if (documentId && e.code === 'permission-denied') {
          return; // 監視成功後にdocumentIdがある(Firestoreにログイン済)&&権限エラーになった場合はスキップ
        }
        onDocErrorRef.current?.();
      },
    );
  }, [destlang, documentId]);

  /**
   * Firestoreのデータ監視をデタッチする
   */
  const unsubscribeStreaming = useCallback(
    () => unsubscribeRef.current?.(),
    [],
  );

  /**
   * マウント/アンマウント
   */
  useEffect(() => {
    // マウント時に監視を開始
    subscribeStreaming();

    return () => {
      // アンマウント時に解放
      unsubscribeStreaming();
    };
    // マウント時の1度だけ実行したいので無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    // Firestoreの「ストリーミング翻訳」を監視する
    subscribeStreaming,
  };
};
