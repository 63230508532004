import { useCallback, useEffect } from 'react';

import {
  MIC_STATUS,
  SHARE_DISPLAY_STATUS,
  STT_STATUS,
} from '@/states/slices/translationInfoSlice';
import { addBeforeunloadEvent, removeBeforeunloadEvent } from '@/utils';

import { useTranslationInfo } from '../../../hooks/useTranslationInfo';

/**
 * タブを閉じる、ブラウザを閉じる、更新を検知して警告ダイアログを表示するカスタムフック
 *
 * ※beforeunloadイベントはユーザジェスチャーがないと発生しない
 * ※翻訳画面でのみ使用
 *
 * @param onLeave 画面から離れるときに実行したい処理
 */
export const useBeforeUnload = () => {
  const { micStatus, shareDisplayStatus, sttStatus } = useTranslationInfo();

  /**
   * マイク許可失敗、共有失敗、STT失敗のいずれかであるか否か
   *
   * @returns true=いずれかに失敗している
   */
  const hasError = useCallback((): boolean => {
    if (sttStatus === STT_STATUS.ERROR) {
      return true;
    }

    if (micStatus === MIC_STATUS.ERROR) {
      return true;
    }

    if (shareDisplayStatus === SHARE_DISPLAY_STATUS.ERROR) {
      return true;
    }

    return false;
  }, [micStatus, shareDisplayStatus, sttStatus]);

  useEffect(() => {
    // エラーダイアログを経由してページを離れる場合は警告ダイアログは表示しない
    if (!hasError()) {
      addBeforeunloadEvent();
    }

    return () => {
      removeBeforeunloadEvent();
    };
  }, [hasError]);
};
