import { Reducer, useCallback, useReducer } from 'react';

import { ApiStatus, API_STATUS } from '@/constants';

import {
  langUpdateApi,
  LangUpdateApiResponse,
  isLangUpdateApiResultCode,
  LANG_UPDATE_API_RESULT_CODE,
  LangUpdateApiRequest,
} from '../langUpdateApi';

/**
 * State
 */
export type langUpdateState = {
  // レスポンスデータ
  data?: LangUpdateApiResponse;
  // APIの呼び出し状態
  status: ApiStatus;
};

/**
 * Actionタイプ
 */
const LANG_UPDATE_ACTION_TYPE = {
  SET_LANG_UPDATE: 'SET_LANG_UPDATE',
  SET_LANG_UPDATE_SUCCESS: 'SET_LANG_UPDATE_SUCCESS',
  SET_LANG_UPDATE_FAILED: 'SET_LANG_UPDATE_FAILED',
} as const;

/**
 * Action
 */
type langUpdateAction = {
  data?: LangUpdateApiResponse;
  type: keyof typeof LANG_UPDATE_ACTION_TYPE;
};

/**
 * reducer関数
 *
 * @param state
 * @param action
 * @returns
 */
const reducer: Reducer<langUpdateState, langUpdateAction> = (
  state: langUpdateState,
  action: langUpdateAction,
) => {
  switch (action.type) {
    case LANG_UPDATE_ACTION_TYPE.SET_LANG_UPDATE:
      return {
        ...state,
        status: API_STATUS.LOADING,
      };

    case LANG_UPDATE_ACTION_TYPE.SET_LANG_UPDATE_SUCCESS:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.SUCCESS,
      };

    case LANG_UPDATE_ACTION_TYPE.SET_LANG_UPDATE_FAILED:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.FAILED,
      };
    default:
      return state;
  }
};

/**
 * 利用言語更新API 呼び出しhooks
 *
 * @returns
 */
export const useLangUpdateApi = () => {
  const [state, dispatch] = useReducer(reducer, {
    data: undefined,
    status: API_STATUS.IDLE,
  });

  /**
   * API呼び出し
   */
  const fetchLangUpdate = useCallback(
    (request: LangUpdateApiRequest) => {
      if (state.status === API_STATUS.LOADING) {
        return;
      }

      dispatch({ type: LANG_UPDATE_ACTION_TYPE.SET_LANG_UPDATE });

      langUpdateApi(request)
        .then((response: LangUpdateApiResponse) => {
          // 成功
          if (response.resultCode === LANG_UPDATE_API_RESULT_CODE.OK) {
            dispatch({
              type: LANG_UPDATE_ACTION_TYPE.SET_LANG_UPDATE_SUCCESS,
              data: response,
            });

            return;
          }

          // 意図しない結果コードの型だった場合は失敗とする
          if (!isLangUpdateApiResultCode(response.resultCode)) {
            dispatch({
              type: LANG_UPDATE_ACTION_TYPE.SET_LANG_UPDATE_FAILED,
              data: {
                ...response,
                resultCode: LANG_UPDATE_API_RESULT_CODE.ERR_UNKNOWN,
              } as LangUpdateApiResponse,
            });

            return;
          }

          // 失敗
          dispatch({
            type: LANG_UPDATE_ACTION_TYPE.SET_LANG_UPDATE_FAILED,
            data: response,
          });
        })
        .catch((_) => {
          dispatch({
            type: LANG_UPDATE_ACTION_TYPE.SET_LANG_UPDATE_FAILED,
            data: {
              resultCode: LANG_UPDATE_API_RESULT_CODE.ERR_UNKNOWN,
            } as LangUpdateApiResponse,
          });
        });
    },
    [state.status],
  );

  return {
    langUpdateState: state,
    fetchLangUpdate,
  };
};
