// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  DIALOG_BUTTON_TYPE,
  ModalDialog,
  DialogMessageContents,
  SpinnerOverlayView,
} from '@/components/Elements';
import { API_STATUS } from '@/constants';
import { LOGOUT_API_RESULT_CODE } from '@/features/api';
import { AccessExpiredDialog } from '@/features/expired';

import { useLogoutDialog } from '../hooks/useLogoutDialog';

/**
 * ログアウトダイアログ プロパティ
 */
type LogoutDialog = {
  // ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // ダイアログを閉じる
  onClose: () => void;
};

/**
 * ログアウトダイアログ
 */
export const LogoutDialog = React.memo(({ isOpen, onClose }: LogoutDialog) => {
  const { t } = useTranslation();
  const { doLogoutForSerial, logoutStatus, logoutResultCode } = useLogoutDialog(
    {
      onCompleteFun: onClose,
    },
  );

  const logoutDialogButtons = [
    {
      label: t('logout.キャンセル'),
      onClick: onClose,
      type: DIALOG_BUTTON_TYPE.CHANCEL,
    },

    {
      label: t('logout.ログアウト'),
      onClick: () => doLogoutForSerial(),
      type: DIALOG_BUTTON_TYPE.WARNING,
    },
  ];

  return logoutStatus === API_STATUS.FAILED &&
    logoutResultCode === LOGOUT_API_RESULT_CODE.WARN_AUTH ? (
    // アクセス期限切れダイアログを表示(必ず表示するのでisOpenにtrueを直指定)
    <AccessExpiredDialog isOpen />
  ) : (
    <>
      {/* ログアウトダイアログを表示 */}
      <SpinnerOverlayView isOpen={logoutStatus === API_STATUS.LOADING} />
      <ModalDialog
        isOpen={isOpen}
        isHiddenCloseButton
        onClickClose={onClose}
        title={t('logout.ログアウトしますか？').toString()}
        buttons={logoutDialogButtons}
      >
        <DialogMessageContents>
          {t('logout.ログアウトで設定と通訳履歴が削除されます。')}
        </DialogMessageContents>
      </ModalDialog>
    </>
  );
});
