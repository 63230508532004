/**
 * シリアル画面への遷移時に渡す値
 */
export type SerialState = {
  needsBack: boolean; // ヘッダーに戻るボタンの表示が必要かどうか（true=必要）
};

export const isSerialStateType = (
  target: SerialState | unknown,
): target is SerialState => {
  if (typeof target !== 'boolean') {
    return false;
  }

  return true;
};
