import React from 'react';
import { useTranslation } from 'react-i18next';

import { PlanDialog } from '@/features/plan';
import useOpen from '@/hooks/useOpen';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { convertMillisToMinutes } from '@/utils/date';

import styles from './FreePlanButton.module.scss';

/**
 * お試しプラン利用時の利用可能残時間ボタン コンポーネント
 */
export const FreePlanButton = React.memo(() => {
  const { remainingTime } = useTranslationInfo();
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useOpen();

  return (
    <>
      <div
        role="button"
        tabIndex={-1}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <div className={styles['free-plan-container']}>
          <button
            type="button"
            onClick={onOpen}
            className={styles['free-plan-button']}
          >
            <div className={styles['free-plan-label']}>
              <p>
                {t('plan.残り{num}分', {
                  num: convertMillisToMinutes(remainingTime),
                })}
              </p>
              <p>{t('plan.購入')}</p>
            </div>
            <span className={styles['forward-icon']} />
          </button>
        </div>
      </div>

      {/* useOpenのisOpenでレンダリング制御しているため、常にtrue */}
      {isOpen && <PlanDialog isOpen onClose={onClose} />}
    </>
  );
});
