import { useCallback, useEffect, useMemo, useRef } from 'react';

import { RecognizedItem } from '@/constants';
import { useBrowserHistory } from '@/hooks/useBrowserHistory';
import { usePrevious } from '@/hooks/usePrevious';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { BrowserHistory } from '@/states/slices/browserHistorySlice';

import { useCSVDownload } from './useCSVDownload';

/**
 * 通訳履歴ダウンロード管理 カスタムフック
 *
 * @returns
 */
export const useHistoryDownload = () => {
  const {
    isHistory,
    changeIsHistory,
    clearAllHistoryList,
    pushAllHistoryList,
    updateHistory,
    updateHistoryOnRecognizedItems,
  } = useBrowserHistory();
  const { recognizedList } = useTranslationInfo();
  const { convertDataToCommaSeparateStr, downloadCSVDataWithBom } =
    useCSVDownload();

  /**
   * 履歴を保存するか否かを監視してuseRefに格納
   *
   * イベントリスナー内のReduxはキャプチャされた値が使われてしまうので
   * useRefを使って現行の値を参照できるようにする
   */
  const isHistoryRef = useRef<boolean>(isHistory);

  /**
   * 翻訳一覧の変更を監視してuseRefに格納
   *
   * recognizedListが変わるたびにisHistoryが監視されてしまうため
   */
  const recognizedListRef = useRef<RecognizedItem[]>(recognizedList);
  useEffect(() => {
    recognizedListRef.current = recognizedList;
  }, [recognizedList]);

  /**
   * 最新の翻訳結果を翻訳履歴に追加する
   */
  const addNewRecognizedItemToHistory = useCallback(
    (recognizedItem: RecognizedItem) => {
      if (!isHistoryRef.current) {
        return;
      }

      updateHistory(recognizedItem);
    },
    [updateHistory],
  );

  /**
   * ボディ部分のCSVデータ生成
   */
  const bodyCSVData = useMemo(() => {
    // ローカルストレージの通訳履歴一覧を書き込み
    const browserHistoryList: BrowserHistory[] = pushAllHistoryList();

    // 英文などテキストの途中にカンマがあると意図せず区切られてしまうので一旦配列に格納する
    const dataArray: string[][] = [];
    // 日付グループごとの配列を1つの配列にまとめる
    // 出力するデータを日時順にソートする
    browserHistoryList.forEach((browserHistory) => {
      const sortedList = browserHistory.list.sort(
        (a, b) => Date.parse(a.通訳日時) - Date.parse(b.通訳日時),
      );
      sortedList.forEach((historyData) => {
        dataArray.push([
          `${historyData.通訳日時}`,
          `${historyData.通訳元言語}`,
          `${historyData.通訳元テキスト}`,
          `${historyData.通訳先言語}`,
          `${historyData.通訳先テキスト}`,
        ]);
      });
    });

    // 配列の中身を取り出してカンマ区切りにする
    return convertDataToCommaSeparateStr(dataArray);
  }, [convertDataToCommaSeparateStr, pushAllHistoryList]);

  /**
   * [ダウンロード]ボタンのクリック処理
   */
  const onClickDownload = useCallback(() => {
    // CSVダウンロード
    downloadCSVDataWithBom(bodyCSVData);
  }, [bodyCSVData, downloadCSVDataWithBom]);

  /**
   * 通訳履歴を残すトグルボタンを押下した際の処理
   */
  const onClickToggleButton = () => {
    changeIsHistory(!isHistoryRef.current);
  };

  // 変更前のisHistory
  const previousValue: boolean | undefined = usePrevious<boolean>(isHistory);

  /**
   * 通訳履歴を保存するか否かの変更値を監視
   */
  useEffect(() => {
    const procWhenIsHistoryChange = () => {
      if (isHistory === previousValue) {
        return; // 値変わってなかったら何もしない
      }

      if (!isHistory) {
        // 無効化された(OFFになった)場合は履歴を全て削除(確認ダイアログの表示なし)
        clearAllHistoryList();
      }

      if (isHistory && previousValue === false) {
        // OFFからONに変わったタイミングでのみ画面表示の翻訳一覧を追加する
        updateHistoryOnRecognizedItems(recognizedListRef.current);
      }
    };

    procWhenIsHistoryChange();
    isHistoryRef.current = isHistory;
  }, [
    clearAllHistoryList,
    isHistory,
    previousValue,
    updateHistoryOnRecognizedItems,
  ]);

  return {
    addNewRecognizedItemToHistory,
    onClickDownload,
    onClickToggleButton,
  };
};
