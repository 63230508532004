import { Reducer, useCallback, useReducer } from 'react';

import { ApiStatus, API_STATUS } from '@/constants';

import {
  viewInfoApi,
  ViewInfoApiResponse,
  isViewInfoApiResultCode,
  VIEW_INFO_API_RESULT_CODE,
} from '../viewInfoApi';

/**
 * State
 */
export type ViewInfoState = {
  // レスポンスデータ
  data?: ViewInfoApiResponse;
  // APIの呼び出し状態
  status: ApiStatus;
};

/**
 * Actionタイプ
 */
const VIEW_INFO_ACTION_TYPE = {
  SET_VIEW_INFO: 'SET_VIEW_INFO',
  SET_VIEW_INFO_SUCCESS: 'SET_VIEW_INFO_SUCCESS',
  SET_VIEW_INFO_FAILED: 'SET_VIEW_INFO_FAILED',
  SET_VIEW_INFO_IDLE: 'SET_VIEW_INFO_IDLE',
} as const;

/**
 * Action
 */
type ViewInfoAction = {
  data?: ViewInfoApiResponse;
  type: keyof typeof VIEW_INFO_ACTION_TYPE;
};

/**
 * reducer関数
 *
 * @param state
 * @param action
 * @returns
 */
const reducer: Reducer<ViewInfoState, ViewInfoAction> = (
  state: ViewInfoState,
  action: ViewInfoAction,
) => {
  switch (action.type) {
    case VIEW_INFO_ACTION_TYPE.SET_VIEW_INFO:
      return {
        ...state,
        status: API_STATUS.LOADING,
      };

    case VIEW_INFO_ACTION_TYPE.SET_VIEW_INFO_SUCCESS:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.SUCCESS,
      };

    case VIEW_INFO_ACTION_TYPE.SET_VIEW_INFO_FAILED:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.FAILED,
      };

    case VIEW_INFO_ACTION_TYPE.SET_VIEW_INFO_IDLE:
      return {
        ...state,
        status: API_STATUS.IDLE,
      };
    default:
      return state;
  }
};

/**
 * 共有画面情報取得API 呼び出しhooks
 *
 * @returns
 */
export const useViewInfoApi = () => {
  const [state, dispatch] = useReducer(reducer, {
    data: undefined,
    status: API_STATUS.IDLE,
  });

  /**
   * API呼び出し
   */
  const fetchViewInfo = useCallback(() => {
    if (state.status === API_STATUS.LOADING) {
      return;
    }

    dispatch({ type: VIEW_INFO_ACTION_TYPE.SET_VIEW_INFO });

    viewInfoApi()
      .then((response: ViewInfoApiResponse) => {
        // 成功
        if (response.resultCode === VIEW_INFO_API_RESULT_CODE.OK) {
          dispatch({
            type: VIEW_INFO_ACTION_TYPE.SET_VIEW_INFO_SUCCESS,
            data: response,
          });

          return;
        }

        // 意図しない結果コードの型だった場合は失敗とする
        if (!isViewInfoApiResultCode(response.resultCode)) {
          dispatch({
            type: VIEW_INFO_ACTION_TYPE.SET_VIEW_INFO_FAILED,
            data: {
              ...response,
              resultCode: VIEW_INFO_API_RESULT_CODE.ERR_UNKNOWN,
            } as ViewInfoApiResponse,
          });

          return;
        }

        // 失敗
        dispatch({
          type: VIEW_INFO_ACTION_TYPE.SET_VIEW_INFO_FAILED,
          data: response,
        });
      })
      .catch((_) => {
        dispatch({
          type: VIEW_INFO_ACTION_TYPE.SET_VIEW_INFO_FAILED,
          data: {
            resultCode: VIEW_INFO_API_RESULT_CODE.ERR_UNKNOWN,
          } as ViewInfoApiResponse,
        });
      });
  }, [state.status]);

  /**
   * APIのステータスをリセットする
   */
  const resetViewInfoState = useCallback(() => {
    dispatch({ type: VIEW_INFO_ACTION_TYPE.SET_VIEW_INFO_IDLE });
  }, []);

  return {
    viewInfoState: state,
    fetchViewInfo,
    resetViewInfoState,
  };
};
