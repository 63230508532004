import React, { ReactNode } from 'react';

import styles from './PopupWrapper.module.scss';

/**
 * 使い方
 * <PopupDetails>
 *  <PopupSummary>
 *   メニューを開くボタンのデザイン
 *  </PopupSummary>
 *  <PopupContents>
 *    開いた時のメニューのデザイン
 *  </PopupContents>
 * </PopupDetails>
 */
export const POPUP_CONTENT_ALIGN_POSITION = {
  RIGHT: 'right',
  LEFT: 'left',
  LEFT_TOP: 'left-top',
};
type PopupContentsAlignPosition =
  (typeof POPUP_CONTENT_ALIGN_POSITION)[keyof typeof POPUP_CONTENT_ALIGN_POSITION];

type SummaryProps = {
  children: ReactNode;
  disabled?: boolean;
};

/**
 * ポップアップで使用するsummaryでラップする
 */
export const PopupSummary = React.memo<SummaryProps>(
  ({ children, disabled = false }) => (
    <summary
      data-testid="popup-summary"
      tabIndex={disabled ? -1 : 0}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();
        }
      }}
    >
      {children}
    </summary>
  ),
);

type ContentsProps = {
  children: ReactNode;
  align?: PopupContentsAlignPosition;
};

/**
 * ポップアップで表示するコンテンツ
 */
export const PopupContents = React.memo<ContentsProps>(
  ({ children, align = POPUP_CONTENT_ALIGN_POSITION.LEFT }) => (
    <div className={`${styles['popup-contents']} ${styles[align]}`}>
      {children}
    </div>
  ),
);

/**
 * プロパティ
 */
type PopupDetailsProps = {
  isOpen?: boolean;
  detailsRef?: React.MutableRefObject<HTMLDetailsElement | null>;
  disabled?: boolean;
  children: ReactNode;
  // Toggle時の処理
  onToggle?: (e: React.SyntheticEvent<HTMLDetailsElement, Event>) => void;
};

/**
 * Detailsをポップアップないクリックで閉じるために、refを受け取るようにする
 */
export const PopupDetails = React.memo<PopupDetailsProps>(
  ({
    isOpen = false,
    children,
    detailsRef = null,
    disabled = false,
    onToggle,
  }) => (
    <div className={`${styles['details-container']}`}>
      {
        // detailsクリック時に親要素のイベント発火させたくないので無効にする
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <details
          data-testid="popup-details"
          ref={detailsRef}
          open={isOpen}
          onToggle={onToggle}
          className={`${styles['popup-container']} ${
            disabled ? styles.disabled : ''
          }`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {children}
        </details>
      }
    </div>
  ),
);
