/**
 * Cookieを取得・保存するパス
 */
const COOKIE_ROOT_PATH = '/';

/**
 * Cookie情報
 */
export type CookieInfo = {
  // キー
  key: string;
  // 値
  value: string;
};

/**
 * Cookie情報MAPを作成
 */
const createCookieMap = () => {
  const cookieMap = new Map();
  const cookies = document.cookie.split(';');

  cookies.forEach((info) => {
    if (!info) {
      return;
    }
    const array = info.split('=');
    if (!array || array.length < 2) {
      return;
    }
    cookieMap.set(array[0].trim(), array[1].trim());
  });

  return cookieMap;
};

/**
 * Cookie情報MAPから指定されたkeyの値を取得
 */
export const findCookieValue = (key: string): string => {
  const value = createCookieMap().get(key);
  if (!value) {
    return '';
  }

  return value;
};

/**
 * 指定されたkey=valueをCookieに保存
 *
 * ※保存対象のパスは「/」固定
 */
export const setupCookie = (key: string, value: string) => {
  document.cookie = `${key}=${value}; path=${COOKIE_ROOT_PATH}`;
};
