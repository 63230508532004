import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import setting from '@/assets/setting.svg';
import { SettingDialog } from '@/features/setting';
import useOpen from '@/hooks/useOpen';

import { useTranslationPath } from '../../hooks/useTranslationPath';

/**
 * 設定メニュー プロパティ
 */
type SettingMenuContainer = {
  // 設定メニューを閉じる
  onCloseMenu: () => void;
  // ライセンス情報ダイアログを開く
  onOpenLicenseDialog: () => void;
  // アクセス期限切れダイアログを表示
  onOpenAccessExpDialog: () => void;
};

/**
 * ヘッダー > メインメニュー > 設定メニュー
 */
export const SettingMenuContainer = React.memo(
  ({
    onCloseMenu,
    onOpenLicenseDialog,
    onOpenAccessExpDialog,
  }: SettingMenuContainer) => {
    const { isOpen, onClose, onOpen } = useOpen();
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { isTranslationPath } = useTranslationPath();

    return (
      <>
        <button
          type="button"
          onClick={() => {
            onOpen();
            onCloseMenu();
          }}
          className={isTranslationPath(pathname) ? 'dts-transconf' : 'dts-conf'}
        >
          <img src={setting} alt="setting" />
          {t('mainMenu.一般')}
        </button>

        {/* useOpenのisOpenでレンダリング制御しているため、常にtrue */}
        {isOpen && (
          <SettingDialog
            isOpen
            onClose={onClose}
            onOpenLicenseDialog={onOpenLicenseDialog}
            onOpenAccessExpDialog={onOpenAccessExpDialog}
          />
        )}
      </>
    );
  },
);
