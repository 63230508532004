import { useCallback } from 'react';

import { LIST_DISPLAY_TYPE, ListDisplayType } from '@/constants';

/**
 * プロパティ
 */
type Props = {
  // 現在の表示タイプ
  currentDisplayType: ListDisplayType;
  // 表示切り替え時に実行したい処理
  onChangeDisplayTypeFunc: (value: ListDisplayType) => void;
};

/**
 * 本カスタムフックからの返却値
 */
export type Value = {
  // 表示切り替えボタンがクリックされた場合の処理
  onClickButton: () => void;
};

/**
 * 翻訳元テキストの表示/非表示 切り替えボタン hooks
 * @returns
 */
export const useDisplayTypeButton = ({
  currentDisplayType,
  onChangeDisplayTypeFunc,
}: Props): Value => {
  /**
   * 表示切り替えボタンがクリックされた場合の処理
   */
  const onClickButton = useCallback(() => {
    if (currentDisplayType === LIST_DISPLAY_TYPE.onePhrase) {
      onChangeDisplayTypeFunc(LIST_DISPLAY_TYPE.list);

      return;
    }
    onChangeDisplayTypeFunc(LIST_DISPLAY_TYPE.onePhrase);
  }, [currentDisplayType, onChangeDisplayTypeFunc]);

  return {
    onClickButton,
  };
};
