import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import download from '@/assets/download.svg';

import useOpen from '../../../../hooks/useOpen';
import { useTranslationPath } from '../../hooks/useTranslationPath';

import { HistoryDialog } from './HistoryDialog';

/**
 * 通訳履歴をダウンロードメニュー プロパティ
 */
type HistoryDownloadContainer = {
  onCloseMenu: () => void;
};
/**
 * ヘッダー > メインメニュー > 通訳履歴をダウンロードメニュー
 */
export const HistoryDownloadContainer = React.memo(
  ({ onCloseMenu }: HistoryDownloadContainer) => {
    const { isOpen, onClose, onOpen } = useOpen();
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { isTranslationPath } = useTranslationPath();

    return (
      <>
        <button
          type="button"
          onClick={() => {
            onOpen();
            onCloseMenu();
          }}
          className={
            isTranslationPath(pathname) ? 'dts-transdl' : 'dts-download'
          }
        >
          <img src={download} alt="download" />
          {t('mainMenu.通訳履歴をダウンロード')}
        </button>

        {/* useOpenのisOpenでレンダリング制御しているため、常にtrue */}
        {isOpen && <HistoryDialog isOpen onClose={onClose} />}
      </>
    );
  },
);
