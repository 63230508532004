import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  COOKIE_KEY_NAME,
  COOKIE_USER_TYPE,
  PAGE_ROUTE_PATH,
} from '@/constants';
import { findCookieValue } from '@/utils/cookie';

/**
 * 認証エラー画面 hooks
 *
 * @returns
 */
export const useAuthError = () => {
  const navigate = useNavigate();

  /**
   * ログイン済かどうかを判定
   *
   * @returns true: ログイン済 / false: 未ログイン
   */
  const isLogin = (): boolean => {
    if (findCookieValue(COOKIE_KEY_NAME.PTBR_TOKEN)) {
      return true;
    }

    return false;
  };

  /**
   * シリアルで利用中かどうかを判定
   *
   * @returns true: シリアルで利用中 / false: PTIDで利用中
   */
  const isSerial = (): boolean => {
    if (
      findCookieValue(COOKIE_KEY_NAME.PTBR_TYPE) === COOKIE_USER_TYPE.SERIAL
    ) {
      return true;
    }

    return false;
  };

  /**
   * [再ログインする]ボタンがクリックされた場合の処理
   */
  const clickLoginAgainButton = useCallback(() => {
    if (!isLogin()) {
      // 未ログインの場合は、利用方法選択画面に遷移(同一タブ内)
      navigate(PAGE_ROUTE_PATH.SELECT_USAGE);

      return;
    }

    if (isSerial()) {
      // シリアルで利用中の場合は、ホーム画面に遷移(同一タブ内)
      navigate(PAGE_ROUTE_PATH.HOME);

      return;
    }

    // PTIDで利用中の場合は、ようこそ画面に遷移(同一タブ内)
    navigate(PAGE_ROUTE_PATH.WELCOME);
  }, [navigate]);

  return {
    clickLoginAgainButton,
  };
};
