import React from 'react';
import { useTranslation } from 'react-i18next';

import selectMic from '@/assets/select_mic.svg';
import selectSpeaker from '@/assets/select_speaker.svg';
import { SpinnerOverlayView } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import { API_STATUS } from '@/constants';
import { AccessExpiredDialog } from '@/features/expired';
import { isSupportBrowser } from '@/utils/browser';
import { OS_TYPE, detectOS, isMobile } from '@/utils/device';

import { useHome } from '../hooks/useHome';

import styles from './Home.module.scss';
import { UnSupportBrowserDialogContainer } from './UnSupportBrowserDialogContainer';

/**
 * ホーム画面（モード選択画面） 子コンポーネント
 *
 * @returns
 */
const HomeView = React.memo(() => {
  // hooks
  const {
    tokenAuthStatus,
    clickMicButton,
    clickSpeakerButton,
    expiredSerialLogoutState,
  } = useHome();
  const { t } = useTranslation();

  return (
    <>
      <SpinnerOverlayView
        isOpen={
          tokenAuthStatus === API_STATUS.LOADING ||
          expiredSerialLogoutState === API_STATUS.LOADING
        }
      />
      <UnSupportBrowserDialogContainer />
      <div className={styles.home}>
        <div className={styles['select-translation']}>
          <div className={`${styles['select-button']} dts-start`}>
            {isSupportBrowser() && (
              <button
                type="button"
                disabled={tokenAuthStatus !== API_STATUS.SUCCESS}
                onClick={clickMicButton}
              >
                <div className={styles['select-button-title']}>
                  <p>{t('home.マイクに入る音を通訳')}</p>
                </div>
                <div className={styles['select-mic-button-image']}>
                  <img src={selectMic} alt="selectMic" />
                </div>
              </button>
            )}
          </div>

          {!isMobile() && isSupportBrowser() && (
            <div className={`${styles['select-button']} dts-advconf`}>
              <button
                type="button"
                disabled={tokenAuthStatus !== API_STATUS.SUCCESS}
                onClick={clickSpeakerButton}
              >
                <div className={styles['select-button-title']}>
                  <p>
                    {detectOS() === OS_TYPE.MAC_OS
                      ? t('home.ブラウザの音を通訳')
                      : t('home.PCの音を通訳')}
                  </p>
                </div>
                <div className={styles['select-speaker-button-image']}>
                  <img src={selectSpeaker} alt="selectSpeaker" />
                </div>
              </button>
            </div>
          )}
        </div>
        {/* アクセス期限切れダイアログ */}
        <AccessExpiredDialog isOpen={tokenAuthStatus === API_STATUS.FAILED} />
      </div>
    </>
  );
});

/**
 * ホーム画面（モード選択画面）
 *
 * @returns
 */
export const Home = React.memo(() => (
  <ContentLayout>
    <HomeView />
  </ContentLayout>
));
