import { Reducer, useCallback, useReducer } from 'react';

import { ApiStatus, API_STATUS } from '@/constants';

import {
  licenseInfoApi,
  LicenseInfoApiResponse,
  isLicenseInfoApiResultCode,
  LICENSE_INFO_API_RESULT_CODE,
} from '../licenseInfoApi';

/**
 * State
 */
export type LicenseInfoState = {
  // レスポンスデータ
  data?: LicenseInfoApiResponse;
  // APIの呼び出し状態
  status: ApiStatus;
};

/**
 * Actionタイプ
 */
const LICENSE_INFO_ACTION_TYPE = {
  SET_LICENSE_INFO: 'SET_LICENSE_INFO',
  SET_LICENSE_INFO_SUCCESS: 'SET_LICENSE_INFO_SUCCESS',
  SET_LICENSE_INFO_FAILED: 'SET_LICENSE_INFO_FAILED',
} as const;

/**
 * Action
 */
type LicenseInfoAction = {
  data?: LicenseInfoApiResponse;
  type: keyof typeof LICENSE_INFO_ACTION_TYPE;
};

/**
 * reducer関数
 *
 * @param state
 * @param action
 * @returns
 */
const reducer: Reducer<LicenseInfoState, LicenseInfoAction> = (
  state: LicenseInfoState,
  action: LicenseInfoAction,
) => {
  switch (action.type) {
    case LICENSE_INFO_ACTION_TYPE.SET_LICENSE_INFO:
      return {
        ...state,
        status: API_STATUS.LOADING,
      };
    case LICENSE_INFO_ACTION_TYPE.SET_LICENSE_INFO_SUCCESS:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.SUCCESS,
      };
    case LICENSE_INFO_ACTION_TYPE.SET_LICENSE_INFO_FAILED:
      return {
        ...state,
        data: action.data,
        status: API_STATUS.FAILED,
      };
    default:
      return state;
  }
};

/**
 * ライセンス確認API 呼び出しhooks
 *
 * @returns
 */
export const useLicenseInfoApi = () => {
  const [state, dispatch] = useReducer(reducer, {
    data: undefined,
    status: API_STATUS.IDLE,
  });

  /**
   * API呼び出し
   */
  const fetchLicenseInfo = useCallback(() => {
    if (state.status === API_STATUS.LOADING) {
      return;
    }

    dispatch({ type: LICENSE_INFO_ACTION_TYPE.SET_LICENSE_INFO });

    licenseInfoApi()
      .then((response: LicenseInfoApiResponse) => {
        // 成功
        if (response.resultCode === LICENSE_INFO_API_RESULT_CODE.OK) {
          dispatch({
            type: LICENSE_INFO_ACTION_TYPE.SET_LICENSE_INFO_SUCCESS,
            data: response,
          });

          return;
        }

        // 意図しない結果コードの型だった場合は失敗とする
        if (!isLicenseInfoApiResultCode(response.resultCode)) {
          dispatch({
            type: LICENSE_INFO_ACTION_TYPE.SET_LICENSE_INFO_FAILED,
            data: {
              ...response,
              resultCode: LICENSE_INFO_API_RESULT_CODE.ERR_UNKNOWN,
            } as LicenseInfoApiResponse,
          });

          return;
        }

        // 失敗
        dispatch({
          type: LICENSE_INFO_ACTION_TYPE.SET_LICENSE_INFO_FAILED,
          data: response,
        });
      })
      .catch((_) => {
        dispatch({
          type: LICENSE_INFO_ACTION_TYPE.SET_LICENSE_INFO_FAILED,
          data: {
            resultCode: LICENSE_INFO_API_RESULT_CODE.ERR_UNKNOWN,
          } as LicenseInfoApiResponse,
        });
      });
  }, [state.status]);

  return {
    licenseInfoState: state,
    fetchLicenseInfo,
  };
};
