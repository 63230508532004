import crypto from 'crypto-js';

const PRIVATE_KEY = 'z28QpkE_b4e-xt96W6k5mex_dj2Hiy';

/**
 * キーをもとに暗号化
 *
 * @param text
 * @returns
 */
export const encryptStr = (text: string): string => {
  const encrypted = crypto.AES.encrypt(text, PRIVATE_KEY);

  return encrypted.toString();
};

/**
 * キーをもとに複合化
 *
 * @param encryptedData
 * @returns
 */
export const decryptStr = (encryptedData: string): string => {
  const decrypted = crypto.AES.decrypt(encryptedData, PRIVATE_KEY);

  return decrypted.toString(crypto.enc.Utf8);
};
