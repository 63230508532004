import { BROWSER } from '@/constants';
import { browserAxios } from '@/lib/axios';

import { API_AUTH_RESULT_CODE } from './apiAuth';

/**
 * 結果コード定数
 */
export const LICENSE_CREATE_API_RESULT_CODE = {
  ...({
    // 成功
    OK: 'OK',
    // 利用時間上限超過
    INFO_LIMIT_EXCEEDED: 'INFO_LIMIT_EXCEEDED',
    // ライセンス認証失敗
    WARN_INVALID_AUTH: 'WARN_INVALID_AUTH',
    // 外部API呼び出し失敗
    WARN_FAILED_CALL: 'WARN_FAILED_CALL',
    // 不明なエラー
    ERR_UNKNOWN: 'ERR_UNKNOWN',
  } as const),
  ...API_AUTH_RESULT_CODE,
} as const;
export type LicenseCreateApiResultCode =
  (typeof LICENSE_CREATE_API_RESULT_CODE)[keyof typeof LICENSE_CREATE_API_RESULT_CODE];

/**
 * 結果コードのTypeGuard
 *
 * @param resultCode
 * @returns
 */
export const isLicenseCreateApiResultCode = (
  resultCode: string,
): resultCode is LicenseCreateApiResultCode => {
  if (resultCode in LICENSE_CREATE_API_RESULT_CODE) {
    return true;
  }

  return false;
};

/**
 * レスポンス
 */
export type LicenseCreateApiResponse = {
  // 結果コード
  resultCode: LicenseCreateApiResultCode;
  // ライセンストークン(JSON Web Token形式)
  token: string;
};

/**
 * API 呼び出し
 *
 * @returns
 */
const callApi = (): Promise<LicenseCreateApiResponse> => {
  // リクエスト送信(POST)
  const response = browserAxios
    .get<LicenseCreateApiResponse>(BROWSER.API_URL.LICENSE_CREATE, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((apiResponse) => apiResponse.data);

  return response;
};

/**
 * ライセンストークン発行API(同通サーバ) リクエスト
 *
 * @returns
 */
export const licenseCreateApi = (): Promise<LicenseCreateApiResponse> =>
  callApi();
