import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ModalDialog,
  PullDownMenu,
  TextBlock,
} from '@/components/Elements';
import { PERMISSION_STATE } from '@/constants';
import { useBrowserUserSetting } from '@/hooks/useBrowserUserSetting';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { TTS_STATUS } from '@/states/slices/translationInfoSlice';

import { useDeviceMenu } from '../../device/hooks/useDeviceMenu';

import { FeedbackContainer } from './FeedbackContainer';
import { LicenseInfoContainer } from './LicenseInfoContainer';
import styles from './SettingDialog.module.scss';
import { UILanguageContainer } from './UILanguageContainer';

/**
 * 設定ダイアログ プロパティ
 */
type SettingDialog = {
  // 設定ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // 設定ダイアログを閉じる
  onClose: () => void;
  // ライセンス情報ダイアログを開く
  onOpenLicenseDialog: () => void;
  // アクセス期限切れダイアログを開く
  onOpenAccessExpDialog: () => void;
};

/**
 * 設定ダイアログ
 */
export const SettingDialog = React.memo(
  ({
    isOpen,
    onClose,
    onOpenLicenseDialog,
    onOpenAccessExpDialog,
  }: SettingDialog) => {
    const { inputDevice, outputDevice } = useBrowserUserSetting();
    const {
      isChangingOutputAvailable,
      inputDeviceList,
      onChangeInputDevice,
      outputDeviceList,
      onChangeOutputDevice,
      micPermission,
      fetchMicPermission,
    } = useDeviceMenu();
    const { ttsStatus } = useTranslationInfo();
    const { t } = useTranslation();

    return (
      <ModalDialog
        isOpen={isOpen}
        isHiddenCloseButton={false}
        onClickClose={onClose}
        title={t('mainMenu.一般').toString()}
      >
        <div>
          <ul className={styles['setting-menu-list']}>
            <li>
              {/* 出力元デバイス */}
              <div>
                <span
                  className={`${styles['output-device']} ${styles.settings} ${
                    ((isChangingOutputAvailable &&
                      ttsStatus !== TTS_STATUS.READING) ||
                      !isChangingOutputAvailable) &&
                    styles.disabled
                  }`}
                >
                  <PullDownMenu
                    optionItems={outputDeviceList}
                    name="outputDeviceSelect"
                    selectedValue={outputDevice}
                    onChange={onChangeOutputDevice}
                    disabled={
                      !isChangingOutputAvailable ||
                      ttsStatus !== TTS_STATUS.READING
                    }
                  />
                </span>

                {!isChangingOutputAvailable && (
                  <TextBlock
                    text={t(
                      'settingMenu.お使いの環境では変更できません。',
                    ).toString()}
                  />
                )}
                {isChangingOutputAvailable &&
                  ttsStatus !== TTS_STATUS.READING && (
                    <TextBlock
                      text={t(
                        'settingMenu.通訳音声の再生中のみ変更できます。',
                      ).toString()}
                    />
                  )}
              </div>
            </li>
            <li>
              {/* 入力元デバイス */}
              <div className={`${styles['input-device']} ${styles.settings}`}>
                <PullDownMenu
                  optionItems={inputDeviceList}
                  name="inputDeviceSelect"
                  selectedValue={inputDevice}
                  onChange={onChangeInputDevice}
                />
              </div>
            </li>
            <li>
              {/* UI言語メニュー */}
              <div className={`${styles['ui-lang']} ${styles.settings}`}>
                <UILanguageContainer
                  onCloseDialog={onClose}
                  onOpenAccessExpDialog={onOpenAccessExpDialog}
                />
              </div>
            </li>
            {/* フェードバックメニュー */}
            <li>
              <FeedbackContainer onCloseDialog={onClose} />
            </li>
            <li>
              {/* ライセンス情報メニュー */}
              <LicenseInfoContainer
                onCloseDialog={onClose}
                onOpenLicenseDialog={onOpenLicenseDialog}
              />
            </li>
          </ul>
        </div>
        {micPermission !== PERMISSION_STATE.ALLOW && (
          <div className={styles['mic-permission']}>
            <div className={styles['permission-msg']}>
              {t('settingMenu.マイクへのアクセスを許可してください').toString()}
            </div>
            {/* 不許可になっている場合、許可を求めるダイアログを出せない */}
            {micPermission === PERMISSION_STATE.ASK && (
              <Button
                type="button"
                title={t('settingMenu.許可する').toString()}
                onClick={fetchMicPermission}
              />
            )}
          </div>
        )}
      </ModalDialog>
    );
  },
);
