/**
 * API認証失敗時の結果コード
 */
export const API_AUTH_RESULT_CODE = {
  // 認証失敗
  WARN_AUTH: 'WARN_AUTH',
  // IDトークン期限切れ
  INFO_EXPIRED_IDTOKEN: 'INFO_EXPIRED_IDTOKEN',
  // 利用規約の同意が必要
  INFO_NEED_AGREEMENT: 'INFO_NEED_AGREEMENT',
  // 利用規約バージョンマスタの登録に不備がある
  ERR_AGREEMENT_MASTER: 'ERR_AGREEMENT_MASTER',
} as const;
