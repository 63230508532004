import { BROWSER } from '@/constants';
import { browserLangListAxios } from '@/lib/axios';

import { VoiceInfo } from '../texttospeech/constants';

/**
 * Google TTS API 発話情報取得
 * @returns
 */
const get = (): Promise<VoiceInfo> => {
  const url = `${BROWSER.LANG_LIST_URL}/googleTtsVoice.json`;
  const response = browserLangListAxios
    .get<VoiceInfo>(url)
    .then((apiResponse) => apiResponse.data);

  return response;
};

/**
 * Google TTS API 発話情報(同通サーバ) 取得
 * @returns
 */
export const googleTtsVoiceList = (): Promise<VoiceInfo> => get();
