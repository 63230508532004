// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */
import React from 'react';

import {
  ListDisplayType,
  LIST_DISPLAY_TYPE,
  RecognizedItem,
  InterimContent,
  TranslationListViewType,
  TRANSLATION_LIST_VIEW_TYPE,
} from '@/constants';

import styles from './ListView.module.scss';
import { RecognizedListView } from './RecognizedListView';
/**
 * プロパティ
 */
export type TranslationListView = {
  // リアルタイム音声認識結果
  interimContent: InterimContent;
  // 音声認識結果/翻訳結果テキストリストの表示内容
  recognizedList: RecognizedItem[];
  // 表示タイプ
  displayType: ListDisplayType;
  // フォントサイズ
  fontSize?: number;
  // 文字サイズ単位
  point?: string;
  // フッター開閉状況(true=開いている/false=閉じている)
  isDisplayFooter?: boolean;
  // 利用中のプランが「お試しプラン」か否か
  isFreePlan?: boolean;
  // 画面タイプ（ホストorゲスト）
  translationListViewType?: TranslationListViewType;
  // 共有バナーが表示されているか否か
  isShowGuestBannerBottomHeader?: boolean;
  // 共有中か否か
  isShared?: boolean;
};

/**
 * 翻訳テキストビュー
 */
const RecognizedView = React.memo(
  ({
    lists,
    displayType,
    interimContent,
    translationListViewType = TRANSLATION_LIST_VIEW_TYPE.TRANSLATION,
    isShowGuestBannerBottomHeader = false,
  }: {
    lists: RecognizedItem[];
    displayType: ListDisplayType;
    interimContent: InterimContent;
    translationListViewType?: TranslationListViewType;
    isShowGuestBannerBottomHeader?: boolean;
  }) => (
    <RecognizedListView
      lists={lists}
      displayType={displayType}
      interimContent={interimContent}
      translationListViewType={translationListViewType}
      isShowGuestBannerBottomHeader={isShowGuestBannerBottomHeader}
    />
  ),
);

/**
 * 同時通訳リストビュー コンポーネント
 *
 * @param param0
 * @returns
 */
export const TranslationListView = React.memo(
  ({
    interimContent,
    recognizedList,
    displayType,
    fontSize,
    point,
    isDisplayFooter = true,
    isFreePlan = false,
    translationListViewType = TRANSLATION_LIST_VIEW_TYPE.TRANSLATION,
    isShowGuestBannerBottomHeader = false,
    isShared = false,
  }: TranslationListView) => (
    <div
      className={`${styles.content} ${
        isFreePlan &&
        (!isDisplayFooter
          ? styles['free-plan-footer-close']
          : styles['free-plan-footer-open'])
      } ${
        !isFreePlan &&
        (!isDisplayFooter ? styles['footer-close'] : styles['footer-open'])
      } ${
        translationListViewType === TRANSLATION_LIST_VIEW_TYPE.CONFERENCE &&
        styles['footer-guest']
      } ${
        isShared &&
        (!isDisplayFooter
          ? styles['footer-close-shared']
          : styles['footer-open-shared'])
      }`}
    >
      <div
        className={`${styles.text} ${styles.conference}`}
        style={fontSize ? { fontSize: `${fontSize}${point}` } : undefined}
      >
        {displayType === LIST_DISPLAY_TYPE.onePhrase ? (
          <div className={styles['one-phrase']} id="onePhrase">
            <RecognizedView
              lists={recognizedList}
              displayType={displayType}
              interimContent={interimContent}
              translationListViewType={translationListViewType}
              isShowGuestBannerBottomHeader={isShowGuestBannerBottomHeader}
            />
          </div>
        ) : (
          <div className={`${styles.list}`} id="list">
            <RecognizedView
              lists={recognizedList}
              displayType={displayType}
              interimContent={interimContent}
              translationListViewType={translationListViewType}
              isShowGuestBannerBottomHeader={isShowGuestBannerBottomHeader}
            />
          </div>
        )}
      </div>
    </div>
  ),
);
