import React from 'react';

import {
  InterimContent,
  LIST_DISPLAY_TYPE,
  ListDisplayType,
} from '@/constants';

import { InterimView } from './InterimView';

/**
 * プロパティ
 */
export type InterimListView = {
  // 表示タイプ
  displayType: ListDisplayType;
  // 暫定テキスト情報
  interimContent: InterimContent;
};

/**
 * 音声認識テキストリストビューの最下部表示用の
 * 暫定テキストリストビュー コンポーネント
 */
export const InterimListView = React.memo(
  ({ displayType, interimContent }: InterimListView) => (
    <li>
      {displayType === LIST_DISPLAY_TYPE.onePhrase ? (
        <InterimView
          isReversed={interimContent.isReversed}
          langCode={
            interimContent.isReversed
              ? interimContent.srclang
              : interimContent.destlang
          }
          text={
            interimContent.isReversed ? interimContent.stt : interimContent.ttt
          }
          isAutoLeftScrollInterimText={false}
        />
      ) : (
        <>
          <InterimView
            isReversed={!interimContent.isReversed}
            langCode={
              !interimContent.isReversed
                ? interimContent.srclang
                : interimContent.destlang
            }
            text={
              !interimContent.isReversed
                ? interimContent.stt
                : interimContent.ttt
            }
            isAutoLeftScrollInterimText={false}
          />
          <InterimView
            isReversed={interimContent.isReversed}
            langCode={
              !interimContent.isReversed
                ? interimContent.destlang
                : interimContent.srclang
            }
            text={
              !interimContent.isReversed
                ? interimContent.ttt
                : interimContent.stt
            }
            isAutoLeftScrollInterimText={false}
          />
        </>
      )}
    </li>
  ),
);
