/**
 * 対象の音声データが無音かどうか
 * @param data
 * @returns trueのとき、無音
 */
export const isNoSound = (data: ArrayBufferLike) => {
  const buffer = new Int16Array(data);

  const max = Math.max(...buffer);
  const min = Math.min(...buffer);

  // 無音(0)かどうかを判断できれば良いので、音圧(デシベル)を計算しないで
  // bufferの値からそのまま判断する
  if (Math.abs(max) > 1 || Math.abs(min) > 1) {
    // 調査検証時に無音にも関わらずなぜかbufferに1が含まれることがあったので、
    // 1より大きい値が含まれていた場合に無音ではないと判断する
    return false;
  }

  return true;
};
