import {
  UserCredential,
  getAuth,
  signInWithCustomToken,
  signOut,
} from 'firebase/auth';
import { useCallback } from 'react';

import {
  CREDENTIALS_API_RESULT_CODE,
  CredentialsApiRequest,
  CredentialsApiResponse,
  CredentialsApiResultCode,
  credentialsApi,
} from '@/features/api';

/**
 * Firestoreログイン結果
 */
type FirestoreLoginResult = {
  // Firestore接続情報APIの結果コード
  credentialsApiResultCode: CredentialsApiResultCode;
  // ログインに成功したときに取得したuid
  uid: string;
};

/**
 * Firestoreにログインしてuid(FirestoreのドキュメントID)を取得
 *
 * @param urlKey 共有画面のURLキー
 * @returns
 */
const findDocumentIdSignInFirestore = async (
  urlKey: string | undefined,
): Promise<FirestoreLoginResult> => {
  // Firestore接続情報API呼び出し
  const request: CredentialsApiRequest = urlKey ? { urlKey } : {};
  const apiResponse: CredentialsApiResponse = await credentialsApi(
    request,
  ).catch(() => ({
    resultCode: CREDENTIALS_API_RESULT_CODE.ERR_UNKNOWN,
    firestoreToken: '',
  }));

  if (apiResponse.resultCode !== CREDENTIALS_API_RESULT_CODE.OK) {
    return {
      credentialsApiResultCode: apiResponse.resultCode,
      uid: '',
    };
  }

  // Firestoreにログイン
  const auth = getAuth();
  const userCredential: UserCredential | undefined =
    await signInWithCustomToken(auth, apiResponse.firestoreToken).catch(
      () => undefined,
    );
  if (!userCredential) {
    return {
      credentialsApiResultCode: apiResponse.resultCode,
      uid: '',
    };
  }

  return {
    credentialsApiResultCode: apiResponse.resultCode,
    uid: userCredential.user.uid,
  };
};

/**
 * サーバから取得したトークンを使ってFirestoreにログイン
 *
 * @param param0
 */
export const useFirestoreLoginUseToken = () => {
  /**
   * Firestoreにログイン
   *
   * @param urlKey 共有画面のURLキー
   */
  const signInFirestore = useCallback(
    async (urlKey?: string): Promise<FirestoreLoginResult> => {
      const result = await findDocumentIdSignInFirestore(urlKey);

      return result;
    },
    [],
  );

  /**
   * Firestoreからログアウト
   */
  const signOutFirestore = useCallback(() => {
    const auth = getAuth();
    signOut(auth);
  }, []);

  return {
    // Firestoreにログイン
    signInFirestore,
    // Firestoreからログアウト
    signOutFirestore,
  };
};
