export * from './data';
/**
 * firestoreのパス一覧
 */
export const FIRESTORE_PATH = {
  COLLECTION: {
    // ストリーム情報
    ITEMS: 'items',
    // 確定テキスト
    RECOGNIZED_TEXTS: 'recognized_texts',
    // 暫定テキスト
    INTERIM_TEXTS: 'interim_texts',
    // 言語コード
    LANGUAGES: 'languages',
    // 共有画面設定
    VIEW: 'view',
  },
  DOCUMENT: {
    CONFIG: 'config',
  },
} as const;

/**
 * ドキュメントの監視に失敗したら実行したい関数の型
 */
export type DocErrorFunction = () => void;
