/* eslint-disable react/require-default-props */
import React from 'react';

import { Header } from '@/features/header';

import { FooterLayout } from '../FooterLayout';

import styles from './ContentLayout.module.scss';

/**
 * プロパティ
 */
export type ContentLayout = {
  children: React.ReactNode;
  // フッターコンポーネント
  footerChildren?: React.ReactNode;
};

type ContentLayoutView = {
  children: React.ReactNode;
};

/**
 * コンテンツビュー コンポーネント
 * @param param0
 * @returns
 */
const ContentLayoutView = ({ children }: ContentLayoutView) => (
  <div
    data-testid="content-layout-children"
    className={`${styles['content-layout']} ${styles['header-display']}`}
  >
    {children}
  </div>
);

/**
 * コンテンツ コンポーネント
 *
 * @param param0
 * @returns
 */
export const ContentLayout = React.memo(
  ({ children, footerChildren }: ContentLayout) => (
    <>
      <Header />
      <ContentLayoutView>{children}</ContentLayoutView>
      {footerChildren ?? <FooterLayout>{footerChildren}</FooterLayout>}
    </>
  ),
);
