import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useBrowserUserSetting } from '@/hooks/useBrowserUserSetting';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { MIC_STATUS } from '@/states/slices/translationInfoSlice';

import { USER_MEDIA_CONSTRAINTS } from '../constants';

/**
 * 本カスタムフックからの返却値
 */
export type UseMicValue = {
  // マイク利用開始リクエスト
  requestStartMic: (callback: (data: Int16Array) => void) => Promise<void>;
  // マイク利用終了
  stopMic: () => void;
  // 収録開始
  startRecording: () => void;
  // 収録停止
  stopRecording: () => void;
};

/**
 * マイク hooks
 *
 * @returns
 */
export const useMic = (): UseMicValue => {
  const mediaStream = useRef<MediaStream | null>(null);
  const audioContext = useRef<AudioContext | null>(null);
  const inputStream = useRef<MediaStreamAudioSourceNode | undefined>(undefined);
  const audioWorklet = useRef<AudioWorkletNode | null>(null);

  const { micStatus, setMicStatus } = useTranslationInfo();
  const { inputDevice } = useBrowserUserSetting();

  /**
   * 要求するメディアの種類
   */
  const mediaRequestTypes = useMemo(
    () => ({
      audio: {
        channelCount: USER_MEDIA_CONSTRAINTS.DEFAULT_CHANNELS,
        sampleRate: USER_MEDIA_CONSTRAINTS.DEFAULT_SAMPLE_RATE,
        deviceId: inputDevice,
        // ループバックするように、あえてfalseを指定する。
        echoCancellation: false,
      },
      video: false,
    }),
    [inputDevice],
  );

  /**
   * 音声データのオブジェクトがundefinedかチェックする
   *
   * @returns true=undefined
   */
  const isAudioObjectsClose = (): boolean => {
    if (
      audioWorklet.current ||
      inputStream.current ||
      audioContext.current ||
      mediaStream.current
    ) {
      return false;
    }

    return true;
  };

  /**
   * マイク利用開始リクエスト
   * ※AudioWorkletを含むWorkletを使うページはHTTPSまたはhttp://localhostでのみ利用可能
   *
   * @param callback 音声入力結果を返却
   * @param deviceId デバイスID
   */
  const requestStartMic = async (callback: (data: Int16Array) => void) => {
    // 生成されたオブジェクトが残っていないかをチェック
    // 後々字幕アプリのコードに合わせて対策処理を入れる予定なので、現段階ではアサーションメッセージのみ出力する。
    // eslint-disable-next-line no-console
    console.assert(isAudioObjectsClose(), 'audioContextがundefinedじゃない');

    // 直接音声データを触るために必要なオブジェクトを生成
    audioContext.current = new AudioContext();

    audioContext.current.audioWorklet
      .addModule('/audioProcessor.js')
      .then(() => {
        // マイクの使用許可
        navigator.mediaDevices
          .getUserMedia(mediaRequestTypes)
          .then(
            (stream) =>
              new Promise<void>((resolve, reject) => {
                // マイク認識成功
                setMicStatus(MIC_STATUS.SUCCESS);

                mediaStream.current = stream;
                if (!audioContext || !audioContext.current) {
                  reject();

                  return;
                }

                // マイクの使用が許可されるとstreamが渡されるので、AudioContextで音声ストリームを扱えるように設定
                inputStream.current =
                  audioContext.current.createMediaStreamSource(stream);
                audioWorklet.current = new AudioWorkletNode(
                  audioContext.current,
                  'audio-worklet-stream',
                );

                // MediaStream,Worklet,スピーカーを接続する
                audioWorklet.current.connect(audioContext.current.destination);
                inputStream.current.connect(audioWorklet.current);

                // イベントハンドラー
                audioWorklet.current.port.start();
                audioWorklet.current.port.addEventListener(
                  'message',
                  (event) => {
                    callback(event.data);
                  },
                );

                resolve();
              }),
          )
          .catch((_) => {
            // マイク認識失敗
            setMicStatus(MIC_STATUS.ERROR);
          });
      })
      .catch((_) => {
        // マイク認識失敗
        setMicStatus(MIC_STATUS.ERROR);
      });
  };

  /**
   * ストリームの初期化
   */
  const stopStream = (): void => {
    if (mediaStream && mediaStream.current) {
      mediaStream.current
        .getTracks()
        .forEach((track: MediaStreamTrack) => track.stop());
    }
    mediaStream.current = null;

    if (inputStream && inputStream.current) {
      inputStream.current.disconnect();
    }
    inputStream.current = undefined;
  };

  /**
   * マイク利用終了
   */
  const stopMic = useCallback((): void => {
    stopStream();

    if (audioWorklet && audioWorklet.current) {
      audioWorklet.current.port.close();
      audioWorklet.current.disconnect();
    }
    audioWorklet.current = null;

    if (audioContext && audioContext.current) {
      audioContext.current.close();
    }
    audioContext.current = null;
  }, []);

  /**
   * マイク変更
   *
   * @param deviceId デバイスID
   * @returns true:成功、false:失敗
   */
  const changeMic = () => {
    // マイク変更中
    setMicStatus(MIC_STATUS.CHANGING);

    navigator.mediaDevices.getUserMedia(mediaRequestTypes).then((stream) =>
      new Promise<void>((resolve, reject) => {
        // マイク認識成功
        // 変更前の音声データの接続を切って初期化する
        stopStream();

        if (!audioContext.current || !audioWorklet.current) {
          reject();

          return;
        }

        // 変更後の音声データを接続する
        mediaStream.current = stream;
        inputStream.current = audioContext.current.createMediaStreamSource(
          mediaStream.current,
        );
        inputStream.current.connect(audioWorklet.current);

        setMicStatus(MIC_STATUS.SUCCESS);
        resolve();
      }).catch((_) => {
        // マイク認識失敗
        setMicStatus(MIC_STATUS.ERROR);
      }),
    );
  };

  /**
   * 収録開始
   *
   * @returns
   */
  const startRecording = (): void => {
    if (!audioContext || !audioContext.current) {
      return;
    }
    if (audioContext.current.state === 'running') {
      // すでに収録中の場合は何もしない
      return;
    }

    // 収録開始
    audioContext.current.resume();
  };

  /**
   * 収録停止
   *
   * @returns
   */
  const stopRecording = (): void => {
    if (!audioContext || !audioContext.current) {
      return;
    }
    if (audioContext.current.state === 'suspended') {
      // すでに停止中の場合は何もしない
      return;
    }
    // 収録停止
    audioContext.current.suspend();
  };

  /**
   * マイク認識状態を監視
   */
  useEffect(() => {
    if (micStatus !== MIC_STATUS.SUCCESS && micStatus !== MIC_STATUS.CHANGING) {
      stopMic(); // マイク利用終了
    }
  }, [micStatus, stopMic]);

  /**
   * マイク設定の変更を監視
   */
  useEffect(
    () => {
      if (micStatus === MIC_STATUS.NONE) {
        return;
      }

      changeMic();
    },
    // 入力元デバイスの変更だけをトリガーにしたいため無効コメント追加
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputDevice],
  );

  /**
   * マウント/アンマウント時に実行する処理
   */
  useEffect(
    () => () => {
      if (!isAudioObjectsClose()) {
        // マイク利用終了
        stopMic();
      }
    },
    // コンポーネントのアンマウント時に1度だけ実行
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return {
    requestStartMic,
    stopMic,
    startRecording,
    stopRecording,
  };
};
