import { PAY, PTPayLangCodeMap } from '@/constants';
import { useBrowserUserSetting } from '@/hooks/useBrowserUserSetting';

/**
 * PT_PAYの商品選択画面を表示する際に指定するモード
 */
const PRODUCT_SELECT_MODE = '3' as string;

/**
 * PTPayライセンス確認エンドポイント
 */
const PLM_0100_URL = `${PAY.DOMAIN}${PAY.API_URL.PLM_0100}` as string;

/**
 * PTPayライセンス確認 カスタムフック
 *
 * @returns
 */
export const usePTPayPlm0100 = () => {
  const { language } = useBrowserUserSetting();

  /**
   * PTPayライセンス確認リクエスト
   *
   * @param mode
   */
  const request = (mode?: string) => {
    let url = `${PLM_0100_URL}?language=${PTPayLangCodeMap[language]}`;
    if (mode) {
      url = `${url}&mode=${mode}&productCode=${PAY.PRODUCT_CODE}`;
    }
    window.open(url, '_blank');
  };

  /**
   * PT_PAYの商品選択画面を表示
   */
  const openProductSelect = () => {
    request(PRODUCT_SELECT_MODE);
  };

  /**
   * PT_PAYのサービス一覧画面を表示
   */
  const openServiceList = () => {
    request();
  };

  return {
    openProductSelect,
    openServiceList,
  };
};
