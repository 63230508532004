// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */

import React, { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import shareGuestUrlCopy from '@/assets/share-guest-link-copy.svg';
import { Button, ModalDialog, QRCode } from '@/components/Elements';

import { useShareCommonDialog } from '../hooks/useShareCommonDialog';

import styles from './ShareCommonDialog.module.scss';
/**
 * 共有ダイアログ プロパティ
 */
type Props = {
  // ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
  // ダイアログを閉じる
  onClose: () => void;
  // 閉じるボタンを表示するか否か
  isHiddenCloseButton: boolean;
  // ダイアログのタイトル
  title: string;
  // 説明文
  description?: string;
  // URLコピーボタンを表示するか否か
  isUrlCopyButton: boolean;
  // ボタン上部に表示するオプションコンテンツ
  optionContents?: ReactNode;
  // URL文字列
  url: string;
  // ボタン要素群
  buttons?: ReactElement<typeof Button>;
};

/**
 * 共有ダイアログ
 */
export const ShareCommonDialog = React.memo(
  ({
    isOpen,
    onClose,
    isHiddenCloseButton,
    title,
    description,
    isUrlCopyButton,
    optionContents = undefined,
    url,
    buttons = undefined,
  }: Props) => {
    const { clickUrlCopyButton } = useShareCommonDialog();
    const { t } = useTranslation();

    return (
      <ModalDialog
        isOpen={isOpen}
        isHiddenCloseButton={isHiddenCloseButton}
        onClickClose={onClose}
        title={title}
      >
        <div className={styles['share-container']}>
          <div className={styles['share-description-wrapper']}>
            {description && <div>{description}</div>}
          </div>
          <div className={styles['share-qrcode']}>
            <QRCode url={url} />
          </div>

          {isUrlCopyButton && (
            <div className={styles['url-copy-button-wrapper']}>
              <div className={styles['share-url-wrapper']}>{url}</div>

              <div className={styles['url-copy-button']}>
                <button
                  type="button"
                  onClick={() => {
                    clickUrlCopyButton(url);
                  }}
                >
                  <img src={shareGuestUrlCopy} alt="shareGuestUrlCopy" />
                  {t('Copy link')}
                </button>
              </div>
            </div>
          )}
          {optionContents && (
            <div className={styles['share-option-contents-wrapper']}>
              {optionContents}
            </div>
          )}
          {buttons && (
            <div className={styles['suspension-button-wrapper']}>{buttons}</div>
          )}
        </div>
      </ModalDialog>
    );
  },
);
