// // Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */
import React from 'react';

/**
 * プロパティ
 */
export type LinkButtonMenu = {
  // リンク名
  title: string;
  // リンク先
  href: string;
  // 別タブで開くか否か(true=別タブで開く)
  isNewTab: boolean;
  // クリック時の処理
  onClick?: () => void;
  // 画像URL
  imgUrl?: string;
};

/**
 * リンクボタンメニュー コンポーネント
 */
export const LinkButtonMenu = React.memo(
  ({
    title,
    href,
    isNewTab,
    onClick = undefined,
    imgUrl = '',
  }: LinkButtonMenu) => (
    <a
      data-testid="link-button-menu"
      target={isNewTab ? '_blank' : '_self'}
      href={href}
      rel="noreferrer"
      onClick={onClick}
    >
      {imgUrl && <img src={imgUrl} alt="img" />}
      {title}
    </a>
  ),
);
