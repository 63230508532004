// Propsのデフォルト設定を無効にする
/* eslint-disable react/require-default-props */
import React, { useRef } from 'react';

import {
  InputSlider,
  POPUP_CONTENT_ALIGN_POSITION,
  PopupContents,
  PopupDetails,
  PopupSummary,
} from '@/components/Elements';
import { FONT_SIZE_INFO } from '@/constants';
import { useTranslationDetails } from '@/hooks/useTranslationDetails';

import { useFontSizeInput } from '../hooks/useFontSizeInput';

import styles from './FontSizeContainer.module.scss';

/**
 * プロパティ
 */
type Props = {
  // フォントサイズの現在値
  currentFontSize: number;
  // フォントサイズが変更された場合に実行したい処理
  onChangeFontSizeFunc: (value: number) => void;
  // GTM用クラスタグ
  gtmClassTag?: string;
};

/**
 * 文字サイズ変更
 */
export const FontSizeContainer = React.memo(
  ({ currentFontSize, onChangeFontSizeFunc, gtmClassTag = '' }: Props) => {
    // メニュークリックでopen要素削除する
    const detailsRef = useRef<HTMLDetailsElement | null>(null);
    const { onToggle } = useTranslationDetails();
    const { onChangeFontSize } = useFontSizeInput({ onChangeFontSizeFunc });

    return (
      <div>
        <PopupDetails detailsRef={detailsRef} onToggle={onToggle}>
          <PopupSummary>
            <div className={`${styles['font-size']} ${gtmClassTag}`}>
              <div className={styles['font-size-icon']} />
            </div>
          </PopupSummary>
          <PopupContents align={POPUP_CONTENT_ALIGN_POSITION.RIGHT}>
            <div
              className={`${styles['font-size-contents']} ${styles['font-size-slider']}`}
            >
              <ul>
                <li>
                  <InputSlider
                    min={FONT_SIZE_INFO.minLength}
                    max={FONT_SIZE_INFO.maxLength}
                    step={FONT_SIZE_INFO.step}
                    defaultValue={currentFontSize}
                    onChange={onChangeFontSize}
                  />
                </li>
              </ul>
            </div>
          </PopupContents>
        </PopupDetails>
      </div>
    );
  },
);
