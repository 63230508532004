import { useCallback } from 'react';

/**
 * プロパティ
 */
type Props = {
  // フォントサイズが変更された場合に実行したい処理
  onChangeFontSizeFunc: (value: number) => void;
};

/**
 * 本カスタムからの返却値
 */
export type Value = {
  // フォントサイズ変更が変更された場合の処理
  onChangeFontSize: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

/**
 * フォントサイズ変更スライダー カスタムフック
 *
 * @returns
 */
export const useFontSizeInput = ({ onChangeFontSizeFunc }: Props): Value => {
  /**
   * フォントサイズ変更が変更された場合の処理
   */
  const onChangeFontSize = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChangeFontSizeFunc(Number(e.currentTarget.value));
    },
    [onChangeFontSizeFunc],
  );

  return {
    onChangeFontSize,
  };
};
