import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { PAGE_ROUTE_PATH } from '@/constants';

/**
 * 契約状態更新 カスタムフック
 *
 * @returns
 */
export const useContractRenewal = () => {
  const navigate = useNavigate();

  /**
   * 契約状態更新後の再ログインダイアログ用OKボタンのクリック処理
   */
  const clickContractRenewalOKButton = useCallback(() => {
    // ようこそ画面に遷移。このときURLパラメータの指定なし
    navigate({
      pathname: PAGE_ROUTE_PATH.WELCOME,
    });
  }, [navigate]);

  return {
    clickContractRenewalOKButton,
  };
};
