import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { PAGE_ROUTE_PATH, URL_PARAMS_KEY } from '@/constants';

/**
 * 利用規約更新 カスタムフック
 */
export const useNeedAgreement = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  /**
   * 利用規約が更新された時に利用規約画面に遷移する
   */
  const needAgreement = useCallback(() => {
    navigate(PAGE_ROUTE_PATH.AGREEMENT, { state: pathname });
  }, [navigate, pathname]);

  return {
    needAgreement,
  };
};
