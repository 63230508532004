import { BROWSER } from '@/constants';
import { browserAxios } from '@/lib/axios';

import { API_AUTH_RESULT_CODE } from './apiAuth';

/**
 * 結果コード定数
 */
export const LOGOUT_API_RESULT_CODE = {
  ...({
    // 成功
    OK: 'OK',
    // 不明エラー
    ERR_UNKNOWN: 'ERR_UNKNOWN',
  } as const),
  ...API_AUTH_RESULT_CODE,
} as const;
export type LogoutResultCode =
  (typeof LOGOUT_API_RESULT_CODE)[keyof typeof LOGOUT_API_RESULT_CODE];

/**
 * 結果コードのTypeGuard
 *
 * @param resultCode
 * @returns
 */
export const isLogoutApiResultCode = (
  resultCode: string,
): resultCode is LogoutResultCode => {
  if (resultCode in LOGOUT_API_RESULT_CODE) {
    return true;
  }

  return false;
};

/**
 * レスポンス
 */
export type LogoutApiResponse = {
  // 結果コード
  resultCode: LogoutResultCode;
};

/**
 * API 呼び出し
 *
 * @returns
 */
const callApi = (): Promise<LogoutApiResponse> => {
  // リクエスト送信
  const response = browserAxios
    .get<LogoutApiResponse>(BROWSER.API_URL.LOGOUT, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((apiResponse) => apiResponse.data);

  return response;
};

/**
 * ログアウトAPI リクエスト
 *
 * @returns
 */
export const logoutApi = (): Promise<LogoutApiResponse> => callApi();

/**
 * ログアウトAPI(リダイレクト用) リクエスト
 *
 * @returns
 */
export const logoutRedirectApi = () => {
  // GETでアクセス(ブラウザアクセスと一緒)
  window.open(
    `${BROWSER.DOMAIN.API}${BROWSER.API_URL.LOGOUT_REDIRECT}`,
    '_self',
  );
};
