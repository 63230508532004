import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { initMocks } from './test/server';

// mock起動
initMocks();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(<App />);

serviceWorkerRegistration.register();
