/**
 * TTSの音声設定
 */
export const TTS_AUDIO_CONFIG = {
  // エンコーディング
  AUDIO_ENCODING: 'MP3',
  // 発声速度
  SPEAKING_RATE: 1.5,
  // ピッチ
  PITCH: 0,
} as const;

/**
 * TTS発話情報の型
 */
export type VoiceInfo = {
  [key: string]: {
    languageCode: string;
    voiceName: string;
  };
};
