import { useEffect } from 'react';

import { useLoginAuth } from '@/features/auth';

/**
 * ようこそ画面(サインアップ)用 カスタムフック
 */
export const useSignUp = () => {
  const { loginAuth } = useLoginAuth({});

  useEffect(() => {
    // 認証実行
    loginAuth(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
