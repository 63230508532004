import { Reducer, useCallback, useReducer } from 'react';

// ファイルの読み込みの状態
export const FILE_LOAD_STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  FAILED: 'failed',
  SUCCESS: 'success',
} as const;
export type FileLoadStatus =
  (typeof FILE_LOAD_STATUS)[keyof typeof FILE_LOAD_STATUS];

export type LoadFileState = {
  // テキストデータ
  text?: string;
  // ファイルの読み込みの状態
  status: FileLoadStatus;
};

const LOAD_FILE_ACTION_TYPE = {
  START_LOAD: 'START_LOAD',
  SUCCESS_LOAD: 'SUCCESS_LOAD',
  FAILED_LOAD: 'FAILED_LOAD',
} as const;

type LoadFileActionType =
  (typeof LOAD_FILE_ACTION_TYPE)[keyof typeof LOAD_FILE_ACTION_TYPE];

type LoadFileAction = {
  text?: string;
  type: LoadFileActionType;
};

const reducer: Reducer<LoadFileState, LoadFileAction> = (state, action) => {
  switch (action.type) {
    case LOAD_FILE_ACTION_TYPE.START_LOAD:
      return {
        ...state,
        status: FILE_LOAD_STATUS.LOADING,
      };
    case LOAD_FILE_ACTION_TYPE.SUCCESS_LOAD:
      return {
        ...state,
        text: action.text,
        status: FILE_LOAD_STATUS.SUCCESS,
      };
    case LOAD_FILE_ACTION_TYPE.FAILED_LOAD:
      return {
        ...state,
        text: action.text,
        status: FILE_LOAD_STATUS.FAILED,
      };
    default:
      // do nothing
      return state;
  }
};

const useLoadFile = () => {
  const [state, dispatch] = useReducer(reducer, {
    text: undefined,
    status: FILE_LOAD_STATUS.IDLE,
  });

  /**
   * 外部のファイルを読み込む
   * @param fileUrl ファイルのURL
   */
  const loadFile = useCallback(
    (fileUrl: string) => {
      if (state.status === FILE_LOAD_STATUS.LOADING) {
        return;
      }

      dispatch({ type: LOAD_FILE_ACTION_TYPE.START_LOAD });

      fetch(fileUrl, { method: 'GET' })
        .then((response) => {
          // 500エラーなどは、catchしてくれないので、200番台以外はリジェクトする
          if (!response.ok) {
            return Promise.reject(response);
          }

          return response.text();
        })
        .then((text) => {
          dispatch({
            type: LOAD_FILE_ACTION_TYPE.SUCCESS_LOAD,
            text,
          });
        })
        .catch((_) => {
          // 例外は失敗扱いにする
          dispatch({
            type: LOAD_FILE_ACTION_TYPE.FAILED_LOAD,
            text: undefined,
          });
        });
    },

    [state.status],
  );

  return {
    state,
    loadFile,
  };
};

export default useLoadFile;
