import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DEFAULT_DEVICE } from '@/constants';
import {
  browserUserSettingSlice,
  BrowserUserSettingState,
} from '@/states/slices/browserUserSettingSlice';
import { AppDispatch, RootState, useAppDispatch } from '@/states/store';
import { defaultUiLanguage } from '@/utils/ui';

/**
 * [設定ダイアログ]の設定内容をローカルストレージとReduxに保存 hooks
 *
 * @returns
 */
export const useBrowserUserSetting = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const {
    setInputDevice,
    setOutputDevice,
    setLanguage,
    setIsHistory,
    clearUserSetting,
  } = browserUserSettingSlice.actions;

  const { inputDevice, outputDevice, language, isHistory } = useSelector<
    RootState,
    BrowserUserSettingState
  >((state) => state.browserUserSetting);

  /**
   * 入力元デバイス
   */
  const userSettingInputDevice = useMemo(() => {
    if (inputDevice) {
      return inputDevice;
    }

    return DEFAULT_DEVICE;
  }, [inputDevice]);

  /**
   * 出力元デバイス
   */
  const userSettingOutputDevice = useMemo(() => {
    if (outputDevice) {
      return outputDevice;
    }

    return DEFAULT_DEVICE;
  }, [outputDevice]);

  /**
   * UI言語
   */
  const userSettingLanguage = useMemo(() => {
    if (language) {
      return language;
    }

    // ブラウザ言語を取得してデフォルト設定
    return defaultUiLanguage();
  }, [language]);

  /**
   * 入力元デバイスを更新
   */
  const changeInputDevice = useCallback(
    (value: string) => {
      dispatch(setInputDevice(value));
    },
    [dispatch, setInputDevice],
  );

  /**
   * 出力元デバイスを更新
   */
  const changeOutputDevice = useCallback(
    (value: string) => {
      dispatch(setOutputDevice(value));
    },
    [dispatch, setOutputDevice],
  );

  /**
   * UI言語を更新
   */
  const changeLanguage = useCallback(
    (value: string) => {
      dispatch(setLanguage(value));
    },
    [dispatch, setLanguage],
  );

  /**
   * 翻訳履歴を保存するか否か
   */
  const changeIsHistory = useCallback(
    (value: boolean) => {
      dispatch(setIsHistory(value));
    },
    [dispatch, setIsHistory],
  );

  /**
   * [設定ダイアログ]の設定内容に関する全てのStateをリセット
   */
  const resetState = useCallback(() => {
    dispatch(clearUserSetting());
  }, [clearUserSetting, dispatch]);

  return {
    inputDevice: userSettingInputDevice,
    outputDevice: userSettingOutputDevice,
    language: userSettingLanguage,
    isHistory,
    setInputDevice: changeInputDevice,
    setOutputDevice: changeOutputDevice,
    setLanguage: changeLanguage,
    setIsHistory: changeIsHistory,
    resetState,
  };
};
