import { useCallback } from 'react';

import { useTranslationDisplay } from '@/hooks/useTranslationDisplay';

/**
 * 翻訳画面で使用している <details> 要素の制御 hooks
 * ※文字サイズメニューが対象
 *
 * @returns
 */
export const useTranslationDetails = () => {
  const { setIsOpenSetting } = useTranslationDisplay();

  /**
   * <details>要素の開閉が切り替わったときの処理
   */
  const onToggle = useCallback(
    (e: React.SyntheticEvent<HTMLDetailsElement, Event>) => {
      setIsOpenSetting(e.currentTarget.open);
    },
    [setIsOpenSetting],
  );

  return { onToggle };
};
