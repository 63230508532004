import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
  googleTtsVoiceListSlice,
  googleTtsVoiceListState,
} from '@/states/slices/googleTtsVoiceListSlice';
import { AppDispatch, RootState, useAppDispatch } from '@/states/store';

import { VoiceInfo } from '../features/texttospeech/constants';

/**
 * Google TTS APIの発話情報を保存 hooks
 * @returns
 */
export const useGoogleTtsVoiceList = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const { setGoogleTtsVoiceList, resetToInitialState } =
    googleTtsVoiceListSlice.actions;

  const { ttsVoiceInfo } = useSelector<RootState, googleTtsVoiceListState>(
    (state) => state.googleTtsVoiceList,
  );

  /**
   * Google TTS API発話情報一覧 更新
   */
  const changeGoogleTtsVoiceList = useCallback(
    (value: VoiceInfo) => {
      dispatch(setGoogleTtsVoiceList(value));
    },
    [dispatch, setGoogleTtsVoiceList],
  );

  /**
   * 指定された翻訳先言語が「Google TTS API対応言語」か否かチェック
   *
   * @param currentDestlang 指定された翻訳先言語
   * @return true=対応言語 / false=非対応言語
   */
  const isSupportedLangGoogleTTS = useCallback(
    (currentDestlang: string): boolean => {
      if (!Object.keys(ttsVoiceInfo).length) {
        return false;
      }

      if (Object.prototype.hasOwnProperty.call(ttsVoiceInfo, currentDestlang)) {
        return true;
      }

      return false;
    },
    [ttsVoiceInfo],
  );

  /**
   * 全てのStateをリセット
   */
  const resetState = useCallback(() => {
    dispatch(resetToInitialState());
  }, [dispatch, resetToInitialState]);

  return {
    ttsVoiceInfo,
    setGoogleTtsVoiceList: changeGoogleTtsVoiceList,
    resetState,
    isSupportedLangGoogleTTS,
  };
};
