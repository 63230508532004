import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  COOKIE_KEY_NAME,
  COOKIE_USER_TYPE,
  PAGE_ROUTE_PATH,
  URL_PARAMS_KEY,
} from '@/constants';
import { findCookieValue } from '@/utils/cookie';

/**
 * リトライ失敗エラー カスタムフック
 */
export const useRetryError = () => {
  const navigate = useNavigate();

  /**
   * リトライ失敗エラーダイアログ用OKボタンのクリック処理
   */
  const clickRetryErrorOKButton = useCallback(() => {
    const userType = findCookieValue(COOKIE_KEY_NAME.PTBR_TYPE);
    if (userType === COOKIE_USER_TYPE.SERIAL) {
      // シリアルで利用開始した場合はホーム画面に遷移
      navigate({
        pathname: PAGE_ROUTE_PATH.HOME,
      });

      return;
    }

    // ようこそ画面に遷移。このときホーム画面のパスを指定
    navigate({
      pathname: PAGE_ROUTE_PATH.WELCOME,
      search: encodeURI(
        `${URL_PARAMS_KEY.WELCOME.path}=${PAGE_ROUTE_PATH.HOME}`,
      ),
    });
  }, [navigate]);

  return {
    clickRetryErrorOKButton,
  };
};
