/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

import { PullDownMenu, SpinnerOverlayView } from '@/components/Elements';
import { TransMode, TRANS_MODE } from '@/constants';
import { FontSizeContainer } from '@/features/font';
import { useBrowserTranslationDisplay } from '@/hooks/useBrowserTranslationDisplay';
import { useBrowserUserSetting } from '@/hooks/useBrowserUserSetting';
import { useTranslationInfo } from '@/hooks/useTranslationInfo';
import { useTranslationSelect } from '@/hooks/useTranslationSelect';

import { useDeviceMenu } from '../../../device/hooks/useDeviceMenu';
import { useTranslationFooter } from '../../hooks/footer/useTranslationFooter';
import { FreePlanButton } from '../freeplanbutton/FreePlanButton';
import styles from '../TranslationFooter.module.scss';

/**
 * プロパティ
 */
export type Props = {
  // [STT ON/OFF]ボタンがクリックされた場合の処理
  onClickSttButton: () => void;
  // 翻訳モード
  translationMode: TransMode;
  // フッター開閉表示状態(true=開いている/false=閉じている)
  isDisplayFooter: boolean;
};

/**
 * 翻訳画面_フッター コンポーネント
 */
export const TranslationFooter = React.memo(
  ({ onClickSttButton, translationMode, isDisplayFooter }: Props) => {
    const { isUseTranslation, isFreePlan } = useTranslationInfo();
    const { inputDevice } = useBrowserUserSetting();
    const { inputDeviceList, onChangeInputDevice } = useDeviceMenu();
    const { isVisibledSpinner, fetchSttButtonImg } = useTranslationFooter();
    const selectOpenClose = useTranslationSelect();
    const { fontSize, setFontSize } = useBrowserTranslationDisplay();

    return (
      <>
        <div
          className={`${styles.footer} ${styles['footer-container']} ${
            styles['footer-open']
          } ${!isDisplayFooter && styles['footer-close']}
        }`}
        >
          <SpinnerOverlayView isOpen={isVisibledSpinner} />
          <div className={styles['translation-footer']}>
            <div className={styles['translation-button']}>
              <div className={styles['footer-translation-container']}>
                <div
                  className={`${styles['device-name']} ${styles['footer-container-left']}`}
                >
                  <div
                    className={`${styles['free-plan-button']} ${
                      isDisplayFooter
                        ? styles['free-plan-button-footer-open']
                        : styles['free-plan-button-footer-close']
                    }`}
                  >
                    {isFreePlan && <FreePlanButton />}
                  </div>

                  <p>
                    {translationMode === TRANS_MODE.MIC && (
                      <PullDownMenu
                        optionItems={inputDeviceList}
                        name="micSelect"
                        selectedValue={inputDevice}
                        onChange={onChangeInputDevice}
                        selectRef={selectOpenClose.selectRef}
                        gtmClassTag="dts-micselect"
                      />
                    )}
                  </p>
                </div>
                <div
                  className={styles['footer-container-center']}
                  role="button"
                  tabIndex={-1}
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={(e) => e.stopPropagation()}
                >
                  <button
                    type="button"
                    onClick={onClickSttButton}
                    disabled={!isUseTranslation}
                    className={`${styles['img-btn']} ${
                      styles[fetchSttButtonImg()]
                    } ${
                      fetchSttButtonImg().endsWith('on') &&
                      isDisplayFooter &&
                      styles['ripple-btn-on']
                    } dts-translating`}
                  />
                </div>
                <div className={styles['footer-container-right']}>
                  {/* 文字サイズボタン(翻訳画面のみ表示) */}
                  <FontSizeContainer
                    currentFontSize={fontSize}
                    onChangeFontSizeFunc={setFontSize}
                    gtmClassTag="dts-fontsize"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* フッタークローズ時に表示するボタン */}
        <div className={styles['footer-close-container']}>
          <button
            type="button"
            onClick={onClickSttButton}
            disabled={!isUseTranslation}
            className={`${styles['footer-close-stt']} ${
              styles[fetchSttButtonImg()]
            } ${
              isDisplayFooter
                ? styles['footer-close-btn-hidden']
                : styles['footer-close-btn-display']
            } ${
              !isDisplayFooter &&
              fetchSttButtonImg().endsWith('on') &&
              styles['footer-close-ripple-btn-on']
            } dts-translating`}
          />
        </div>
      </>
    );
  },
);
