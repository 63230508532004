import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';

import firebaseApp from './firebaseApp';

const db = getFirestore(firebaseApp);

if (process.env.NODE_ENV === 'development') {
  connectFirestoreEmulator(db, 'localhost', 8084);
}

export const firestore = db;
