import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { DialogMessageContents, ModalDialog } from '@/components/Elements';
import { PAGE_ROUTE_PATH } from '@/constants';

/**
 * プロパティ
 */
type Props = {
  // ダイアログ表示/非表示(true=表示)
  isOpen: boolean;
};

/**
 * 共有情報取得失敗エラーダイアログ コンポーネント
 */
export const ShareInfoErrorDialog = React.memo(({ isOpen }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  /**
   * OKボタンクリック時の処理
   */
  const onClickOK = () => {
    // ホーム画面に遷移
    navigate(PAGE_ROUTE_PATH.HOME);
  };

  return (
    <ModalDialog
      isOpen={isOpen}
      title={t('shareInfoErrorDialog.エラーが発生しました').toString()}
      buttons={[
        {
          label: t('translationDialog.OK'),
          onClick: onClickOK,
          gtmClassTag: 'dialog-09',
        },
      ]}
    >
      <DialogMessageContents>
        {t('shareInfoErrorDialog.ホーム画面に戻って操作をやり直してください。')}
      </DialogMessageContents>
    </ModalDialog>
  );
});
