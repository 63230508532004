import React, { useEffect } from 'react';

import { COMMON_ERROR_DIALOG_CODE } from '@/constants/error';
import {
  AccessExpiredDialog,
  LicenseInvalidDialog,
  usePtidExpired,
} from '@/features/expired';
import { useCommonErrorDialog } from '@/hooks/useCommonErrorDialog';

/**
 * 共通エラーダイアログ コンポーネント
 */
export const CommonErrorDialogContainer = React.memo(() => {
  const { commonErrorDialogType } = useCommonErrorDialog();
  const { ptidExpired } = usePtidExpired();

  useEffect(() => {
    if (commonErrorDialogType === COMMON_ERROR_DIALOG_CODE.PTID_ERROR) {
      // PTID期限切れ処理実行
      ptidExpired();
    }
  }, [commonErrorDialogType, ptidExpired]);

  return (
    <>
      {/* アクセス期限切れエラーダイアログを表示 */}
      {commonErrorDialogType === COMMON_ERROR_DIALOG_CODE.ACCESS_ERROR && (
        <AccessExpiredDialog isOpen />
      )}

      {/* ライセンス不正ダイアログを表示(※現段階ではここが起因でライセンス不正ダイアログが表示されることはない) */}
      {commonErrorDialogType === COMMON_ERROR_DIALOG_CODE.LICENSE_ERROR && (
        <LicenseInvalidDialog isOpen />
      )}
    </>
  );
});
