import React from 'react';
import { useTranslation } from 'react-i18next';

import feedback from '@/assets/feedback.svg';
import { LinkButtonMenu } from '@/components/Elements';

import { useFeedbackContainer } from '../hooks/useFeedbackContainer';

/**
 * フィードバックメニュー プロパティ
 */
type FeedbackContainer = {
  // 設定ダイアログを閉じる
  onCloseDialog: () => void;
};

/**
 * 設定ダイアログ > フィードバックメニュー
 */
export const FeedbackContainer = React.memo(
  ({ onCloseDialog }: FeedbackContainer) => {
    const { t } = useTranslation();
    const { feedbackLink } = useFeedbackContainer();

    return (
      <LinkButtonMenu
        title={t('settingMenu.フィードバック')}
        href={feedbackLink}
        isNewTab
        onClick={onCloseDialog} // リンククリックでダイアログを閉じる
        imgUrl={feedback}
      />
    );
  },
);
