import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { CommonErrorDialogType } from '@/constants/error';
import {
  CommonErrorDialogState,
  commonErrorDialogSlice,
} from '@/states/slices/commonErrorDialogSlice';
import { AppDispatch, RootState, useAppDispatch } from '@/states/store';

/**
 * 共通エラーダイアログに関する情報を保存 hooks
 *
 * @returns
 */
export const useCommonErrorDialog = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const { setCommonErrorDialogType, resetToInitialState } =
    commonErrorDialogSlice.actions;

  const { commonErrorDialogType } = useSelector<
    RootState,
    CommonErrorDialogState
  >((state) => state.commonErrorDialog);

  /**
   * 共通エラーダイアログのタイプを更新
   */
  const changeCommonErrorDialogType = useCallback(
    (value: CommonErrorDialogType) => {
      dispatch(setCommonErrorDialogType(value));
    },
    [dispatch, setCommonErrorDialogType],
  );

  /**
   * 共通エラーダイアログに関する全ての情報をリセット
   */
  const resetState = useCallback(() => {
    dispatch(resetToInitialState());
  }, [dispatch, resetToInitialState]);

  return {
    commonErrorDialogType,
    setCommonErrorDialogType: changeCommonErrorDialogType,
    resetState,
  };
};
