import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ListDisplayType } from '@/constants';
import {
  guestDisplaySlice,
  GuestDisplayState,
  GuestStatusType,
} from '@/states/slices/guestDisplaySlice';
import { AppDispatch, RootState, useAppDispatch } from '@/states/store';
import { defaultUiLanguage } from '@/utils/ui';

/**
 * ゲスト画面情報に関する情報を保存 hooks
 *
 * @returns
 */
export const useGuestDisplay = () => {
  const dispatch: AppDispatch = useAppDispatch();

  const {
    setDisplayType,
    setFontSize,
    setDestlang,
    setUiLang,
    setGuestStatus,
    resetToInitialState,
  } = guestDisplaySlice.actions;

  const { displayType, fontSize, destlang, uiLang, guestStatus } = useSelector<
    RootState,
    GuestDisplayState
  >((state) => state.guestDisplay);

  /**
   * 翻訳元テキストの表示/非表示を更新
   */
  const changeDisplayType = useCallback(
    (value: ListDisplayType) => {
      dispatch(setDisplayType(value));
    },
    [dispatch, setDisplayType],
  );

  /**
   * 翻訳関連テキストの文字サイズを更新
   */
  const changeFontSize = useCallback(
    (value: number) => {
      dispatch(setFontSize(value));
    },
    [dispatch, setFontSize],
  );

  /**
   * 翻訳先言語を更新
   */
  const changeDestlang = useCallback(
    (value: string) => {
      dispatch(setDestlang(value));
    },
    [dispatch, setDestlang],
  );

  /**
   * UI言語を更新
   */
  const changeUiLanguage = useCallback(
    (value: string) => {
      dispatch(setUiLang(value));
    },
    [dispatch, setUiLang],
  );

  /**
   * ゲスト画面表示状況を更新
   */
  const changeGuestStatus = useCallback(
    (value: GuestStatusType) => {
      dispatch(setGuestStatus(value));
    },
    [dispatch, setGuestStatus],
  );

  /**
   * 全てのStateをリセット
   */
  const resetState = useCallback(() => {
    dispatch(resetToInitialState());
  }, [dispatch, resetToInitialState]);

  /**
   * UI言語
   */
  const currentUiLanguage = useMemo(() => {
    if (uiLang) {
      return uiLang;
    }

    // ブラウザ言語を取得してデフォルト設定
    return defaultUiLanguage();
  }, [uiLang]);

  return {
    displayType,
    fontSize,
    destlang,
    uiLang: currentUiLanguage,
    guestStatus,
    setDisplayType: changeDisplayType,
    setFontSize: changeFontSize,
    setDestlang: changeDestlang,
    setUiLang: changeUiLanguage,
    setGuestStatus: changeGuestStatus,
    resetState,
  };
};
