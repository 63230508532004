import { BROWSER } from '@/constants';
import { LangData } from '@/constants/language';
import { browserLangListAxios } from '@/lib/axios';

/**
 * 音声操作種別
 */
export const VOICE_TYPE = {
  // 音声認識
  STT: 'stt',
  // 音声合成
  TTS: 'tts',
} as const;
export type VoiceType = (typeof VOICE_TYPE)[keyof typeof VOICE_TYPE];

/**
 * リクエストパラメータ
 */
export type LangListRequest = {
  // 音声操作種別
  voiceType: VoiceType;
  // UI言語
  uiLanguage: string;
};

/**
 * 言語一覧取得
 *
 * @param request
 * @returns
 */
const get = (request: LangListRequest): Promise<LangData[]> => {
  const url = `${BROWSER.LANG_LIST_URL}/${request.voiceType}.${request.uiLanguage}.json`;
  const response = browserLangListAxios
    .get<LangData[]>(url)
    .then((apiResponse) => apiResponse.data);

  return response;
};
/**
 * 言語一覧取得(同通サーバ) リクエスト
 *
 * @param request
 * @returns
 */
export const langList = (request: LangListRequest): Promise<LangData[]> =>
  get(request);
