import { useRef } from 'react';

import { BROWSER, WEBSOCKET_CLOSE_CODE } from '@/constants';
import { convertSnakeCaseToCamelCase } from '@/utils/converters';

import {
  StreamRequestHeader,
  StreamApiResponse,
  CloseResponse,
  isCloseResultCode,
  CLOSE_RESULT_CODE,
} from '../streamApi';

/**
 * Websocket切断理由
 */
export const CLOSE_EVENT_TYPE = {
  // 正常に切断された(closeCode=1000)
  SUCCESS: 'SUCCESS',
  // 音声ストリームAPIから切断された「INFO_LIMIT_EXCEEDED(利用時間上限超過)」で切断された
  LIMIT_EXCEEDED_ERROR: 'LIMIT_EXCEEDED_ERROR',
  // 音声ストリームAPIから切断された「INFO_CONNECTION_SHIFTED(接続数管理による切断)」で切断された
  CONNECTION_SHIFTED_ERROR: 'CONNECTION_SHIFTED_ERROR',
  // 音声ストリームAPIから切断された「INFO_FAILED_LANG(言語設定失敗)」で切断された
  LANG_ERROR: 'LANG_ERROR',
  // 音声ストリームAPIから切断された「INFO_SWITCH_BROWSABLE(共有画面有効無効の切り替え)」で切断された
  SWITCH_SHARE_BROWSABLE: 'SWITCH_SHARE_BROWSABLE',
  // その他理由で切断された(ネットワーク接続の不具合など)
  NETWORK_ERROR: 'NETWORK_ERROR',
};
export type CloseEventType =
  (typeof CLOSE_EVENT_TYPE)[keyof typeof CLOSE_EVENT_TYPE];

/**
 * Websocket接続結果
 */
export type WebsocketResult = {
  closeEventType: CloseEventType | undefined; // Websocket切断理由(undefinedの場合は接続成功)
};

/**
 * 本フックからの返却値
 */
export type StreamValue = {
  // WebSocketを使って通信開始
  openWebSocket: (
    header: StreamRequestHeader, // ヘッダ
    onResult: (apiResponse: StreamApiResponse) => void, // テキスト翻訳結果レスポンス
    onClose: (closeEventType: CloseEventType) => void, // Websocket切断イベント
  ) => Promise<WebsocketResult>;
  // メッセージ送信
  sendAudio: (buffer: any) => void;
  // 停止リクエスト
  sendFinal: () => void;
  // websocket閉じる
  closeWebSocket: () => void;
  // Websocketの切断時に指定されたreasonをCloseResponseに変換
  parseReasonToCloseResponse: (reason: string) => CloseResponse | undefined;
};

/**
 * Websocketの切断時に指定されたreasonをCloseResponseに変換
 *
 * @param reason Websocketの切断時に指定されたreason
 * @returns reasonに音声ストリームAPIから返却された結果コードが指定されていた場合はCloseResponse、それ以外はundefined
 */
export const parseReasonToCloseResponse = (
  reason: string,
): CloseResponse | undefined => {
  if (!reason) {
    return undefined;
  }

  try {
    const closeResponse: CloseResponse = JSON.parse(reason) as CloseResponse;
    if (isCloseResultCode(closeResponse.result_code)) {
      return closeResponse;
    }

    return undefined;
  } catch {
    return undefined;
  }
};

/**
 * 音声ストリームAPI hooks
 *
 * @param param0
 * @returns
 */
export const useStreamApi = (): StreamValue => {
  const websocket = useRef<WebSocket | null>(null);

  /**
   * WebSocketを使って通信開始
   *
   * @param header 音声ストリームAPIリクエストヘッダ
   * @param onResult テキスト翻訳結果レスポンス(sendAudioした結果を受け取る)
   * @param onClose 切断時のレスポンス
   * @returns
   */
  const openWebSocket = async (
    header: StreamRequestHeader,
    onResult: (apiResponse: StreamApiResponse) => void,
    onClose: (closeEventType: CloseEventType) => void,
  ) => {
    // メッセージ受信処理
    const onWebsocketMessage = (event: MessageEvent<any>) => {
      const data: StreamApiResponse = JSON.parse(
        convertSnakeCaseToCamelCase(event.data),
      ) as StreamApiResponse;
      onResult(data);
    };

    // 音声ストリームAPIリクエスト送信
    return new Promise<WebsocketResult>((resolve) => {
      const url = `${BROWSER.DOMAIN.WS}${BROWSER.API_URL.STREAM}?X-Access-Key=${header.accessKey}&X-License-Token=${header.licenseToken}&X-Push-Mode=${header.pushMode}&X-Auto-Detect=${header.autoDetect}&X-Options=noise_suppression=${header.noiseSuppression}&X-Options=interim_results=${header.interimResults}`;

      // 開通リクエスト
      websocket.current = new WebSocket(encodeURI(url));

      // 開通成功
      websocket.current.addEventListener('open', () => {
        if (websocket && websocket.current) {
          websocket.current.onmessage = onWebsocketMessage;
          resolve({ closeEventType: undefined });
        }
      });

      // Websocketが切断された
      websocket.current.addEventListener('close', (event: CloseEvent) => {
        // Websocket破棄
        websocket.current = null;

        // reasonに特定の理由が設定されている場合はエンジンサーバから切断された
        const closeResponse: CloseResponse | undefined =
          parseReasonToCloseResponse(event.reason);
        if (closeResponse) {
          let closeEventType = '';
          switch (closeResponse.result_code) {
            case CLOSE_RESULT_CODE.INFO_LIMIT_EXCEEDED:
              closeEventType = CLOSE_EVENT_TYPE.LIMIT_EXCEEDED_ERROR;
              break;
            case CLOSE_RESULT_CODE.INFO_FAILED_LANG:
              closeEventType = CLOSE_EVENT_TYPE.LANG_ERROR;
              break;
            case CLOSE_RESULT_CODE.INFO_SWITCH_BROWSABLE:
              closeEventType = CLOSE_EVENT_TYPE.SWITCH_SHARE_BROWSABLE;
              break;
            default:
              closeEventType = CLOSE_EVENT_TYPE.CONNECTION_SHIFTED_ERROR;
          }
          onClose(closeEventType);
          resolve({
            closeEventType,
          });

          return;
        }

        // closeCode=1000(正常に切断された)
        if (event.code === WEBSOCKET_CLOSE_CODE.NORMAL_CLOSURE) {
          onClose(CLOSE_EVENT_TYPE.SUCCESS);
          resolve({
            closeEventType: CLOSE_EVENT_TYPE.SUCCESS,
          });

          return;
        }

        // エンジンサーバ以外から切断された
        // ネットワーク通信による不具合などで切断された
        onClose(CLOSE_EVENT_TYPE.NETWORK_ERROR);
        resolve({
          closeEventType: CLOSE_EVENT_TYPE.NETWORK_ERROR,
        });
      });
    });
  };

  /**
   * メッセージ送信
   *
   * @param buffer
   * @returns
   */
  const sendAudio = async (buffer: any) => {
    if (
      !websocket ||
      !websocket.current ||
      websocket.current.readyState !== WebSocket.OPEN
    ) {
      return;
    }

    websocket.current.send(buffer);
  };

  /**
   * 停止リクエスト
   *
   * @returns
   */
  const sendFinal = async () => {
    if (
      !websocket ||
      !websocket.current ||
      websocket.current.readyState !== WebSocket.OPEN
    ) {
      return;
    }

    websocket.current.send(JSON.stringify({ final: true }));
  };

  /**
   * websocket閉じる
   *
   * @returns
   */
  const closeWebSocket = async () => {
    if (!websocket.current || websocket.current.readyState !== WebSocket.OPEN) {
      return;
    }

    websocket.current.close();
    websocket.current = null;
  };

  return {
    openWebSocket,
    sendAudio,
    sendFinal,
    closeWebSocket,
    parseReasonToCloseResponse,
  };
};
