import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { COOKIE_KEY_NAME, COOKIE_USER_TYPE } from '@/constants';
import { LICENSE_INFO_API_MAX_DATE, usePTPayPlm0100 } from '@/features/api';
import { useBrowserUserSetting } from '@/hooks/useBrowserUserSetting';
import { findCookieValue } from '@/utils/cookie';
import {
  convertDateToString,
  convertYyyyMMddhhmmssToDateUserTimeZone,
  dateFormatByLang,
} from '@/utils/date';

import { PLAN_INFO_STATE, usePlan } from './usePlan';

/**
 * 失敗時に実行したい関数の型
 */
export type FailedFunction = () => void;

/**
 * 本カスタムフックのプロパティ
 */
type Props = {
  // 失敗時に実行したい処理
  onFailedFun?: FailedFunction;
};

/**
 * プランダイアログ hooks
 *
 * @param param0
 */
export const usePlanDialog = ({ onFailedFun }: Props) => {
  const { openProductSelect, openServiceList } = usePTPayPlm0100();
  const { licenseInfoStatus, licenseExp, serial, planInfoResult } = usePlan();
  const { language } = useBrowserUserSetting();

  /**
   * プロパティに指定された関数Ref
   */
  const onFailedFuncRef = useRef<FailedFunction>();
  useEffect(() => {
    onFailedFuncRef.current = onFailedFun;
  }, [onFailedFun]);

  /**
   * 再ログインが必要か否か(true=必要)
   */
  const [needsLoginAgain, setNeedsLoginAgain] = useState<boolean>(false);

  /**
   * ライセンス確認失敗エラーメッセージを表示する場合のみ、
   * プロパティに指定された関数を実行
   */
  useEffect(() => {
    if (planInfoResult === PLAN_INFO_STATE.ERROR) {
      onFailedFuncRef.current?.();
    }
  }, [planInfoResult]);

  /**
   * [ライセンス購入]ボタンがクリックされた場合の処理
   */
  const onClickPurchaseButton = useCallback(() => {
    // PTPayサーバの商品選択画面を表示(別タブ)
    openProductSelect();
    // 本システム上には契約状態更新後の再ログインダイアログを表示して再ログインを促す
    setNeedsLoginAgain(true);
  }, [openProductSelect]);

  /**
   * [アカウント情報]ボタンがクリックされた場合の処理
   */
  const onClickAccountButton = useCallback(() => {
    // PTPayサーバのサービス一覧画面を表示(別タブ)
    openServiceList();
    // 本システム上には契約状態更新後の再ログインダイアログを表示して再ログインを促す
    setNeedsLoginAgain(true);
  }, [openServiceList]);

  /**
   * ユーザ種別
   */
  const userType = useMemo(
    () => findCookieValue(COOKIE_KEY_NAME.PTBR_TYPE),
    [],
  );

  /**
   * [アカウント情報]ボタンを非表示にするか否か
   */
  const isHiddenAccountButton = useMemo(() => {
    if (userType === COOKIE_USER_TYPE.SERIAL) {
      // シリアル入力で利用開始したユーザの場合は非表示
      return true;
    }

    return false;
  }, [userType]);

  /**
   * ライセンス有効期限の表示用テキスト
   */
  const licenseExpDate = useMemo(() => {
    // Cookieのユーザ種別が「free」のときは表示しない
    if (userType === COOKIE_USER_TYPE.FREE) {
      return undefined;
    }

    // ライセンス期限が取得できなかった場合は表示しない
    if (!licenseExp) {
      return undefined;
    }

    // ライセンス期限が無期限の時は表示しない
    if (licenseExp === LICENSE_INFO_API_MAX_DATE) {
      return undefined;
    }

    // ユーザのタイムゾーンでの日付を表示する
    const date = convertYyyyMMddhhmmssToDateUserTimeZone(licenseExp);

    const format = dateFormatByLang(language);

    return convertDateToString(date, format);
  }, [language, licenseExp, userType]);

  return {
    // ライセンス確認状況
    licenseInfoStatus,
    // ライセンス有効期限
    licenseExpDate,
    // [ライセンス購入]ボタンがクリックされた場合の処理
    onClickPurchaseButton,
    // ライセンス確認APIから返却されたシリアル
    serial,
    // [アカウント情報]ボタンがクリックされた場合の処理
    onClickAccountButton,
    // [アカウント情報]ボタンを非表示にするか否
    isHiddenAccountButton,
    // 再ログインが必要か否か(true=必要)
    needsLoginAgain,
    // プラン確認状態
    planInfoResult,
  };
};
