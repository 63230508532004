import React from 'react';

import { DialogMessageContents, ModalDialog } from '@/components/Elements';

import { useLicenseInvalid } from '../hooks/useLicenseInvalid';

type LicenseInvalidDialog = {
  isOpen: boolean;
};

/**
 * ライセンス不正ダイアログ コンポーネント
 *
 * @param param0 本ダイアログ表示/非表示
 * @returns
 */
export const LicenseInvalidDialog = React.memo(
  ({ isOpen }: LicenseInvalidDialog) => {
    const { clickLicenseInvalidOKButton, licenseInvalidDialogContents } =
      useLicenseInvalid();

    return (
      <ModalDialog
        isOpen={isOpen}
        title={licenseInvalidDialogContents.title}
        buttons={[
          {
            label: 'OK',
            onClick: clickLicenseInvalidOKButton,
            gtmClassTag: licenseInvalidDialogContents.gtmClassTag,
          },
        ]}
      >
        <DialogMessageContents>
          {licenseInvalidDialogContents.message}
        </DialogMessageContents>
      </ModalDialog>
    );
  },
);
